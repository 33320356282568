import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Header from '@/components/organisms/Header/Header';
import {
  selectAccountSlug,
  selectBroadcastId,
} from '@/redux/session/selectors';

import { selectIsDraft } from '../redux/selectors';

interface Props {
  title: string;
}
export const BroadcastLogsHeader = ({ title }: Props) => {
  const slug = useSelector(selectAccountSlug);
  const broadcastId = useSelector(selectBroadcastId);
  const navigate = useNavigate();
  const isDraft = useSelector(selectIsDraft);

  const handleArrowClick = useCallback(() => {
    if (isDraft) {
      navigate(`/${slug}/broadcasts/draft`);
    } else {
      navigate(`/${slug}/broadcasts/${broadcastId}`);
    }
  }, [broadcastId, isDraft, navigate, slug]);

  return (
    <Header>
      <Header.BreadCrumbs />
      <Header.Body>
        <Header.Title
          title={
            <Typography
              variant="heading1-medium"
              color="var(--text-default-gray)"
            >
              {title}
            </Typography>
          }
          onBackClick={handleArrowClick}
        />
      </Header.Body>
    </Header>
  );
};
