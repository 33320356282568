import { forwardRef, useCallback, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import useBrains from '@/hooks/useBrains';
import { EventName } from '@/models/segment';
import { NodeType } from '@/models/tryIt';
import { LogMessage } from '@/modules/analytics/models';
import { TypingIndicator } from '@/modules/humanChat/components/Conversation/MessageArea/TypingIndicator/TypingIndicator';
import { resetSelectedMessage } from '@/modules/TryIt/redux/actions';
import { trackEvent } from '@/segment/segment';
import { isKeyEnter, noop } from '@/util/util';

import { MessageBubble } from './MessageBuble/MessageBubble';
import MessageNode from './MessageNode/MessageNode';
import useTryIt from '../../hooks/useTryIt';
import { NoMessagesIcon } from '../../Icons/NoMessages';
import { SendMessageInput } from '../SendMessageInput/SendMessageInput';

import styles from './Messages.module.scss';

type MessagesProps = {
  webHookNodes?: NodeType[];
  brainId: string;
  webHost?: string;
  messages?: LogMessage[];
  tags?: string[] | null;
  resetClicked?: boolean;
  setResetClicked?: (clicked: boolean) => void;
  isAccountReplay?: boolean;
};

export const Messages = forwardRef<HTMLDivElement, MessagesProps>(
  (
    {
      webHost,
      brainId,
      messages,
      tags = null,
      resetClicked = false,
      setResetClicked = noop,
      isAccountReplay = false,
    },
    screenshotRef
  ) => {
    const { t } = useTranslation();
    const { brain } = useBrains(brainId);

    const {
      nodes,
      isReplay,
      activeNode: activeNodeIndex,
      isInputDisabled,
      handleReset,
      handleInput,
      handleNodeClick,
      bodyRef,
      inputRef,
      widgetRef,
    } = useTryIt(messages, brain, tags, webHost);

    const dispatch = useDispatch();

    useEffect(() => {
      if (resetClicked) {
        handleReset();
        dispatch(resetSelectedMessage());
        if (setResetClicked) {
          setResetClicked(false);
        }
      }
    }, [dispatch, handleReset, resetClicked, setResetClicked]);

    const resetLayout = useCallback(() => {
      if (!bodyRef.current) {
        return;
      }

      if (inputRef.current) {
        inputRef.current.focus();
      }

      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }, [bodyRef, inputRef]);

    const handleKeyPress = useCallback(
      async (e) => {
        if (isInputDisabled) {
          return;
        }
        if (isKeyEnter(e) && e.target.value) {
          handleInput(e.target.value);
          e.target.value = '';
        }
      },
      [isInputDisabled, handleInput]
    );

    const renderEmptyImage = () => {
      return (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          m="auto 0"
        >
          <NoMessagesIcon />
          <Typography
            component="p"
            variant="body-regular"
            color="var(--text-default-gray-light)"
          >
            {t('try_it.empty_page_image')}
          </Typography>
        </Box>
      );
    };

    return (
      <div className={styles.container} ref={widgetRef}>
        <div
          className={cn(styles.body, { [styles.bodyFullHeight]: isReplay })}
          ref={bodyRef}
        >
          {(!nodes || nodes.length === 0) && renderEmptyImage()}

          <div ref={screenshotRef}>
            {nodes?.map((node: NodeType, index: number) => {
              return (
                <MessageNode
                  {...node}
                  isComplete={node.isComplete || isReplay}
                  brainId={brainId}
                  broadcastId={node?.broadcast_id}
                  key={JSON.stringify(node)}
                  active={activeNodeIndex === index}
                  handleNodeClick={() => handleNodeClick(index)}
                  index={index}
                  resetLayout={resetLayout}
                >
                  {node.messages.map((message) => (
                    <MessageBubble
                      key={JSON.stringify(message)}
                      message={message}
                      onOptionClick={handleInput}
                      onPostback={handleInput}
                      collection={node.collection}
                      nodes_stack={node.nodes_stack}
                      brainId={brainId}
                      author_id={node.author_id}
                      author_type={node.author_type}
                      isAccountReplay={isAccountReplay}
                    />
                  ))}

                  {!node.isComplete && !isReplay && <TypingIndicator />}
                </MessageNode>
              );
            })}

            {isReplay && (
              <Typography
                component="p"
                m="18px 0 0 0"
                textAlign="center"
                className={styles.endOfSession}
                variant="label-regular"
              >
                {t('try_it.end_of_session')}
              </Typography>
            )}
          </div>
        </div>

        {!isReplay && (
          <Box m="var(--space-16) var(--space-32)" component="footer">
            <SendMessageInput
              inputRef={inputRef}
              autoFocus
              onKeyUp={handleKeyPress}
              placeholder={t('try_it.type_message_here')}
              handleButtonClick={(_, val: string) => {
                handleInput(val);

                // Track segment event
                trackEvent(EventName.SendTryItMessage);
              }}
            />
          </Box>
        )}
      </div>
    );
  }
);

Messages.displayName = 'TryIt';
