import { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import { MenuItem } from '@/modules/humanChat/models';

import {
  CommonPanelProps,
  panelDefaultStyle,
  panelTransitionStyles,
} from './helper';
import { DrawerList } from '../../LeftSidebar/Drawer/DrawerList';

import styles from '../InformationSection/InformationSection.module.scss';

type InformationPanelProps = CommonPanelProps & {
  items: MenuItem[];
  pinnedItems: string[];
};

export const InformationPanel = ({
  showPanel,
  onBackClick,
  items,
  pinnedItems,
}: InformationPanelProps) => {
  const { t } = useTranslation();
  const nodeRef = useRef(null);

  return (
    <Transition nodeRef={nodeRef} in={showPanel} timeout={300} unmountOnExit>
      {(state) => (
        <div
          ref={nodeRef}
          className={styles.panel}
          style={{
            ...panelDefaultStyle,
            ...panelTransitionStyles[state],
          }}
        >
          <DrawerList
            headerTitle={t('common.information')}
            onBackClick={onBackClick}
            items={items}
            pinnedItems={pinnedItems}
            variant="label-regular"
          />
        </div>
      )}
    </Transition>
  );
};
