import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import styles from './RoleBadge.module.scss';

type RoleBadgeProps = {
  role: string;
};

export const RoleBadge = ({ role }: RoleBadgeProps) => {
  const { t } = useTranslation();

  return (
    <Typography
      variant="heading2-regular"
      color="var(--text-default-black)"
      className={styles.role}
    >
      {t(`roles.${role}.name`)}
    </Typography>
  );
};
