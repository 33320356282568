import { useCallback, useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import HeadManager from '@/components/atoms/HeadManager/HeadManager';
import IconGoogle from '@/components/atoms/Icons/Google';
import Input from '@/components/atoms/Input/Input';
import Link from '@/components/atoms/Link/Link';
import Loading from '@/components/atoms/Loading/Loading';
import { Divider } from '@/components/organisms/Divider/Divider';
import useUser from '@/hooks/useUser';
import { EventName } from '@/models/segment';
import { loadUser } from '@/redux/user/actions';
import {
  selectIsAuthenticated,
  selectIsLoadingUser,
  selectIsTempUser,
} from '@/redux/user/selectors';
import { trackEvent } from '@/segment/segment';
import {
  PRIVACY_POLICY_URL,
  SUBSCRIPTION_AGREEMENT_URL,
} from '@/util/constants';
import { decodeBase64 } from '@/util/util';
import { emailRules } from '@/util/validator';

import { BackgroundWrapper } from '../../../BackgroundWrapper/BackgroundWrapper';

import styles from 'src/client/components/pages/LoginSignup/Signup.module.scss';

const PROVIDERS = window.AUTH_PROVIDERS || 'email,google';

const HAS_GOOGLE = PROVIDERS.includes('google');
const HAS_EMAIL = PROVIDERS.includes('email');

type LoginParams = {
  email: string;
};

// Sub-components
const TermsAndConditions = () => (
  <div className={styles.termsAndConditions}>
    <Typography variant="italic">
      <Trans i18nKey="signup.privacy_policy">
        By submitting my personal information, I understand and agree that
        Moveo.AI may collect, process, and retain my data pursuant to the
        Moveo.AI
        <Link
          target="_blank"
          href={PRIVACY_POLICY_URL}
          rel="noopener noreferrer"
        >
          Privacy Policy.
        </Link>
      </Trans>
    </Typography>
    <Typography variant="italic">
      <Trans i18nKey="signup.subscription_agreement">
        By signing up with any of the above 3 options you agree to the
        <Link
          target="_blank"
          href={SUBSCRIPTION_AGREEMENT_URL}
          rel="noopener noreferrer"
        >
          Moveo Subscription Agreement.
        </Link>
      </Trans>
    </Typography>
  </div>
);

export const RegisterPage = () => {
  // Custom hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { loginWithMagicLink } = useUser();
  const navigate = useNavigate();

  // Redux selectors
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoadingUser = useSelector(selectIsLoadingUser);
  const isTempUser = useSelector(selectIsTempUser);

  // Local state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasLoadedUser, setHasLoadedUser] = useState(false);

  // Local variables
  const redirectTo = String(
    location.state || `/invitation/details?${searchParams}`
  );
  const encodedRedirect = encodeURIComponent(redirectTo);
  const errorMessage = decodeBase64(searchParams.get('error_message'));
  const accountName = decodeBase64(searchParams.get('account_name'));
  const accountAvatar = decodeBase64(searchParams.get('account_avatar'));
  const inviterName = decodeBase64(searchParams.get('inviter_name'));
  const recipientName = decodeBase64(searchParams.get('recipient_name'));
  const roleIds = decodeBase64(searchParams.get('role_ids'));
  const accountId = decodeBase64(searchParams.get('account_id'));
  const invitationCode = decodeBase64(searchParams.get('invitation_code'));
  const accountSlug = decodeBase64(searchParams.get('account_slug'));
  const missingURLparams =
    !accountName ||
    !accountAvatar ||
    !inviterName ||
    !recipientName ||
    !roleIds ||
    !accountId ||
    !invitationCode ||
    !accountSlug;

  // RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<LoginParams>();

  const handleLoginWithMagicLink = useCallback(
    async ({ email }) => {
      setIsSubmitting(true);
      try {
        trackEvent(EventName.ClickLoginWithEmail);
        await loginWithMagicLink({ email, redirectTo });
        navigate(`/invitation/details?${searchParams}`, { replace: true });
      } catch (error) {
        setError(
          'email',
          { type: 'custom', message: error.rawMessage || error.message },
          { shouldFocus: true }
        );
      }
      setIsSubmitting(false);
    },
    [loginWithMagicLink, navigate, searchParams, redirectTo, setError]
  );

  useEffect(() => {
    if (!isAuthenticated && !isTempUser && !hasLoadedUser) {
      dispatch(loadUser());
      setHasLoadedUser(true);
    }
  }, [dispatch, hasLoadedUser, isAuthenticated, isTempUser]);

  // Redirect to the profile invitations if the user is already authenticated
  if (isAuthenticated) {
    return <Navigate to="/profile/invitations" />;
  }

  if (missingURLparams) {
    return <Navigate to="/" />;
  }

  if (isLoadingUser && !hasLoadedUser) {
    return <Loading />;
  }

  return (
    <HeadManager
      title="Join your team at Moveo.AI and experience the future of Customer Service"
      description="Join your team at Moveo.AI and automate over 70% of your processes, personalize customer interactions and boost your efficiency."
    >
      <BackgroundWrapper
        title={t('member_invitation.invited_to_join_account', {
          name: inviterName,
          accountName,
        })}
        subtitle={t('member_invitation.collaborate_on_moveo')}
        showBranding
      >
        {errorMessage && (
          <Alert className={styles.alert} severity="error">
            {errorMessage}
          </Alert>
        )}

        {HAS_EMAIL && (
          <form
            className={styles.startForm}
            onSubmit={handleSubmit(handleLoginWithMagicLink)}
          >
            <Input
              size="xlarge"
              name="email"
              id="email"
              label="email"
              placeholder="Email"
              errorMessage={errors.email?.message}
              {...register('email', emailRules(t))}
            />

            <Button
              type="submit"
              size="large"
              isFullWidth
              className={styles.button}
              isLoading={isSubmitting}
            >
              {t('signup.get_started')}
            </Button>
          </form>
        )}

        {HAS_EMAIL && HAS_GOOGLE && (
          <Divider text={t('rules.or').toUpperCase()} />
        )}

        {HAS_GOOGLE && (
          <Button
            href={`/www/api/auth/google?to=${encodedRedirect}`}
            size="large"
            isFullWidth
            isLink
            variant="social"
            className={styles.social}
            onClick={() => trackEvent(EventName.ClickLoginWithGoogle)}
          >
            <IconGoogle size={24} />
            {t('login.continue_with', { 0: t('login.google') })}
          </Button>
        )}

        <TermsAndConditions />
      </BackgroundWrapper>
    </HeadManager>
  );
};
