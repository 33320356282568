import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography = {
  ['heading1-regular']: {
    fontSize: 'var(--space-22)',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: 'calc(var(--space-24) + var(--space-4))',
  },
  ['heading1-medium']: {
    fontSize: '22px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: 'calc(var(--space-24) + var(--space-4))',
  },
  ['heading1-bold']: {
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '0',
    lineHeight: 'calc(var(--space-24) + var(--space-4))',
  },
  ['heading1-large']: {
    fontSize: '26px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: 'var(--space-32)',
  },
  ['heading1-xlarge']: {
    fontSize: '32px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: 'var(--space-36)',
  },
  ['heading1-xxlarge']: {
    fontSize: '36px',
    fontWeight: '900',
    letterSpacing: '0',
    lineHeight: '42px',
  },
} as TypographyOptions;

export default typography;
