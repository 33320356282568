import { useCallback, useMemo } from 'react';

import { ApolloError, gql, useQuery } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '@/models/state';
import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import {
  Bar,
  GeneralRow,
  getAnalyticsRange,
  prepareBar,
} from '@/util/analytics';
import { parseFilter } from '@/util/util';

import { ResponseType, ResponseTimesProps } from './useResponseTimes';
import { filterFiltersByUrl } from '../constants';

interface ResponseTimeHook {
  isLoading: boolean;
  isPreviousLoading?: boolean;
  currentError?: ApolloError;
  previousError?: ApolloError;
  refetchCurrent?: () => void;
  refetchPrevious?: () => void;
  data?: {
    series: Bar[];
  };
}
interface Rows {
  rows: GeneralRow[];
}

export const RESPONSE_TIMES_INTERVAL = gql`
  query ResponseTimesInterval(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $intervalInDays: interval
    $events: _text
    $firstMessage: Boolean
    $isTest: Boolean
    $agentIds: _text
    $minNumUserMessages: Int
  ) {
    rows: response_times_interval(
      args: {
        account_id: $accountId
        interval_span: $intervalInDays
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        events: $events
        first_message: $firstMessage
        is_test: $isTest
        agent_ids: $agentIds
        min_num_user_messages: $minNumUserMessages
      }
    ) {
      average_time
      median_time
      max_time
      min_time
      num_responses
      date: per_interval
    }
  }
`;

const initializeInterval = (
  startDate?: string | null,
  endDate?: string | null
) => {
  if (!startDate || !endDate) {
    return undefined;
  }
  const start = moment(startDate);
  const end = moment(endDate);

  const interval = new Array(end.diff(start, 'days') + 1);
  const currDay = start;
  for (let i = 0; i < interval.length; i += 1) {
    interval[i] = {
      average_time: 0,
      median_time: 0,
      max_time: 0,
      min_time: 0,
      date: currDay.format('YYYY-MM-DD'),
    };
    currDay.add(1, 'day');
  }

  return [...interval];
};

/**
 * Updates the given interval with the data array.
 *
 * @param interval The initialized array for each day between startDate and endDate
 * @param data The results from the query with the days where there was data
 */
const updateIntervalWithQueryResults = (
  interval?: GeneralRow[],
  data?: GeneralRow[]
): GeneralRow[] | undefined => {
  if (!interval || !data) {
    return undefined;
  }
  const newInterval = cloneDeep(interval);
  const startDate = moment(newInterval[0]?.date);
  for (let i = 0; i < data.length; i += 1) {
    const offset = moment(data[i]?.date).diff(startDate, 'days');

    if (offset < newInterval.length && offset >= 0) {
      newInterval[offset].average_time += data[i].average_time;
      newInterval[offset].median_time += data[i].median_time;
      newInterval[offset].max_time += data[i].max_time;
      newInterval[offset].min_time += data[i].min_time;
    }
  }
  return newInterval;
};

export const formatResponse = (
  currentTotal?: GeneralRow[],
  previousTotal?: GeneralRow[],
  type?: ResponseType
): { series: Bar[] } => {
  if (!currentTotal) {
    return undefined;
  }
  const { newBar } = prepareBar({ currentTotal, previousTotal, type });

  return {
    series: newBar,
  };
};

const useResponseTimesInterval = ({
  intervalProps,
  skip,
  skipPrevious,
  type,
}: ResponseTimesProps): ResponseTimeHook => {
  const accountId = useSelector(selectAccountId);
  const { startDate, endDate, is_contained, is_covered, firstMessage, events } =
    intervalProps;

  const location = useLocation();

  const filters = useSelector((state: RootState) => {
    return {
      analytics: state.analytics.accountAnalytics,
      startDate: startDate || state.analytics.startDate,
      endDate: endDate || state.analytics.endDate,
      filtersLoaded: state.analytics.filtersLoaded,
    };
  }, shallowEqual);

  const periodInDays = moment(filters.endDate).diff(
    moment(filters.startDate),
    'days'
  );

  const previousStartDate = filters.startDate
    ? moment(filters.startDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;
  const previousEndDate = filters.endDate
    ? moment(filters.endDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;

  const variables = useMemo(
    () =>
      Object.assign(
        {
          ...getAnalyticsRange(filters.startDate, filters?.endDate),
          accountId,
          intervalInDays: '1 day',
          isCovered: is_covered ?? undefined,
          isContained: is_contained ?? undefined,
          firstMessage,
          events,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      events,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      firstMessage,
      is_contained,
      is_covered,
      location.pathname,
    ]
  );

  const {
    data: currentData,
    loading: currentLoading,
    error: currentError,
    refetch: refetchCurrent,
  } = useQuery<Rows, QueryVariables>(RESPONSE_TIMES_INTERVAL, {
    variables,
    skip: skip || !accountId || !filters.filtersLoaded,
  });

  const {
    loading: previousLoading,
    data: previousData,
    error: previousError,
    refetch: refetchPrevious,
  } = useQuery<Rows, QueryVariables>(RESPONSE_TIMES_INTERVAL, {
    variables: {
      ...variables,
      startDate: previousStartDate,
      endDate: previousEndDate,
    },
    skip: skipPrevious || !accountId || !filters.filtersLoaded,
  });
  const currentRows = currentData?.rows;
  const previousRows = previousData?.rows;

  const data = useMemo(() => {
    const currentInterval = initializeInterval(
      filters.startDate,
      filters.endDate
    );
    const previousInterval = initializeInterval(
      previousStartDate,
      previousEndDate
    );

    if (
      !currentRows ||
      currentRows.length == 0 ||
      (currentRows.length == 1 && !currentRows[0].average_time)
    ) {
      return null;
    }
    const updatedCurrent = updateIntervalWithQueryResults(
      currentInterval,
      currentRows
    );
    const updatedPrevious = updateIntervalWithQueryResults(
      previousInterval,
      previousRows
    );

    return {
      ...formatResponse(updatedCurrent, updatedPrevious, type),
    };
  }, [
    currentRows,
    filters.endDate,
    filters.startDate,
    previousEndDate,
    previousRows,
    previousStartDate,
    type,
  ]);

  const onRefetchCurrent = useCallback(() => {
    refetchCurrent(variables);
  }, [refetchCurrent, variables]);

  const onRefetchPrevious = useCallback(() => {
    refetchPrevious({
      ...variables,
      startDate: previousStartDate,
      endDate: previousEndDate,
    });
  }, [previousEndDate, previousStartDate, refetchPrevious, variables]);

  if (currentLoading) {
    return {
      isLoading: true,
    };
  }

  if (previousLoading && !skipPrevious) {
    return {
      data,
      isLoading: false,
      isPreviousLoading: true,
    };
  }

  if (currentError || previousError) {
    return {
      isLoading: false,
      currentError,
      previousError,
      refetchCurrent: onRefetchCurrent,
      refetchPrevious: onRefetchPrevious,
    };
  }

  return { isLoading: false, data };
};

export default useResponseTimesInterval;
