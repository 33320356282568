import { useCallback, useMemo } from 'react';

import { ApolloError, DocumentNode, gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useDesks from '@/hooks/useDesks';
import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange } from '@/util/analytics';
import { parseFilter, shouldUseSlowQueries } from '@/util/util';

import { filterFiltersByUrl } from '../constants';
import { selectFilters } from '../redux/selectors';

export type ResponseRatingType = 'avg_rating' | 'median_rating';

interface Rows {
  rows: Row[];
}

interface Row {
  desk_id: string;
  num_requests: number;
  num_sessions: number;
  num_users: number;
  last_used: string;
}

interface MostUsedBrainsHook {
  isLoading: boolean;
  error?: ApolloError;
  refetch?: () => void;
  data?: Array<Row>;
}

export type MostUsedBrainsProps = {
  skip?: boolean;
  skipPrevious?: boolean;
};

const formatResponseTime = (current?: Row[]): Row[] | undefined => {
  if (!current || current.length === 0) {
    return undefined;
  }

  const result = current.reduce((acc, item) => {
    acc.push({
      desk_id: item.desk_id,
      num_requests: item.num_requests,
      num_sessions: item.num_sessions,
      num_users: item.num_users,
      last_used: moment(item.last_used).format('X'),
    });
    return acc;
  }, [] as Row[]);
  return result;
};

export const COUNTS_PER_DESK = gql`
  query CountsPerDesk(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $isTest: Boolean
    $minNumUserMessages: Int
  ) {
    rows: counts_per_desk(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        is_test: $isTest
        min_num_user_messages: $minNumUserMessages
      }
    ) {
      desk_id: id
      num_requests
      num_sessions
      num_users
      last_used
    }
  }
`;

export const COUNTS_PER_DESK_FAST = gql`
  query CountsPerDeskFast(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $startDate: timestamp
    $endDate: timestamp
    $isTest: Boolean
  ) {
    rows: counts_per_desk_fast(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        is_test: $isTest
      }
    ) {
      desk_id: id
      num_requests
      num_sessions
      num_users
      last_used
    }
  }
`;

const getQueryFromVariables = (variables): DocumentNode => {
  if (shouldUseSlowQueries(variables)) {
    return COUNTS_PER_DESK;
  }
  return COUNTS_PER_DESK_FAST;
};

const useMostUsedDesks = (skip?: boolean): MostUsedBrainsHook => {
  const { t } = useTranslation();
  const accountId = useSelector(selectAccountId);
  const filters = useSelector(selectFilters);
  const { desks } = useDesks();

  const location = useLocation();

  const variables = useMemo(
    () =>
      Object.assign(
        {
          ...getAnalyticsRange(filters.startDate, filters?.endDate),
          accountId,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      location.pathname,
    ]
  );

  const {
    data: currentData,
    loading: currentLoading,
    error,
    refetch,
  } = useQuery<Rows, QueryVariables>(getQueryFromVariables(variables), {
    variables,
    skip: skip || !accountId || !filters.filtersLoaded,
  });

  const currentRows = currentData?.rows;

  const data = useMemo(() => {
    const formatedData = formatResponseTime(currentRows);

    let deletedDesks = {
      num_requests: 0,
      num_sessions: 0,
      num_users: 0,
    } as Partial<Row> & { name?: string };

    const result = formatedData?.reduce((acc, item) => {
      const name = desks?.find(({ desk_id }) => desk_id === item.desk_id)?.name;
      if (name) {
        acc.push({
          ...item,
          name,
        });
      } else {
        deletedDesks = {
          num_requests: deletedDesks.num_requests + item.num_requests,
          num_sessions: deletedDesks.num_sessions + item.num_sessions,
          num_users: deletedDesks.num_users + item.num_users,
          name: t('analytics.deleted_envs'),
        };
      }
      return acc;
    }, []);

    return deletedDesks?.num_requests ? [...result, deletedDesks] : result;
  }, [currentRows, desks, t]);

  const onRefetch = useCallback(() => {
    refetch(variables);
  }, [refetch, variables]);

  if (currentLoading) {
    return {
      isLoading: true,
    };
  }

  if (error) {
    return {
      isLoading: false,
      error,
      refetch: onRefetch,
    };
  }

  return { isLoading: false, data };
};

export default useMostUsedDesks;
