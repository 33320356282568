import { useCallback, Fragment, useEffect, useRef, memo, useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { useRtmTyping } from '@/hooks/rtm/useRtmTyping';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import useMessages from '@/hooks/useMessages';
import { ConversationEvent, Message } from '@/models/chat';
import MessageAreaPrologue from '@/modules/humanChat/components/MessageAreaPrologue/MessageAreaPrologue';
import { useConversationEvents } from '@/modules/humanChat/hooks/useConversationEvents';
import { useConversationsNew } from '@/modules/humanChat/hooks/useConversationsNew';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';

import { Events } from './ConversationActivity/Events';
import { MessageGroup } from './ConversationActivity/MessageGroup';
import {
  getVisitorStatus,
  mergeAndSortData,
  shouldShowAvatar,
  shouldShowTime,
} from './helpers';
import { TimeElement } from './TimeElement/TimeElement';
import { TypingIndicator } from './TypingIndicator/TypingIndicator';

import styles from './MessageArea.module.scss';

interface Props {
  isLoading: boolean;
}

export type TimeElementType = {
  time_element: boolean;
  created: string;
};

export type MessageOrEvent = Message | ConversationEvent | TimeElementType;

const MessageArea = ({ isLoading }: Props) => {
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);
  const { conversation, reopenConversation, closeConversation } =
    useConversationsNew({ deskId, sessionId });
  const { visitorStatus, lastVisitorActivity } = getVisitorStatus(conversation);
  const isTyping = useRtmTyping();
  const loadMoreRef = useRef();
  const oldScrollRef = useRef<number>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const { messages, fetchNextPage, hasNextPage } = useMessages(
    deskId,
    sessionId
  );
  const { events } = useConversationEvents(deskId, sessionId);

  const firstLoad = messages?.pages?.[0]?.messages.length;

  const messagesPages = useMemo(
    () => [...(messages?.pages ?? [])],
    [messages?.pages]
  );
  const flatMessages = useMemo(
    () => messagesPages.flatMap((page) => page.messages),
    [messagesPages]
  );

  // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
  const isMessage = (item: MessageOrEvent): item is Message =>
    'message_id' in item;

  const isTimeElement = (item: MessageOrEvent): item is TimeElementType =>
    'time_element' in item;

  // for saving some iterations in flatMessages
  const messageIndexMap = useMemo(() => {
    const map = new Map();
    flatMessages.forEach((message, index) => {
      map.set(message.message_id, index);
    });
    return map;
  }, [flatMessages]);

  useEffect(() => {
    if (bodyRef.current && !isLoading) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  }, [isLoading, firstLoad]);

  useEffect(() => {
    if (messages?.pages.length > 1) {
      bodyRef.current.scrollTop =
        bodyRef.current.scrollHeight - oldScrollRef.current;
    }
  }, [messages?.pages.length]);

  const handleNextPageFetch = useCallback(() => {
    oldScrollRef.current = bodyRef.current.scrollHeight;
    return fetchNextPage();
  }, [fetchNextPage]);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: handleNextPageFetch,
  });

  const mergedData = useMemo(
    () => mergeAndSortData(flatMessages, events),
    [events, flatMessages]
  );

  const renderMessage = (message, index) => {
    const showTime = shouldShowTime({
      currentMessage: message,
      nextMessage: flatMessages[index - 1],
      index,
    });

    const showAvatar = shouldShowAvatar({
      currentMessage: message,
      nextMessage: flatMessages[index - 1],
      prevMessage: flatMessages[index + 1],
      index,
    });

    const user =
      message.author_type === 'visitor' ? conversation?.context?.user : null;
    return (
      <MessageGroup
        visitorStatus={visitorStatus}
        lastVisitorActivity={lastVisitorActivity}
        message={message}
        key={message.message_id}
        showTime={showTime}
        user={user}
        showAvatar={showAvatar}
      />
    );
  };

  const renderEvent = (event: ConversationEvent) => {
    return <Events key={event.event_id} event={event} />;
  };

  const renderTimeElement = (timeElement: TimeElementType) => {
    return (
      <TimeElement key={timeElement.created} created={timeElement.created} />
    );
  };

  const renderMessageOrEvent = (
    item: Message | ConversationEvent | TimeElementType
  ) => {
    if (isMessage(item)) {
      const index = messageIndexMap.get((item as Message).message_id);
      return renderMessage(item, index);
    } else if (isTimeElement(item)) {
      return renderTimeElement(item);
    } else {
      return renderEvent(item);
    }
  };

  return (
    <>
      <MessageAreaPrologue
        conversation={conversation}
        reopenConversation={reopenConversation}
        closeConversation={closeConversation}
      />

      <div className={styles.messageArea} ref={bodyRef}>
        {hasNextPage !== false && (
          <div className={styles.skeleton} ref={loadMoreRef}>
            <Skeleton height={40} width={180} />
          </div>
        )}
        {mergedData.map(renderMessageOrEvent)}
        <div className={cn(styles.typing, { [styles.isTyping]: isTyping })}>
          <TypingIndicator />
        </div>
      </div>
    </>
  );
};

export default memo(MessageArea);
