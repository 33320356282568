import CircularProgress from '@mui/material/CircularProgress';
import './Loading.scss';
import { useTranslation } from 'react-i18next';

const Loading = (props: { label?: string }) => {
  const { t } = useTranslation();
  return (
    <div className="loading__container">
      <div className="circular__progress">
        <CircularProgress size={40} />
        <p>{props.label || t('common.loading')}</p>
      </div>
    </div>
  );
};

export default Loading;
