import HeatMapFieldset from '../components/fieldsets/HeatMapFieldset/HeatMapFieldset';
import ScoreCardsFieldset from '../components/fieldsets/ScoreCardsFieldset/ScoreCardsFieldset';
import SessionsPerChannel from '../components/fieldsets/SessionsPerChannel/SessionsPerChannel';
import Filters from '../components/filters/AccountFilters/AccountFilters';

export const UsageReport = () => {
  return (
    <>
      <Filters />
      <ScoreCardsFieldset />
      <HeatMapFieldset />
      <SessionsPerChannel />
    </>
  );
};
