import { useCallback, useMemo } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { EventName } from '@/models/segment';
import useExport from '@/modules/analytics/hooks/useExport';
import { trackEvent } from '@/segment/segment';

import styles from './ExportDropdown.module.scss';

type Props = {
  className?: string;
  title?: string;
  setShow?: (value: boolean) => void;
  data?: unknown;
};

const ExportDropdown = ({ className, title, setShow, data }: Props) => {
  const { t } = useTranslation();
  const onExportClick = useExport();

  const options = useMemo(
    () => [
      { label: `${t('common.export')} CSV`, value: 'csv' },
      { label: `${t('common.export')} JSON`, value: 'json' },
    ],
    [t]
  );

  const handleExportClick = useCallback(
    (e) => {
      trackEvent(EventName.RunAnalyticsExport, {
        title: title || 'Usage',
        type: e.target.value,
      });
      onExportClick(data, title, e.target.value);
      setShow(false);
    },
    [data, onExportClick, setShow, title]
  );

  return (
    <div className={cn(styles.dropdown, className)}>
      <div className={styles.list_container}>
        {options.map((option) => (
          <button
            key={option.value}
            className={styles.export}
            onClick={handleExportClick}
            value={option.value}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ExportDropdown;
