import React, { useCallback } from 'react';

import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import IconButton from '@/components/atoms/IconButton/IconButton';
import { ActionMenu } from '@/components/organisms/Dialogs/ActionMenu/ActionMenu';
import { useCopyPaste } from '@/hooks/useCopyPaste';
import useHotkeys from '@/hooks/useHotkeys';
import useMenu from '@/hooks/useMenu';
import { Node } from '@/models/node';
import { iconByType } from '@/redux/dialogs/helper';
import { popModal } from '@/redux/modals/actions';
import { isModalOpen } from '@/redux/modals/selectors';

import styles from './NodeHeader.module.scss';

type Props = {
  type: string;
  nodeId: string;
  isRootNodeUnknown?: boolean;
  node: Node;
  isSelected: boolean;
};

const NodeHeader = ({
  type,
  nodeId,
  isRootNodeUnknown = true,
  node,
  isSelected,
}: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const { handlePaste, handleCopy } = useCopyPaste();
  const modalOpen = useSelector(isModalOpen);
  const navigate = useNavigate();
  const { slug, brainId } = useParams();
  const { deleteNode } = useMenu();
  const open = Boolean(anchorEl);
  const name =
    node.type === 'unknown' && !isRootNodeUnknown
      ? t('actions.types.unknown')
      : node.name;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogNameClick = useCallback(
    (dialog_id: string) => {
      dispatch(popModal());
      navigate(`/${slug}/brains/${brainId}/dialogs/${dialog_id}`);
    },
    [brainId, dispatch, navigate, slug]
  );

  const handleDelete = () =>
    deleteNode(nodeId, name as string, handleDialogNameClick);

  const hotkeys = {
    'mod+v': handlePaste,
    'mod+c': () => handleCopy(node),
    delete: handleDelete,
  };

  useHotkeys({ hotkeys, active: isSelected && !modalOpen });

  return (
    <div className={styles.header}>
      {iconByType({
        id: type,
        size: 24,
        color: 'var(--icon-default-blue)',
      })}
      <p>
        <Typography variant="label-bold" color="var(--text-default-blue)">
          {name}
        </Typography>
      </p>
      {!isRootNodeUnknown && (
        <div className={styles.header__icons}>
          {/* TODO: Use proper condition for comments when ready */}
          {/* {true && (
          <IconButton
            ariaLabel={t('dialog.carousel.comments')}
            ariaControls={t('dialog.carousel.comments')}
            ariaHasPopUp
            onClick={() => {}}
          >
            <Comments />
          </IconButton>
        )} */}
          <ActionMenu
            handleClose={handleClose}
            open={open}
            anchorEl={anchorEl}
            handleDelete={handleDelete}
            handleCopy={handleCopy}
            handlePaste={handlePaste}
            objectToCopy={node}
          />
          <IconButton
            ariaLabel="menu-btn"
            ariaControls="card-menu"
            ariaHasPopUp
            onClick={handleClick}
          >
            <MoreHoriz fontSize="small" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default NodeHeader;
