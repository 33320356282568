/* eslint-disable @typescript-eslint/no-explicit-any */
import fetch from 'cross-fetch';

import { API_URL, X_MOVEO_ACCOUNT_SLUG } from '@/util/constants';

declare const window: any;

type RequestOptions = RequestInit & {
  responseType?: 'json' | 'text' | 'blob';
};

/**
 * Convenience methods for calling the APIs
 *
 * includes .get(), .put(), .post(), and .delete() methods
 *
 * Assumes all bodies are JSON
 *
 * @param path - The API Path
 * @param [opts] - The options
 */
export const fetcher = (
  path: string,
  opts: RequestOptions = {}
): Promise<any> => {
  const slugHeader = {};
  if (window.X_MOVEO_ACCOUNT_SLUG) {
    slugHeader[X_MOVEO_ACCOUNT_SLUG] = window.X_MOVEO_ACCOUNT_SLUG;
  }

  const options: RequestOptions = {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Accept-Language': window._language ?? 'en',
      'Content-Type': opts.body ? 'application/json' : '',
      ...slugHeader,
    },
    responseType: 'json',
    ...opts,
  };

  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}${path}`, options)
      .then((response) => {
        if (response.ok) {
          if (response.status === 204) {
            return resolve({});
          }
          if (options.responseType === 'text') {
            return resolve(response.text());
          } else if (options.responseType === 'blob') {
            return resolve(response.blob());
          }
          return resolve(response.json());
        }

        // Check if the response is 401 and reloading the page
        // This prevent `useQuery` errors from happening without the user realizing.
        if (response.status === 401 && !path.endsWith('auth/user')) {
          console.error('Reload the page due to a 401 response');
          window.location.reload();
          return;
        }

        return response
          .json()
          .catch((error) => {
            // in case the json cant be parsed
            throw Object.assign(
              new Error(
                `Network request failed with: ${response.status} - ${response.statusText}. Please try again later.`
              ),
              {
                statusText: response.statusText,
                statusCode: response.status,
                requestId: response.headers.get('x-request-id'),
                error,
              }
            );
          })
          .then((json) => {
            const errMessage =
              json.error || json.description || JSON.stringify(json);
            throw Object.assign(new Error(errMessage), json, {
              statusText: response.statusText,
              statusCode: response.status,
              requestId: response.headers.get('x-request-id'),
            });
          });
      })
      .catch(reject);
  });
};

export const callGet = fetcher;

export const callPost = (
  path: string,
  body: any,
  options: any = {}
): Promise<any> =>
  fetcher(
    path,
    Object.assign(options, {
      method: 'POST',
      body: JSON.stringify(body),
    })
  );

export const callUpload = (
  path: string,
  body: any,
  options: any = {
    headers: { Accept: 'application/json' },
  }
): Promise<any> =>
  fetcher(
    path,
    Object.assign(options, {
      method: 'POST',
      body,
    })
  );

export const callPut = (
  path: string,
  body: any,
  options: any = {}
): Promise<any> =>
  fetcher(
    path,
    Object.assign(options, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
  );

export const callPatch = (
  path: string,
  body: any,
  options: any = {}
): Promise<any> =>
  fetcher(
    path,
    Object.assign(options, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })
  );

export const callDelete = (path: string, options: any = {}): Promise<any> =>
  fetcher(
    path,
    Object.assign(options, {
      method: 'DELETE',
    })
  );
