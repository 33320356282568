import { useCallback, useMemo, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useFeatureFlag from '@/hooks/useFeatureFlag';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { ActionType } from '@/modules/rules/model';
import {
  selectActionsOptions,
  selectRuleActions,
} from '@/modules/rules/redux/selectors';
import { getPermissions } from '@/redux/permissions/selectors';

import ActionDropdownButton from '../ActionDropdownButton/ActionDropdownButton';
import NewActionOptions from '../ActionsModal/NewActionOptions';
import OperatorMenu from '../Triggers/OperatorMenu';

import styles from './Actions.module.scss';

const Actions = () => {
  const { t } = useTranslation();
  const [showActionOptions, setShowActionOptions] = useState(false);
  const { enableBundles, enableCollectionsForRules } = useFeatureFlag();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'rules', actions.WRITE)
  );

  const ruleActions = useSelector(selectRuleActions);
  const actionsOptions = useSelector(selectActionsOptions);
  const showAddAction = ruleActions.length == 0;

  const handleAddContition = useCallback(() => {
    setShowActionOptions(true);
  }, []);

  // These are the options that will be shown in the first dropdown,
  // when you don't have any actions yet
  const calculateActionsOptions = useMemo(() => {
    let result = [...actionsOptions];
    if (!enableBundles) {
      result = result.filter((x) => x.type !== ActionType.APPLY_BUNDLE);
    }

    if (!enableCollectionsForRules) {
      result = result.filter((x) => x.type !== ActionType.ASSIGN_COLLECTION);
    }

    return result;
  }, [actionsOptions, enableBundles, enableCollectionsForRules]);

  return (
    <>
      {!showAddAction ? (
        <div
          className={cn(styles.outerWrapper, {
            [styles.disabled]: !canWrite,
          })}
        >
          {ruleActions.map((action, index) => {
            const {
              type,
              brain_version,
              brain_parent_id,
              tag,
              bundle_id,
              collection_id,
              department_id,
            } = action;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${type}-${index}`} className={styles.outterWrapper}>
                <div className={styles.innerWrapper}>
                  {index !== 0 && <OperatorMenu operator="and" disabled />}
                  <ClickAwayListener
                    onClickAway={() => setShowActionOptions(false)}
                  >
                    <ActionDropdownButton
                      index={index}
                      actionType={type}
                      brain_parent_id={brain_parent_id}
                      version={brain_version}
                      tag={tag}
                      grouped={ruleActions.length - 1 === index}
                      bundleId={bundle_id}
                      departmentId={department_id}
                      collectionId={collection_id}
                    />
                  </ClickAwayListener>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.condition}>
          <div
            className={styles.interactive}
            role="button"
            onClick={handleAddContition}
            onKeyDown={handleAddContition}
            tabIndex={0}
          >
            {t('rules.add_action')}
          </div>
          {showActionOptions && (
            <NewActionOptions
              options={calculateActionsOptions}
              handleOptionClick={() => setShowActionOptions(false)}
              index={0}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Actions;
