import { useCallback, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Documentation from '@/components/atoms/Icons/Global/Documentation';
import Trash from '@/components/atoms/Icons/Trash';
import Table from '@/components/atoms/Table/Table';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import useMembers from '@/hooks/useMembers';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { API_KEYS_DOCS_URL } from '@/util/constants';

import { GenerateKeyForm } from '../../components/GenerateKeyForm/GenerateKeyForm';
import useCredentials from '../../hooks/useCredentials';

import styles from './APIKeys.module.scss';

const sortColumns = (row1, row2, columnName) =>
  row1.values[columnName] > row2.values[columnName] ? 1 : -1;

export const APIKeys = ({ tabValue }) => {
  const { members } = useMembers();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { credentials, listStatus, deleteCredential } = useCredentials();

  const handleDelete = useCallback(
    (credential_id: string, name: string) => {
      const deleteProps = {
        subtitle: (
          <Trans i18nKey="developer_tools.delete_key_warning" values={[name]} />
        ),
        confirm: false,
        onDelete: () => {
          deleteCredential(credential_id, {
            onSuccess: () => {
              dispatch(
                addTemporalToast(
                  'success',
                  t('developer_tools.credentials.deleted')
                )
              );
            },
            onError: (error) => {
              dispatch(addErrorTemporalToast(error));
            },
          });

          dispatch(popModal());
        },
        secondaryButtonText: t('common.cancel'),
      };
      dispatch(pushModal(MODAL_DELETE, deleteProps));
    },
    [deleteCredential, dispatch, t]
  );

  const columns = useMemo(
    () => [
      {
        Header: t('common.name'),
        accessor: 'name',
        Cell: (props) => (
          <Typography variant="body-regular">{props.value}</Typography>
        ),
      },
      {
        Header: t('common.type'),
        accessor: 'type',
        Cell: (props) => (
          <Typography variant="body-regular">
            {t(`developer_tools.api_form.type.${props.value}`)}
          </Typography>
        ),
      },
      {
        Header: t('common.created_by'),
        accessor: 'created_by',
        Cell: (props) => {
          const member = members.find(
            (member) => member.user_id === props.value
          );
          return (
            <div className={styles.center}>
              <Avatar userId={props.value} hideStatus />
              <Typography variant="body-regular">
                {member?.name || '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        Header: t('common.created'),
        accessor: 'created',
        Cell: (props) => (
          <Typography variant="body-regular">
            {moment(props.value).format('DD-MM-YYYY')}
          </Typography>
        ),
      },
      {
        Header: t('common.last_used'),
        accessor: 'last_used_at',
        sortType: sortColumns,
        Cell: (props) => (
          <Typography variant="body-regular">
            {props.value ? moment(props.value).fromNow() : '-'}
          </Typography>
        ),
      },
      {
        Header: '',
        id: 'delete',
        Cell: (props) => {
          const credential_id = props.row.original.credential_id;
          const name = props.row.original.name;
          return (
            <IconButton
              onClick={() => handleDelete(credential_id, name)}
              ariaLabel={t('developer_tools.credentials.delete', {
                name,
              })}
            >
              <Trash size={16} />
            </IconButton>
          );
        },
      },
    ],
    [handleDelete, members, t]
  );

  return (
    <PermissionsWrapper module="credentials">
      <div
        role="tabpanel"
        hidden={tabValue !== 0}
        id={`devtools-tabpanel-${0}`}
        aria-labelledby={`devtools-tab-${0}`}
        className={styles.tabPanel}
      >
        <ContextualHelp
          title={t('developer_tools.contextual_help.api_keys_title')}
          name="about_api_keys"
          links={[
            {
              icon: <Documentation color="currentColor" />,
              label: t('docs.docs'),
              url: API_KEYS_DOCS_URL,
            },
          ]}
        >
          {t('developer_tools.contextual_help.api_keys_message')}
        </ContextualHelp>

        <div className={styles.tabPanel__content}>
          <PlainFieldset
            fullWidth
            overflown
            isLoading={listStatus === 'pending'}
            addPB
          >
            <Table
              title={t('developer_tools.active_api_keys')}
              data={credentials}
              columns={columns}
              sortBy="created_by"
              sortable
              variant="dark"
              headerHeight="medium"
              noGutters
              fullWidth={false}
              searchable={false}
            />
          </PlainFieldset>

          <GenerateKeyForm />
        </div>
      </div>
    </PermissionsWrapper>
  );
};
