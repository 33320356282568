import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router';

import Header from '@/components/organisms/Header/Header';
import Layout from '@/components/templates/Layout/Layout';
import { selectAccountSlug } from '@/redux/session/selectors';

import { APIKeys } from './pages/APIKeys/APIKeys';

import styles from './DeveloperToolsRouter.module.scss';

function a11yProps(index: number) {
  return {
    id: `devtools-tab-${index}`,
    'aria-controls': `devtools-tabpanel-${index}`,
  };
}

export const DeveloperToolsRouter = () => {
  // Custom hooks
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const slug = useSelector(selectAccountSlug);

  // Local variables
  const initialTabValue = location.pathname.endsWith('/plugins') ? 1 : 0;

  // Local state
  const [tabValue, setTabValue] = useState(initialTabValue);

  // Handlers
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    const newPath = `/${slug}/devtools/${newValue === 0 ? 'apikeys' : 'plugins'}`;
    navigate(newPath);
    setTabValue(newValue);
  };

  useEffect(() => {
    // Keep the tab value in sync with the URL
    if (initialTabValue !== tabValue) {
      setTabValue(initialTabValue);
    }
  }, [initialTabValue, tabValue]);

  useEffect(() => {
    // Redirect to the first tab if the user tries to access the devtools page directly
    if (location.pathname.endsWith('/devtools')) {
      navigate(`/${slug}/devtools/apikeys`);
    }
  }, [location.pathname, navigate, slug]);

  return (
    <Layout>
      <Header>
        <Header.Title
          title={
            <Typography
              variant="heading1-medium"
              color="var(--text-default-gray)"
            >
              {t('developer_tools.title')}
            </Typography>
          }
        />
        <Box mt={0.5}>
          <Header.Navigation>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label={t('developer_tools.title')}
            >
              <Tab label={t('developer_tools.api_keys')} {...a11yProps(0)} />

              {/* Uncomment for Webhook subscriptions implementation */}
              {/* <Tab
                label={t('developer_tools.webhook_subscriptions')}
                {...a11yProps(1)}
              /> */}
            </Tabs>
          </Header.Navigation>
        </Box>
      </Header>

      <div className={styles.wrapper}>
        <Routes>
          <Route path="/apikeys" element={<APIKeys tabValue={tabValue} />} />

          {/* Uncomment for Webhook subscriptions implementation */}
          {/* <Route
            path="/plugins"
            element={
              <div
                role="tabpanel"
                hidden={tabValue !== 1}
                id={`devtools-tabpanel-${1}`}
                aria-labelledby={`devtools-tab-${1}`}
              >
                {tabValue === 1 && (
                  <>ADD THE WEBHOOK SUBSCRIPTIONS IMPLEMENTATION HERE</>
                )}
              </div>
            }
          /> */}
        </Routes>
      </div>
    </Layout>
  );
};
