import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import groupBy from 'lodash/groupBy';
import moment from 'moment';

import { formatCents } from '@/modules/billing/util/billing';

function isLastDayOfMonth(timestamp) {
  const date = moment.unix(timestamp).subtract(1, 'days');
  const lastDayOfMonth = moment().endOf('month');

  return date.isSame(lastDayOfMonth, 'day');
}

// Method to transform invoice lines into table rows
export const parseInvoices = (invoiceLines, planName: string) => {
  // We group invoices by date to date from
  const groupedByDate = groupBy(invoiceLines, (item) => {
    const period = item.period
      ? `${moment.unix(item.period.start).subtract(1, 'days').format('MMM D')} - ${moment
          .unix(item.period.end)
          .subtract(1, 'days')
          .format('MMM D Y')}`
      : '';
    return period;
  });

  // Inside the date group, we group each by nickname
  // (so inside a date group ex. From 11 Sept - 20 Sept, we have the records grouped by session, seats, etc)
  const groupedByDateAndNickname = Object.entries(groupedByDate)
    .reverse()
    .map(([key, values]) => {
      const groupedByNickname = Object.entries(
        groupBy(values, 'price.nickname')
      ).map(([key, values]) => ({ key, values }));

      return { key, values: groupedByNickname };
    });

  /*
      Output is an array of objects { key: 'dateFrom - DateTo', values: groupedNicknames } where groupedNicknames is
      an array of { key: 'nickname', values: invoiceLines }
    */
  const tableRows = [];
  // Now we iterate the array to build the table rows
  for (const dateGroup of groupedByDateAndNickname) {
    const isUsageGroup = isLastDayOfMonth(
      dateGroup.values[0].values[0].period.end
    );
    const cell = (
      <span>
        <Typography variant="heading2-medium" component="p">
          {isUsageGroup ? t('billing.usage') : t('billing.renewal')}
        </Typography>
        <Typography
          color="var(--text-default-gray-light)"
          variant="label-caps-big"
        >
          {dateGroup.key}
        </Typography>
      </span>
    );

    tableRows.push({
      id: dateGroup.key,
      item: cell,
      count: '',
      unit_price: '',
      price: '',
      fromTo: null,
    });
    // Now we add the rows that correspond to that period (dateGroup.values)
    for (const nickNameGroup of dateGroup.values) {
      // If there is only one item in the array, these values comes from that array entry.
      // If not, these details are left blank because they'll be shown in the next rows.
      const totalQuantity =
        nickNameGroup.values.length === 1
          ? nickNameGroup.values[0].quantity
          : nickNameGroup.values.reduce(
              (acc, curr) => acc + (curr.quantity || 0),
              0
            );
      if (totalQuantity === 0) {
        tableRows.pop();
        continue;
      }
      const unitPrice =
        nickNameGroup.values.length === 1
          ? t(`billing.invoice.unit_price`, {
              val: formatCents(nickNameGroup.values[0].price.unit_amount),
            })
          : '';
      const total =
        nickNameGroup.values.length === 1
          ? t(`billing.invoice.unit_price`, {
              val: formatCents(nickNameGroup.values[0].amount),
            })
          : '';
      // Item row (completed if only one item in array, only item name if multiple)
      if (nickNameGroup.values.length === 1) {
        const isPlan = nickNameGroup.key === 'plan';
        const item = `${isPlan ? `${planName} ` : ''}${t(`billing.plan_table.${nickNameGroup.key}`)}`;
        tableRows.push({
          id: nickNameGroup.key,
          item,
          count: String(totalQuantity || 0),
          unit_price: unitPrice,
          price: total,
        });
      }

      // Here if there are multiple elements in the array, we iterate them to build the rows
      // This is the case for more than one record due to usage of multiple tiers
      if (nickNameGroup.values.length > 1) {
        // Each tier only contains quantity consumed into that tier, so to know the total consumed so far
        // we have to sum them in groupedQuantity variable
        let groupedQuantity = 0;
        for (const item of nickNameGroup.values) {
          groupedQuantity += item.quantity;
          const price = formatCents(item.amount);
          const currentTierIndex = item.price.tiers.findIndex((tier, i) => {
            const from = tier[i - 1]?.up_to || 0;
            const to = tier.up_to || Infinity;
            return groupedQuantity >= from && groupedQuantity <= to;
          });
          const tierUnitAmount = item.price.tiers[currentTierIndex].unit_amount;
          const unit = formatCents(
            tierUnitAmount ?? item.quantity / tierUnitAmount
          );
          let itemMsg = '';
          // Here we check if the tier is the first one, and if it has an up_to value
          // to decide the corresponding text to show
          if (currentTierIndex === 0) {
            itemMsg = `${t('billing.free')} ${t(`billing.plan_table.${nickNameGroup.key}`)}`;
          } else {
            itemMsg = `${t('billing.paid')} ${t(`billing.plan_table.${nickNameGroup.key}`)}`;
          }

          // Finally we push the tier row to the array of rows
          if (item.quantity !== 0) {
            tableRows.push({
              id: `${nickNameGroup.key}-${item.id}`,
              item: itemMsg,
              count: String(item.quantity || 0),
              unit_price: t(`billing.invoice.price`, { val: unit }),
              price: t(`billing.invoice.price`, { val: price }),
            });
          }
        }
      }
    }
  }

  // We sort the array properly and add an index so that the table component
  // uses that to sort the rows
  return tableRows.reverse().map((item, i) => ({ ...item, index: i }));
};
