import { useCallback } from 'react';

import isEmpty from 'lodash/isEmpty';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { InferType } from 'yup';

import { Banner } from '@/components/atoms/Banner/Banner';
import {
  MODAL_DELETE,
  MODAL_EDIT,
  MODAL_WARN,
} from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import useDialogNodesCache from '@/hooks/useDialogNodesCache';
import useExpressions from '@/hooks/useExpressions';
import useIntents from '@/hooks/useIntents';
import usePrompt from '@/hooks/usePrompt';
import {
  clearIntentExpressions,
  updateIntentData,
} from '@/redux/expressions/actions';
import { selectIntentDraft } from '@/redux/expressions/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { SYSTEM_INTENTS } from '@/util/constants';
import { getRestrictedNames, preventClickThrough } from '@/util/util';
import {
  getEditableTextValidationSchema,
  intentRules,
  LENGTH_S,
} from '@/util/validator';

import { convertSpacesToUnderscores } from './helper';
import SubHeader from './SubHeader';

const SubHeaderIntent = () => {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { findUsedNodes } = useDialogNodesCache();
  const navigate = useNavigate();
  const { intentName } = useParams();
  const { exportIntent, importIntent, isDirty, isDraft } = useExpressions(
    brainId,
    intentName
  );

  const {
    intents,
    deleteIntent,
    handleSaveIntent,
    updateStatus,
    createStatus,
  } = useIntents(brainId, intentName);

  const dispatch = useDispatch();
  const { slug } = useAccount();

  const intentDraft = useSelector(selectIntentDraft, shallowEqual);

  const onImportClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      preventClickThrough(e);
      importIntent(e.target.files[0]);
      e.target.value = '';
    },
    [importIntent]
  );

  const handleNameChange = useCallback(
    (text: string) => {
      dispatch(updateIntentData({ new_name: text, previousName: intentName }));
    },
    [dispatch, intentName]
  );

  const handleIntentNameClick = useCallback(
    (dialogId: string) => {
      dispatch(popModal());
      navigate(`/${slug}/brains/${brainId}/dialogs/${dialogId}`);
    },
    [brainId, dispatch, navigate, slug]
  );
  const onDeleteClick = useCallback(() => {
    const usedIn = findUsedNodes({ brainId, name: intentName });
    if (usedIn && !isEmpty(usedIn)) {
      const warning = (
        <Banner
          relativePosition
          variant="critical"
          references={usedIn}
          onRefClick={handleIntentNameClick}
        >
          <Trans i18nKey="intent.delete_warning" values={[intentName]} />
        </Banner>
      );

      const warnProps = {
        title: t('common.warning'),
        children: warning,
        primaryButtonText: t('common.close'),
      };

      dispatch(pushModal(MODAL_WARN, warnProps));
    } else {
      const deleteProps = {
        title: t('intent.delete_intent'),
        subtitle: (
          <Trans i18nKey="intent.delete_intent_warning" values={[intentName]} />
        ),
        onDelete: () => {
          dispatch(clearIntentExpressions());
          deleteIntent();
        },
      };

      dispatch(pushModal(MODAL_DELETE, deleteProps));
    }
  }, [
    brainId,
    deleteIntent,
    dispatch,
    findUsedNodes,
    handleIntentNameClick,
    intentName,
    t,
  ]);

  usePrompt(isDirty, t('prompts.leave_warning'), undefined, () =>
    handleSaveIntent(false)
  );

  // Updates the intent name using the edit info modal
  const onEditClick = useCallback(() => {
    const renameProps = {
      resouce: t('common.intent'),
      fieldName: 'new_name',
      fieldValue: intentDraft?.new_intent,
      rules: intentRules.name,
      onEdit: async (data) => {
        dispatch(
          updateIntentData({
            new_name: data.new_name,
            previousName: intentName,
          })
        );

        dispatch(popModal());
      },

      fields: [{}],
    };

    dispatch(pushModal(MODAL_EDIT, renameProps));
  }, [intentName, t, intentDraft?.new_intent, dispatch]);

  const isSaveEnabled = isDirty || isDraft;

  // Filters out the current dialog name from the list of restricted values
  const restrictedValues = getRestrictedNames(intents, intentName, 'intent');

  const editableTextValidationSchema = getEditableTextValidationSchema(
    LENGTH_S,
    restrictedValues,
    t('intent.system_rename')
  );

  type YupSchemaType = InferType<typeof editableTextValidationSchema>;

  return (
    <SubHeader<YupSchemaType>
      title={intentDraft?.new_intent}
      onImportClick={onImportClick}
      onExportClick={exportIntent}
      onDeleteClick={onDeleteClick}
      onSaveClick={handleSaveIntent}
      onNameChange={handleNameChange}
      disableSave={!isSaveEnabled}
      disableRename={SYSTEM_INTENTS.indexOf(intentName) !== -1}
      disableDelete={isDraft}
      isLoading={updateStatus === 'pending' || createStatus === 'pending'}
      fileExtension=".csv"
      onEditClick={onEditClick}
      variant="intents"
      editableTextValidationSchema={
        editableTextValidationSchema as YupSchemaType
      }
      customSanitizer={convertSpacesToUnderscores}
    />
  );
};

export default SubHeaderIntent;
