import { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import useDesks from '@/hooks/useDesks';
import { EventName } from '@/models/segment';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';

import { AccountMenu } from './AccountMenu/AccountMenu';
import { Changelog } from './Changelog/Changelog';
import { navigationItems } from './helper';
import { HelpMenu } from './HelpMenu/HelpMenu';
import NavigationItem from './NavigationItem/NavigationItem';
import { UserMenu } from './UserMenu/UserMenu';

import styles from './MainNavigation.module.scss';

const MainNavigation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const { desks, listStatus } = useDesks();
  const { showUpgradeButton } = useBilling();
  const firstDeskId = listStatus === 'success' && desks[0]?.desk_id;

  const slug = useSelector(selectAccountSlug);

  const handleClick = () => {
    trackEvent(EventName.ClickUpgradePlan);
    navigate(`/${slug}/account/billing`, {
      state: { shouldTriggerAction: Date.now() },
    });
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navigationList}>
        {navigationItems(deskId ?? firstDeskId).map(
          ({ icon, link, i18nKey, submenuItems }, index) => (
            <NavigationItem
              key={i18nKey}
              icon={icon}
              i18nKey={i18nKey}
              submenuItems={submenuItems}
              index={index}
              link={link}
            />
          )
        )}
      </div>

      <div className={styles.navbarOptions}>
        {showUpgradeButton && (
          <Button
            variant="primary"
            size="medium"
            onClick={handleClick}
            aria-label="label"
            className={styles.upgradeButton}
          >
            ✨ {t('billing.upgrade')}
          </Button>
        )}
        <AccountMenu />
        <Changelog />
        <HelpMenu />
        <UserMenu />
      </div>
    </nav>
  );
};

export default memo(MainNavigation);
