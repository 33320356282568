import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import TabsTable from '@/components/atoms/TabsTable/TabsTable';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import { numberFormatter } from '@/util/util';

import useCounts from '../../../hooks/useCounts';

import styles from './ConversationsHandled.module.scss';

const ConversationsHandled = () => {
  const [showPrevious, setShowPrevious] = useState(false);
  const { t } = useTranslation();

  const title = t('analytics.conv_handled');

  const handlePreviousClick = () => {
    setShowPrevious((prev) => !prev);
  };

  const brainConversations = useCounts({
    intervalProps: { type: 'sessions', events: '{message:brain_send}' },
    skipPrevious: !showPrevious,
  });
  const agentConversations = useCounts({
    intervalProps: { type: 'sessions', events: '{message:send}' },
    skipPrevious: !showPrevious,
  });

  const overallConversations = useCounts({
    intervalProps: { type: 'sessions' },
    skipPrevious: !showPrevious,
  });

  const handleReload = () => {
    brainConversations.refetchCurrent();
    agentConversations.refetchCurrent();
    overallConversations.refetchCurrent();
  };

  const items = useMemo(
    () => [
      {
        ...agentConversations,
        data: {
          ...agentConversations.data,
          counts: numberFormatter(agentConversations.data?.counts) as
            | string
            | number,
          exact_counts: numberFormatter(agentConversations.data?.counts, false),
          title: t('analytics.human_agents'),
        },
      },
      {
        ...brainConversations,
        data: {
          ...brainConversations.data,
          counts: numberFormatter(brainConversations.data?.counts) as
            | string
            | number,
          exact_counts: numberFormatter(brainConversations.data?.counts, false),
          title: t('common.brains'),
        },
      },
      {
        ...overallConversations,
        data: {
          ...overallConversations.data,
          counts: numberFormatter(overallConversations.data?.counts) as
            | string
            | number,
          exact_counts: numberFormatter(
            overallConversations.data?.counts,
            false
          ),
          title: t('analytics.overall'),
        },
      },
    ],
    [agentConversations, brainConversations, overallConversations, t]
  );

  const isError =
    agentConversations?.currentError &&
    brainConversations?.currentError &&
    overallConversations?.currentError;

  const isEmpty = items[0]?.data.counts === 0 && items[1]?.data.counts === 0;

  const isLoading =
    agentConversations?.isLoading &&
    brainConversations?.isLoading &&
    overallConversations?.isLoading;

  return (
    <AnalyticsFieldset
      fullWidth
      title={title}
      hasFooter={false}
      error={isError}
      onReload={handleReload}
      isEmpty={isEmpty}
      isLoading={isLoading}
      data={items}
    >
      <div className={styles.checkbox}>
        <Checkbox
          label={<TitleSubtitle subtitle={t('analytics.compare_prev')} />}
          onChange={handlePreviousClick}
          checked={showPrevious}
          sameLevel
        />
      </div>
      <TabsTable items={items} />
    </AnalyticsFieldset>
  );
};

export default ConversationsHandled;
