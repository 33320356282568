import { useCallback, useEffect, useState } from 'react';

import { MutationStatus } from '@tanstack/react-query';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';

import Search from '@/components/atoms/Icons/Search';
import Input from '@/components/atoms/Input/Input';
import ListSelect, {
  ListSelectItem,
} from '@/components/atoms/ListSelect/ListSelect';

import { BrainWithVersion } from '../ActionConfiguration';

import styles from './RuleActionTable.module.scss';

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  t,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  const handleChange = useCallback(
    (e) => {
      setValue(e.target.value);
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <Input
      iconLeft={<Search />}
      value={value || ''}
      onChange={handleChange}
      placeholder={t('rules.brains_count', { 0: count })}
      size="small"
    />
  );
}

type TableProps = {
  data: BrainWithVersion[];
  emptyMessage?: string;
  onSelectionClick: (row, version, saveChanges) => void;
  getStatus: MutationStatus;
  fullWidth: boolean;
};

const RuleActionTable = ({
  data,
  emptyMessage,
  fullWidth = true,
  onSelectionClick,
  getStatus,
}: TableProps) => {
  const { t } = useTranslation();
  const [listItems, setListItems] = useState([]);
  const [searchText, setSearchText] = useState('');

  const onListItemSelect = (...args: Array<string | number>) => {
    const [brainId, versionId] = args;
    const clickedRow = data.find((row) => row.brain_id == brainId);
    onSelectionClick(clickedRow, versionId, true);
  };

  const onRowClick = (...args: Array<string | number>) => {
    const [brainId, versionId] = args;
    const clickedRow = data.find((row) => row.brain_id == brainId);
    onSelectionClick(clickedRow, versionId, false);
  };

  useEffect(() => {
    const items: ListSelectItem[] = data
      .filter(
        (row) =>
          !searchText ||
          row.name.toLowerCase().includes(searchText.toLowerCase())
      )
      .map((row) => {
        const latestVersion = row?.versions?.find(
          (v) => v.value === row.version
        );
        const versionCount = row.versionCount;
        return {
          id: row.brain_id,
          text: row.name,
          textRight:
            versionCount === 0
              ? latestVersion?.label
              : t('version.vcount', { count: versionCount }),
          items: row?.versions.map((version) => ({
            id: version.value,
            text: version.label,
          })),
        };
      });
    setListItems(items);
  }, [data, t, searchText]);

  return (
    <div
      className={cn(styles.tableContainer, { [styles.fullWidth]: fullWidth })}
    >
      <table>
        <thead>
          <tr>
            <th className={styles.filterRow}>
              <GlobalFilter
                preGlobalFilteredRows={data}
                globalFilter={searchText}
                setGlobalFilter={setSearchText}
                t={t}
              />
            </th>
          </tr>
        </thead>
      </table>
      <div className={styles.listContainer} role="list">
        <ListSelect
          items={listItems}
          onSelect={onListItemSelect}
          onRowClick={onRowClick}
          secondLevelStatus={getStatus}
        />
      </div>
      {data.length === 0 && (
        <div className={styles.empty}>{emptyMessage || t('rules.empty')}</div>
      )}
    </div>
  );
};

export default RuleActionTable;
