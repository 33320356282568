import { useCallback, useState } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import AvatarGroups from '@/components/atoms/Avatar/AvatarGroup/AvatarGroup';
import { EditableText } from '@/components/atoms/EditableText/EditableText';
import ArrowDownIcon from '@/components/atoms/Icons/ArrowDown';
import BlobIcon from '@/components/atoms/Icons/BlobIcon';
import Dot from '@/components/atoms/Icons/Dot';
import Folder from '@/components/atoms/Icons/Folder';
import { actions } from '@/models/permissions';
import { Presence } from '@/models/presence';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { isKeyEnter } from '@/util/util';
import { LENGTH_XS } from '@/util/validator';

import Item, { ItemProps } from './Item';
import { SubnavType } from '../SubNav';
import { Collections } from '../SubNavIntent';

import styles from './TreeView.module.scss';

interface CollectionSubmitProps {
  collection: string;
  new_collection: string;
}

export type AsItems = Omit<ItemProps, 'isOver' | 'isOrphan'>;

interface CollectionProps {
  items: Array<AsItems>;
  nodeId: string;
  label: string;
  onCollectionUpdate: ({
    id,
    collection,
  }: {
    id: string;
    collection: string;
  }) => void;
  onCollectionSubmit: (data: CollectionSubmitProps) => void;
  statusColor?: string;
  isLastCreated?: boolean;
  whoIsViewing?: Presence[];
  onKeyDown?: (e: React.KeyboardEvent) => void;
  subnavType?: SubnavType;
  collections?: Collections;
}

function Collection({
  items,
  nodeId,
  label,
  onCollectionUpdate,
  onCollectionSubmit,
  statusColor,
  isLastCreated = false,
  whoIsViewing = [],
  onKeyDown,
  subnavType,
  collections,
}: CollectionProps) {
  const { t } = useTranslation();

  const recommendedCollection = t('intent.recommendations.collection');
  const [showItems, setShowItems] = useState(
    label === recommendedCollection ? false : true
  );
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  const isOrphan = label === '_orphans';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropActionInNode = (item: any) => {
    if (nodeId === '_orphans' && item.collection === '') {
      return;
    }
    if (nodeId !== item.collection) {
      onCollectionUpdate({ id: item.id, collection: nodeId });
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: 'treeItem',
    drop: dropActionInNode,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const recommendedCollectionName = t('intent.recommendations.collection');

  const handleClickShowItems = useCallback(
    (e) => {
      if (e.target.id !== 'editable-text-field') {
        setShowItems(!showItems);
      }
    },
    [showItems]
  );

  const handleEnterShowItems = useCallback(
    (e) => {
      if (isKeyEnter(e) || e.code === 'Space') {
        if (e.target.tagName !== 'INPUT') {
          setShowItems(!showItems);
        }
      }
    },
    [showItems]
  );

  const handleCollectionSubmit = useCallback(
    (newLabel) => {
      const defaultLabel = label === t('common.default') ? '' : label;
      onCollectionSubmit({
        new_collection: newLabel,
        collection: defaultLabel,
      });
    },
    [label, onCollectionSubmit, t]
  );

  const restrictedValues = Object.keys(collections ?? [])
    .map((x) => x.toLowerCase())
    .filter((key) => key !== label?.toLowerCase());
  const validationSchema = yup.object().shape({
    inputField: yup
      .string()
      .trim()
      .max(LENGTH_XS)
      .test(
        'unique-name',
        t('validation.name_already_exists'),
        function (value) {
          if (!value || !restrictedValues.includes(value.toLowerCase())) {
            return true;
          }
          return false;
        }
      )
      .label(t('subnav.folder_name')),
  });

  const renderCollectionTitle = useCallback(() => {
    if (label === recommendedCollectionName) {
      return (
        <div className={styles.recommended}>
          <Typography variant="label-caps-big">
            {label.toUpperCase()} ({items?.length})
          </Typography>
        </div>
      );
    }

    return (
      <div className={styles.name}>
        <EditableText<typeof validationSchema>
          defaultValue={label}
          onSubmit={handleCollectionSubmit}
          variant="label-caps-big"
          validationSchema={validationSchema}
          overflowEllipsis
          startFromEditMode={isLastCreated}
        />
      </div>
    );
  }, [
    label,
    recommendedCollectionName,
    handleCollectionSubmit,
    validationSchema,
    isLastCreated,
    items?.length,
  ]);

  return (
    <div
      ref={canWrite ? drop : null}
      className={cn(styles.treeCollection, {
        [styles.isOver]: isOver,
      })}
      onKeyDown={onKeyDown}
      onClick={() => {}}
      tabIndex={-1}
      role="button"
    >
      {!isOrphan && (
        <span
          role="button"
          tabIndex={0}
          className={styles.item}
          onClick={handleClickShowItems}
          onKeyDown={handleEnterShowItems}
        >
          <span className={cn(styles.icon, { [styles.collapse]: !showItems })}>
            <ArrowDownIcon color="var(--icon-default-gray)" size={18} />
          </span>

          <span>
            {label === recommendedCollectionName ? <BlobIcon /> : <Folder />}
          </span>

          {renderCollectionTitle()}

          {statusColor && (
            <span className={cn(styles.dot, { [styles.showItems]: showItems })}>
              <Dot color={statusColor} size={8} />
            </span>
          )}

          {!showItems && (
            <span className={styles.avatars}>
              <AvatarGroups max={2}>
                {whoIsViewing.map((x) => (
                  <Avatar key={x.agent_id} userId={x.agent_id} size="small" />
                ))}
              </AvatarGroups>
            </span>
          )}
        </span>
      )}
      {showItems &&
        items.map((item) => {
          return (
            <Item
              key={item.id}
              {...item}
              isOrphan={isOrphan}
              subnavType={subnavType}
            />
          );
        })}
    </div>
  );
}

export default Collection;
