import { memo } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Create from '@/components/atoms/Icons/Create';
function TileCreation({
  title,
  onCreateClick = () => {},
  isList = false,
  isBrains = false,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={cn({
        tile: true,
        tile__creation: true,
        'tile__creation--list': isList,
        'tile__creation--brains': isBrains,
      })}
      data-testid="tile-creation"
    >
      <button type="button" onClick={onCreateClick}>
        {!isList && <Create color="var(--icon-default-blue)" />}

        {title || t('common.create')}
      </button>
    </div>
  );
}

export default memo(TileCreation);
