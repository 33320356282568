import { useCallback, useMemo } from 'react';

import { ApolloError, gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '@/models/state';
import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange, getDateRangeText } from '@/util/analytics';
import { parseFilter, numberFormatter } from '@/util/util';

import { filterFiltersByUrl, MAX_RATING } from '../constants';

export type ResponseRatingType = 'avg_rating' | 'median_rating';

interface Row {
  avg_rating: number;
  median_rating: number;
  num_ratings: number;
}

interface Rows {
  rows: Row[];
}

interface Usage {
  counts: number | string;
  growth: number;
  id: string;
  timeframe: string;
  label?: string | number;
}

interface RatingCountHook {
  isLoading: boolean;
  isPreviousLoading?: boolean;
  currentError?: ApolloError;
  previousError?: ApolloError;
  refetchCurrent?: () => void;
  refetchPrevious?: () => void;
  data?: Usage;
}

export type ResponseType =
  | 'median_time'
  | 'average_time'
  | 'min_time'
  | 'max_time';

export type RatingCountProps = {
  intervalProps?: {
    value?: string;
    startDate?: string;
    endDate?: string;
    deskIds?: string;
    intervalInDays?: '1 day';
    is_contained?: boolean;
    is_covered?: boolean;
    firstMessage?: boolean;
    type?: 'onlyBrain' | 'onlyHuman';
  };
  skip?: boolean;
  skipPrevious?: boolean;
  type: ResponseRatingType;
};

const formatResponseTime = (
  t,
  current?: Row[],
  previous?: Row[]
): Omit<Usage, 'timeframe'>[] | undefined => {
  if (!current || current.length === 0) {
    return undefined;
  }

  const result = Object.keys(current[0]).reduce(
    (acc, key) => {
      if (key === '__typename' || key === 'num_responses') {
        return acc;
      }
      let growth = 0;
      if (previous && previous.length !== 0 && previous[0][key] > 0) {
        growth = Math.round(
          ((current[0][key] - previous[0][key]) / previous[0][key]) * 100
        );
      }
      acc.push({
        id: key,
        counts: `${current[0][key].toFixed(1)}/${MAX_RATING}`,
        growth: previous ? growth : undefined,
        label: t('analytics.out_of_reviews', [
          numberFormatter(current[0].num_ratings),
        ]),
      });
      return acc;
    },
    [] as Omit<Usage, 'timeframe'>[]
  );
  return result;
};

export const RATING_COUNTS = gql`
  query RatingsCounts(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $agentIds: _text
    $isTest: Boolean
    $onlyBrain: Boolean
    $onlyHuman: Boolean
  ) {
    rows: ratings_counts(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        is_test: $isTest
        agent_ids: $agentIds
        only_brain: $onlyBrain
        only_human: $onlyHuman
      }
    ) {
      avg_rating
      median_rating
      num_more_than_3
      num_ratings
    }
  }
`;

const useRatingCount = ({
  intervalProps,
  skip,
  skipPrevious,
  type,
}: RatingCountProps): RatingCountHook => {
  const { t } = useTranslation();
  const accountId = useSelector(selectAccountId);

  const filters = useSelector((state: RootState) => {
    return {
      analytics: state.analytics.accountAnalytics,
      startDate: intervalProps?.startDate || state.analytics.startDate,
      endDate: intervalProps?.endDate || state.analytics.endDate,
      deskIds: intervalProps?.deskIds,
    };
  }, shallowEqual);

  const { type: filterType } = intervalProps || {};

  const location = useLocation();

  const periodInDays = moment(filters.endDate).diff(
    moment(filters.startDate),
    'days'
  );

  const previousStartDate = filters.startDate
    ? moment(filters.startDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;
  const previousEndDate = filters.endDate
    ? moment(filters.endDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;

  const variables = useMemo(
    () =>
      Object.assign(
        {
          ...getAnalyticsRange(filters.startDate, filters?.endDate),
          accountId,
          deskIds: filters?.deskIds,
          onlyBrain: filterType === 'onlyBrain' || undefined,
          onlyHuman: filterType === 'onlyHuman' || undefined,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      filterType,
      filters.analytics,
      filters?.deskIds,
      filters.endDate,
      filters.startDate,
      location.pathname,
    ]
  );

  const {
    data: currentData,
    loading: currentLoading,
    error: currentError,
    refetch: refetchCurrent,
  } = useQuery<Rows, QueryVariables>(RATING_COUNTS, {
    variables,
    skip: skip || !accountId,
  });

  const {
    loading: previousLoading,
    data: previousData,
    error: previousError,
    refetch: refetchPrevious,
  } = useQuery<Rows, QueryVariables>(RATING_COUNTS, {
    variables: {
      ...variables,
      startDate: previousStartDate,
      endDate: previousEndDate,
    },
    skip: skip || skipPrevious || !accountId,
  });
  const currentRows = currentData?.rows;
  const previousRows = previousData?.rows;

  const data = useMemo(() => {
    const formatedData = formatResponseTime(t, currentRows, previousRows);
    const result = formatedData
      ?.filter((item) => item.id === type)
      .map((item) => ({
        ...item,
        id: item.id,
        timeframe: getDateRangeText(filters.startDate, filters.endDate),
      }))[0];
    return result;
  }, [currentRows, filters.endDate, filters.startDate, previousRows, t, type]);

  const onRefetchCurrent = useCallback(() => {
    refetchCurrent(variables);
  }, [refetchCurrent, variables]);

  const onRefetchPrevious = useCallback(() => {
    refetchPrevious({
      ...variables,
      startDate: previousStartDate,
      endDate: previousEndDate,
    });
  }, [previousEndDate, previousStartDate, refetchPrevious, variables]);

  if (currentLoading) {
    return {
      isLoading: true,
    };
  }

  if (previousLoading && !skipPrevious) {
    return {
      data,
      isLoading: false,
      isPreviousLoading: true,
    };
  }

  if (currentError || previousError) {
    return {
      isLoading: false,
      currentError,
      previousError,
      refetchCurrent: onRefetchCurrent,
      refetchPrevious: onRefetchPrevious,
    };
  }

  return { isLoading: false, data };
};

export default useRatingCount;
