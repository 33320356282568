import { useCallback } from 'react';

import { TypographyVariant } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import { EditableText } from '@/components/atoms/EditableText/EditableText';
import Link from '@/components/atoms/Icons/Link';
import PlusCircle from '@/components/atoms/Icons/PlusCircle';
import Trash from '@/components/atoms/Icons/Trash';
import Switch from '@/components/atoms/Switch/Switch';
import Header from '@/components/organisms/Header/Header';
import {
  MODAL_DELETE,
  RULE_CREATE,
} from '@/components/organisms/Modals/ModalConductor';
import useHomeCheckList, {
  AccountUserPrefsEnum,
} from '@/hooks/useHomeCheckList';
import usePrompt from '@/hooks/usePrompt';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useRules } from '@/modules/rules/hooks/useRules';
import { setDraftRule } from '@/modules/rules/redux/actions';
import {
  selectHasRuleActions,
  selectHasRuleTriggers,
  selectIsActionError,
  selectIsAnyConiditionOpen,
  selectIsConditionError,
  selectIsRuleDirty,
  selectRule,
} from '@/modules/rules/redux/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import {
  selectAccountSlug,
  selectDeskId,
  selectRuleId,
} from '@/redux/session/selectors';
import { getRestrictedNames } from '@/util/util';
import { getEditableTextValidationSchema, LENGTH_XS } from '@/util/validator';

import styles from './RulesHeader.module.scss';

const RulesHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deskId = useSelector(selectDeskId);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'rules', actions.WRITE)
  );
  const { markAsComplete } = useHomeCheckList();

  const navigate = useNavigate();
  const slug = useSelector(selectAccountSlug);
  const ruleId = useSelector(selectRuleId);
  const {
    rules,
    updateRule,
    addRule,
    isRuleLoading,
    createStatus,
    updateStatus,
    deleteRule,
  } = useRules(deskId, ruleId);
  const draftRule = useSelector(selectRule);
  const isConditionError = useSelector(selectIsConditionError);
  const isActionError = useSelector(selectIsActionError);
  const isDraft = location.pathname.includes('draft');
  const hasActions = useSelector(selectHasRuleActions);
  const hasTriggers = useSelector(selectHasRuleTriggers);
  const isDirty = useSelector(selectIsRuleDirty);
  const isConditionOpen = useSelector(selectIsAnyConiditionOpen);

  const onToggle = useCallback(() => {
    dispatch(
      setDraftRule({
        ...draftRule,
        dirty: true,
        status: draftRule?.status === 'active' ? 'inactive' : 'active',
      })
    );
  }, [dispatch, draftRule]);

  const onCancel = useCallback(() => {
    navigate(`/${slug}/environments/${deskId}/rules`);
  }, [deskId, navigate, slug]);

  const handleCreateRule = useCallback(() => {
    dispatch(pushModal(RULE_CREATE));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    markAsComplete(AccountUserPrefsEnum.UPDATE_RULE);
    if (isDraft) {
      addRule(undefined, {
        onSuccess: () => {
          navigate(`/${slug}/environments/${deskId}/rules`);
        },
      });
    } else {
      updateRule(draftRule, {
        onSuccess: () => {
          navigate(`/${slug}/environments/${deskId}/rules`);
        },
      });
    }
  }, [
    addRule,
    deskId,
    draftRule,
    isDraft,
    markAsComplete,
    navigate,
    slug,
    updateRule,
  ]);

  usePrompt(isDirty, t('common.header_prompt'), undefined, handleSubmit);

  const handleNameSubmit = useCallback(
    (text: string) => {
      dispatch(
        setDraftRule({
          ...draftRule,
          name: text,
          dirty: true,
        })
      );
    },
    [dispatch, draftRule]
  );

  // Filters out the current rule name from the list of restricted values
  const restrictedValues = getRestrictedNames(rules?.rules, draftRule?.name);

  const validationSchema = getEditableTextValidationSchema(
    LENGTH_XS,
    restrictedValues,
    t('rule.rule_name')
  );

  const editableTextProps = {
    defaultValue: draftRule?.name,
    onSubmit: handleNameSubmit,
    variant: 'heading1-medium' as TypographyVariant,
    canEdit: canWrite,
    validationSchema,
  };

  const handleDelete = useCallback(() => {
    const deleteProps = {
      subtitle: <Trans i18nKey="rules.delete_warning" values={[name]} />,
      confirm: true,
      onDelete: () => {
        deleteRule(ruleId, {
          onSuccess: () => {
            navigate(`/${slug}/environments/${deskId}/rules`);
            dispatch(popModal());
          },
        });
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [deleteRule, deskId, dispatch, navigate, ruleId, slug]);

  const handleCopyLink = useCallback(() => {
    const url = `${window.location.origin}/${slug}/environments/${deskId}/rules/${ruleId}`;
    navigator.clipboard.writeText(url);
  }, [deskId, ruleId, slug]);

  const menuItems = [
    {
      name: t('common.copy_link'),
      onClick: handleCopyLink,
      icon: <Link color="var(--icon-default-gray)" />,
      type: 'copy',
    },
    {
      name: t('common.delete'),
      icon: <Trash />,
      onClick: handleDelete,
      type: 'delete',
      disabled: ruleId === 'draft' || !canWrite,
    },
  ];

  if (ruleId) {
    return (
      <Header>
        <span className={styles.breadcrumbsWrapper}>
          <Header.BreadCrumbs />
          <Header.Menu menuItems={menuItems} />
        </span>
        <Header.Body>
          <Header.Title
            title={
              <EditableText<typeof validationSchema> {...editableTextProps} />
            }
            isLoading={isRuleLoading}
          />

          <Header.Actions>
            <div className={styles.toggle}>
              <span>{t('rules.active')}</span>
              <Switch
                checked={draftRule?.status === 'active'}
                size="medium"
                onChange={onToggle}
                disabled={!canWrite}
              />
            </div>
            <Button variant="secondary" onClick={onCancel}>
              {t('common.cancel')}
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={
                !canWrite ||
                isConditionError ||
                isActionError ||
                !hasActions ||
                !hasTriggers ||
                !isDirty ||
                isConditionOpen
              }
              isLoading={
                createStatus === 'pending' || updateStatus === 'pending'
              }
            >
              {t('common.save_and_close')}
            </Button>
          </Header.Actions>
        </Header.Body>
      </Header>
    );
  }

  return (
    <Header>
      <Header.Body>
        <Header.Title
          title={
            <Typography
              mr="var(--space-24)"
              variant="heading1-medium"
              color="var(--text-default-gray)"
            >
              {t('rules.rules')}
            </Typography>
          }
          withDesk
        />
        <Header.Actions>
          <Button onClick={handleCreateRule} disabled={!deskId || !canWrite}>
            <PlusCircle
              color={
                deskId && canWrite
                  ? 'var(--icon-default-white)'
                  : 'var(--icon-default-gray)'
              }
            />
            {t('rules.add_rule')}
          </Button>
        </Header.Actions>
      </Header.Body>
    </Header>
  );
};

export default RulesHeader;
