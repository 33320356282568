import { useCallback, useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { useTemplates } from '@/hooks/useTemplates';
import { useWebClient } from '@/hooks/useWebClient';
import BlueBox from '@/modules/onboarding/components/BrainInfo/BlueBox';
import WaveLeft from '@/modules/onboarding/icons/WaveLeft';
import { popModal } from '@/redux/modals/actions';
import { isKeyEnter } from '@/util/util';

import { CommonProps, TemplateTryProps } from '../ModalBrainCreationNew';

import styles from '../ModalBrainCreationNew.module.scss';

export const TemplateTry = (props: TemplateTryProps & CommonProps) => {
  const {
    template,
    createBrainFromTemplateStatus: status,
    createBrainFromTemplateAsync,
  } = useTemplates(props.templateId);
  const dispatch = useDispatch();
  const integrationId = template?.integration_id;
  const brain_id = template?.languages[props.language]?.brain_id;
  const loadedRef = useRef(false);

  const { initialiseWebchat } = useWebClient({
    integrationId,
    elementId: 'embed-chat',
    widget_position: 'embed',
    show_reset: true,
    language: props?.language,
  });

  useEffect(() => {
    if (integrationId && !loadedRef.current) {
      initialiseWebchat();
      loadedRef.current = true;
    }
  }, [initialiseWebchat, integrationId]);

  const handleApplyBrain = useCallback(() => {
    const { name, description } = props;
    createBrainFromTemplateAsync(
      { brain_id, name, description },
      { onSettled: async () => dispatch(popModal()) }
    );
  }, [brain_id, createBrainFromTemplateAsync, dispatch, props]);

  const handleKeyDown = useCallback(
    (e) => {
      if (isKeyEnter(e)) {
        handleApplyBrain();
      }
    },
    [handleApplyBrain]
  );

  return (
    <>
      <div className={styles.wave}>
        <WaveLeft />
      </div>
      <div className={styles.left}>
        <div className={styles.tryOut}>
          <span>{props.t('onboarding.try_out')}</span>
          <span>
            <div className={styles.iframe} id="embed-chat" />
          </span>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.top}>
          <span className={styles.title}>
            {props.t(`templates:${props.templateId}.title`)}
          </span>
          <span className={styles.buttons}>
            <Button
              onClick={handleApplyBrain}
              onKeyDown={handleKeyDown}
              disabled={status === 'pending'}
              isLoading={status === 'pending'}
            >
              {props.t('onboarding.select_template')}
            </Button>
          </span>
        </div>
        <div className={styles.infoContainer}>
          <p>{props.t(`templates:${props.templateId}.p1`)}</p>
          <p>{props.t(`templates:${props.templateId}.p2`)}</p>
          <span>{props.t(`templates:${props.templateId}.listTitle`)}</span>
          <ul>
            <li>{props.t(`templates:${props.templateId}.l1`)}</li>
            <li>{props.t(`templates:${props.templateId}.l2`)}</li>
            <li>{props.t(`templates:${props.templateId}.l3`)}</li>
          </ul>
        </div>
        <div className={styles.boxes}>
          <BlueBox text={props.t(`templates:${props.templateId}.blueBox1`)} />
        </div>
      </div>
    </>
  );
};
