import { useEffect } from 'react';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { ErrorIcon } from '@/components/atoms/Icons/Error';
import { PageName } from '@/models/segment';
import { pageView } from '@/segment/segment';

import styles from './Unauthorized.module.scss';

const Unauthorized: React.FC = () => {
  const { t } = useTranslation();
  const [URLSearchParams] = useSearchParams();
  const error = URLSearchParams.get('error');
  useTitle(t('pages.unauthorized'));

  useEffect(() => {
    pageView(PageName.UNAUTHORIZED);
  }, []);

  return (
    <div className={cn(styles.container)}>
      <Alert icon={false} severity="error">
        <div className={cn(styles.alert)}>
          <Typography variant="heading1-bold" className={cn(styles.title)}>
            <div className={cn(styles.titleContainer)}>
              <ErrorIcon size={24} className={cn(styles.icon)} />
              Oops! Something went wrong
            </div>
          </Typography>
          <Typography
            component={'span'}
            variant="heading2-medium"
            className={cn(styles.error)}
          >
            {error ? error : t('errors.you_are_not_authorized')}
          </Typography>
          <Typography variant="body-regular" className={cn(styles.footer)}>
            <p>
              {`Please try again later. If the problem persists, don't hesitate to
              contact our support team.`}
            </p>
          </Typography>
        </div>
      </Alert>
    </div>
  );
};

export default Unauthorized;
