import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography = {
  ['heading2-regular']: {
    fontSize: 'var(--space-16)',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: 'var(--space-24)',
  },
  ['heading2-medium']: {
    fontSize: 'var(--space-16)',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: 'var(--space-24)',
  },
  ['heading2-bold']: {
    fontSize: 'var(--space-16)',
    fontWeight: '700',
    letterSpacing: '0',
    lineHeight: 'var(--space-24)',
  },
  ['heading3-medium']: {
    fontSize: 'var(--space-18)',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: 'var(--space-24)',
  },
} as TypographyOptions;

export default typography;
