import { useState } from 'react';

import Skeleton from '@mui/material/Skeleton/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { EditableText } from '@/components/atoms/EditableText/EditableText';
import { BrowserIcon } from '@/components/atoms/Icons/BrowserIcon/BrowserIcon';
import Clock from '@/components/atoms/Icons/Clock';
import Email from '@/components/atoms/Icons/Email';
import Globe from '@/components/atoms/Icons/Globe';
import House from '@/components/atoms/Icons/House';
import LocationPin from '@/components/atoms/Icons/LocationPin';
import Phone from '@/components/atoms/Icons/Phone';
import { PlatformIcon } from '@/components/atoms/Icons/PlatformIcon/PlatformIcon';
import Link from '@/components/atoms/Link/Link';
import { IntegrationIcon } from '@/components/organisms/Integrations';
import { useConversationContext } from '@/hooks/useConversationContext';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useInformationSection } from '@/modules/humanChat/hooks/useInformationSection';
import {
  visitorAddressSchema,
  visitorEmailSchema,
  visitorPhoneSchema,
} from '@/modules/humanChat/validator';
import { getPermissions } from '@/redux/permissions/selectors';

import { CustomDetails } from '../CustomDetails/CustomDetails';
import { InformationPanel } from '../Panels/InformationPanel';

import styles from './InformationSection.module.scss';

export const IconByField: Record<string, JSX.Element> = {
  email: <Email size={16} color="var(--icon-default-gray)" />,
  local_time: <Clock size={16} color="var(--icon-default-gray)" />,
  languages: <Globe size={16} color="var(--icon-default-gray)" />,
  address: <House size={16} color="var(--icon-default-gray)" />,
  phone: <Phone size={16} color="var(--icon-default-gray)" />,
  location: <LocationPin size={16} color="var(--icon-default-gray)" />,
};

const InputSkeleton = () => <Skeleton width={80} height={20} />;

export const InformationSection = () => {
  // Local state
  const [showPanel, setShowPanel] = useState(false);

  // Hooks
  const { t } = useTranslation();
  const { isLoading, updateUser } = useConversationContext();
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'conversations', actions.WRITE)
  );
  const {
    items,
    pinnedItems,
    email,
    channel,
    browser,
    platform,
    cityAndCountry,
    usersTime,
    languages,
    address,
    phone,
  } = useInformationSection();

  // Local variables
  const foundItems = pinnedItems.reduce((acc, item) => {
    acc[item] = pinnedItems?.find((pinnedItem) => pinnedItem === item);
    return acc;
  }, {});

  // Handlers
  const onBackClick = () => {
    setShowPanel(false);
  };

  const handleSubmit = (key: string, value: string, prevValue: string) => {
    if (prevValue === value) return;

    updateUser({
      [key]: value,
    });
  };

  return (
    <section>
      <CustomDetails title={t('common.information')}>
        <ul className={styles.infoList}>
          <li
            className={cn({
              [styles.isEditable]: !isLoading,
            })}
          >
            <Tooltip arrow title={t('field.email')}>
              {IconByField.email}
            </Tooltip>

            <span>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <EditableText<typeof visitorEmailSchema>
                  defaultValue={email}
                  onSubmit={(value) => handleSubmit('email', value, email)}
                  color="var(--text-default-black)"
                  validationSchema={visitorEmailSchema}
                  placeholder={t('contacts.set_email')}
                  canEdit={canWrite}
                />
              )}
            </span>
          </li>

          {foundItems['channel'] && (
            <li>
              <Tooltip arrow title={t('common.channel')}>
                <IntegrationIcon size={16} type={channel} />
              </Tooltip>

              <span>{isLoading ? <InputSkeleton /> : channel}</span>
            </li>
          )}

          {foundItems['browser'] && (
            <li>
              <Tooltip arrow title={t('rules.browser')}>
                <BrowserIcon browser={browser} />
              </Tooltip>

              <span>{isLoading ? <InputSkeleton /> : browser}</span>
            </li>
          )}

          {foundItems['platform'] && (
            <li>
              <Tooltip arrow title={t('rules.platform')}>
                <PlatformIcon platform={platform} />
              </Tooltip>

              <span>{isLoading ? <InputSkeleton /> : channel}</span>
            </li>
          )}

          <li>
            <Tooltip arrow title={t('contacts.location')}>
              {IconByField.location}
            </Tooltip>
            <span>{isLoading ? <InputSkeleton /> : cityAndCountry}</span>
          </li>

          <li>
            <Tooltip arrow title={t('contacts.local_time')}>
              {IconByField.local_time}
            </Tooltip>
            <span>{isLoading ? <InputSkeleton /> : usersTime}</span>
          </li>

          <li>
            <Tooltip arrow title={t('common.languages')}>
              {IconByField.languages}
            </Tooltip>
            <span>{isLoading ? <InputSkeleton /> : languages}</span>
          </li>

          {foundItems['address'] && (
            <li
              className={cn({
                [styles.isEditable]: !isLoading,
              })}
            >
              <Tooltip arrow title={t('field.address')}>
                {IconByField.address}
              </Tooltip>
              <span>
                {isLoading ? (
                  <InputSkeleton />
                ) : (
                  <EditableText<typeof visitorAddressSchema>
                    defaultValue={address}
                    onSubmit={(value) =>
                      handleSubmit('address', value, address)
                    }
                    validationSchema={visitorAddressSchema}
                    color="var(--text-default-black)"
                    placeholder={t('contacts.set_address')}
                    canEdit={canWrite}
                  />
                )}
              </span>
            </li>
          )}

          {foundItems['phone'] && (
            <li
              className={cn({
                [styles.isEditable]: !isLoading,
              })}
            >
              <Tooltip arrow title={t('field.phone')}>
                {IconByField.phone}
              </Tooltip>
              <span className={styles.time}>
                {isLoading ? (
                  <InputSkeleton />
                ) : (
                  <>
                    <EditableText<typeof visitorPhoneSchema>
                      defaultValue={phone}
                      onSubmit={(value) => handleSubmit('phone', value, phone)}
                      color="var(--text-default-black)"
                      validationSchema={visitorPhoneSchema}
                      placeholder={t('contacts.set_phone')}
                      canEdit={canWrite}
                    />
                  </>
                )}
              </span>
            </li>
          )}

          <li>
            <Link
              onClick={(e) => {
                e.preventDefault();
                setShowPanel(true);
              }}
              className={styles.link}
              href={'/'}
            >
              {t('onboarding.show_all', {
                0: '',
              })}
            </Link>
          </li>
        </ul>
      </CustomDetails>

      <InformationPanel
        showPanel={showPanel}
        onBackClick={onBackClick}
        items={items}
        pinnedItems={pinnedItems}
      />
    </section>
  );
};
