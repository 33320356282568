import body from './typography/body';
import heading1 from './typography/heading1';
import heading2 from './typography/heading2';
import supporting from './typography/supporting';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body: React.CSSProperties;
    ['body-regular']: React.CSSProperties;
    ['body-medium']: React.CSSProperties;
    ['body-bold']: React.CSSProperties;
    ['body-hyperlink']: React.CSSProperties;
    ['heading1-regular']: React.CSSProperties;
    ['heading1-medium']: React.CSSProperties;
    ['heading1-bold']: React.CSSProperties;
    ['heading1-large']: React.CSSProperties;
    ['heading1-xlarge']: React.CSSProperties;
    ['heading1-xxlarge']: React.CSSProperties;
    ['heading2-regular']: React.CSSProperties;
    ['heading2-medium']: React.CSSProperties;
    ['heading3-medium']: React.CSSProperties;
    ['heading2-bold']: React.CSSProperties;
    ['label-regular']: React.CSSProperties;
    ['label2-regular']: React.CSSProperties;
    ['label2-medium']: React.CSSProperties;
    ['label-caps-small']: React.CSSProperties;
    ['label-caps-big']: React.CSSProperties;
    ['label-bold']: React.CSSProperties;
    ['label-caps-bold']: React.CSSProperties;
    ['label-nav-regular']: React.CSSProperties;
    ['code-small']: React.CSSProperties;
    ['code-big']: React.CSSProperties;
    ['italic']: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body?: React.CSSProperties;
    ['body-regular']?: React.CSSProperties;
    ['body-medium']?: React.CSSProperties;
    ['body-bold']?: React.CSSProperties;
    ['body-hyperlink']?: React.CSSProperties;
    ['heading1-regular']?: React.CSSProperties;
    ['heading1-medium']?: React.CSSProperties;
    ['heading1-bold']?: React.CSSProperties;
    ['heading1-large']?: React.CSSProperties;
    ['heading1-xlarge']?: React.CSSProperties;
    ['heading1-xxlarge']?: React.CSSProperties;
    ['heading2-regular']?: React.CSSProperties;
    ['heading3-medium']?: React.CSSProperties;
    ['heading2-medium']?: React.CSSProperties;
    ['heading2-bold']?: React.CSSProperties;
    ['label-regular']?: React.CSSProperties;
    ['label2-regular']?: React.CSSProperties;
    ['label2-medium']?: React.CSSProperties;
    ['label-caps-small']?: React.CSSProperties;
    ['label-caps-big']?: React.CSSProperties;
    ['label-bold']?: React.CSSProperties;
    ['label-caps-bold']?: React.CSSProperties;
    ['label-nav-regular']?: React.CSSProperties;
    ['code-small']?: React.CSSProperties;
    ['code-big']?: React.CSSProperties;
    ['italic']: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body: true;
    ['body-regular']: true;
    ['body-medium']: true;
    ['body-bold']: true;
    ['body-hyperlink']: true;
    ['heading1-regular']: true;
    ['heading1-medium']: true;
    ['heading1-bold']: true;
    ['heading1-large']: true;
    ['heading1-xlarge']: true;
    ['heading1-xxlarge']: true;
    ['heading2-regular']: true;
    ['heading2-medium']: true;
    ['heading3-medium']: true;
    ['heading2-bold']: true;
    ['label-regular']: true;
    ['label2-regular']: true;
    ['label2-medium']: true;
    ['label-caps-small']: true;
    ['label-caps-big']: true;
    ['label-bold']: true;
    ['label-caps-bold']: true;
    ['label-nav-regular']: true;
    ['code-small']: true;
    ['code-big']: true;
    ['italic']: true;
  }
}

const FONT_PRIMARY = ['"Inter"', '"Helvetica Neue"', 'Arial'].join(',');

const typography = {
  fontSize: 14,
  fontFamily: FONT_PRIMARY,

  h1: {
    fontSize: 'var(--font-h1)',
    fontWeight: 500,
    color: 'var(--text-default-white)',
  },
  h2: {
    fontSize: 'var(--font-h2)',
    fontWeight: 500,
    letterSpacing: '-1px',
    color: 'var(--text-default-white)',
  },
  h3: {
    fontSize: 'var(--font-h1)',
    fontWeight: 700,
    letterSpacing: '2px',
    color: 'var(--text-default-black)',
  },

  h6: {
    fontSize: 'var(--font-h6)',
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: '16px',
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: '12px',
    color: 'var(--text-default-gray)',
  },
  body: {
    color: 'var(--text-default-black)',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: 'var(--space-20)',
  },
  bodyMedium: {
    color: 'var(--text-default-black)',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: 'var(--space-20)',
  },
  ...body,
  ...heading1,
  ...heading2,
  ...supporting,
};

export default typography;
