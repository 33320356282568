import { PropsWithChildren } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';

import Tick from '@/components/atoms/Icons/Tick';

import styles from './StepFieldset.module.scss';
interface Props {
  title: string;
  step: number;
  status: 'completed' | 'active' | 'disabled';
}

const StepFieldset = ({
  title,
  step,
  status = 'disabled',
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {status === 'completed' ? (
          <Tick size={18} backgroundColor="var(--icon-default-success)" />
        ) : (
          <Typography className={cn(styles.step, styles[status])}>
            {step}
          </Typography>
        )}
        <Typography variant="heading3-medium">{title}</Typography>
      </div>
      <div className={styles.main}>{children}</div>
    </div>
  );
};

export default StepFieldset;
