import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';

import { RootState } from '@/models/state';

/**
 * Selector to retrieve viewers by brain ID.
 * @param {RootState} state - The global state of the application.
 * @param {string} brainId - The unique identifier for the brain.
 * @returns {Array} An array of viewers associated with the specified brain ID.
 */
export const selectViewersByBrainId = (state: RootState, brainId: string) =>
  state.presence.brains?.[brainId];

/**
 * Selector to retrieve viewers by collection ID.
 * @param {RootState} state - The global state of the application.
 * @param {string} collectionId - The unique identifier for the collection.
 * @returns {Array} An array of viewers associated with the specified collection ID.
 */
export const selectViewersByCollectionId = (
  state: RootState,
  collectionId: string
) => state.presence.collections?.[collectionId];

/**
 * Selector to return all the account members.
 * @param {RootState} state - The global state of the application.
 * @returns {Array} An array of all agents.
 */
export const selectAllAgents = (state: RootState) => state.presence.agents;

/**
 * Selector to retrieve an agent by their ID.
 * @param {RootState} state - The global state of the application.
 * @param {string} agentId - The unique identifier for the agent.
 * @returns {Object|null} The agent object if found, otherwise null.
 */
export const selectAgentById = createSelector(
  [selectAllAgents, (_, agentId) => agentId],
  (agents, agentId) => {
    if (!agentId) return null;

    return agents.find((agent) => agent.agent_id === agentId);
  }
);

const selectOnlineAgents = createSelector([selectAllAgents], (agents) =>
  agents.filter((agent) => agent.status === 'online')
);

/**
 * Selector to retrieve viewers by session ID.
 * @param {RootState} state - The global state of the application.
 * @param {string} sessionId - The unique identifier for the session.
 * @returns {Array} An array of viewers associated with the specified session ID.
 */
export const selectViewersBySessionId = (state: RootState, sessionId: string) =>
  state.presence.sessions[sessionId];

/**
 * Selector to retrieve the last three online viewers by session ID.
 * @param {RootState} state - The global state of the application.
 * @param {string} sessionId - The unique identifier for the session.
 * @returns {Object} An object containing the array of the last three online viewers and the total count of online viewers.
 */
export const selectLast3ViewersBySessionId = createSelector(
  [selectOnlineAgents, selectViewersBySessionId],
  (onlineAgents, viewers) => {
    const onlineViewers = viewers?.filter((viewer) => {
      return onlineAgents.some((agent) => agent.agent_id === viewer.user_id);
    });
    return {
      viewers: sortBy(onlineViewers, 'last_activity_at').slice(0, 3),
      count: onlineViewers?.length,
    };
  }
);

export const selectOnlineViewersByBrainId = createSelector(
  [selectOnlineAgents, selectViewersByBrainId],
  (onlineAgents, viewers) => {
    const onlineViewers = viewers?.filter((viewer) => {
      return onlineAgents.some((agent) => agent.agent_id === viewer.user_id);
    });
    return {
      viewers: sortBy(onlineViewers, 'last_activity_at'),
    };
  }
);
