import { IntegrationType } from './integration';
import { ResponseMessage } from './tryIt';

export type SessionState = {
  active: boolean;
  expired_at: string;
  last_activity_at?: string;
};
export type Conversations = {
  conversations: Conversation[];
  pagination: {
    has_more: boolean;
    limit: number;
    next_cursor: string;
    prev_cursor: string;
  };
};

type UserLocation = {
  city?: string;
  country: string;
  region?: string;
  latitude?: number;
  longitude?: number;
};

export type User = {
  readonly user_id?: string;
  readonly external_id?: string;
  readonly contact_id?: string;

  location?: UserLocation;
  locales?: string[];
  address?: string;
  timezone?: string;
  phone?: string;

  browser?: string;
  platform?: string;
  device?: string;
  ip?: string;

  display_name?: string;
  avatar?: string;
  language?: 'en' | 'el' | 'es' | 'ro' | undefined;
  gender?: 'male' | 'female' | 'other' | '-' | undefined;
  email?: string;

  facebook_psid?: string;
  twitter_handler?: string;
  viber_psid?: string;
  whatsapp_psid?: string;

  verified?: boolean | string;
  verified_method?: 'jwt' | 'otp' | 'email' | 'qr' | undefined;
  verified_at?: string;

  past_visits?: number;
  past_chats?: number;
  last_seen_at?: string;
  last_replied_at?: string;
  last_contacted_at?: string; // Last time we got a message from the user

  // if external_id is set and this is an external user
  external_details?: Record<string, string | number | boolean | string[]>;
};

export type Context = Partial<{
  user: User;
  tags: string[];
  global: {
    bundle?: Record<string, string | boolean | number>;
    client_url?: string;
  };
  channels: {
    [key in IntegrationType]?: Record<
      string,
      string | string | boolean | number
    >;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}>;

export type ComposeBody = {
  author_id: string;
  author_type: string;
  action: 'start' | 'stop';
  updated: string;
};

export type Compose = Record<string, ComposeBody>;

export type Conversation = {
  account_id: string;
  agent_unread_count: number;
  assignee_agent_id: string | null;
  assignee_brain_id: string | null;
  channel: IntegrationType;
  compose: Compose;
  context: Context;
  created: string;
  department_id: string;
  desk_id: string;
  integration_id: string;
  last_agent_assigned_at: string;
  last_message_at: string;
  last_message: LastMessage;
  message_count: number;
  participants: Record<string, string>;
  session_id: string;
  state: SessionState;
  status: 'open' | 'closed' | 'spam' | 'trash';
  updated: string;
  visitor_unread_count: number;
  waiting_since: string;
  waiting_time: number;
};

type BaseMessage = {
  readonly author_id: string;
  readonly message_id: string;
  readonly session_id: string;
  readonly created: string;
  readonly updated: string;
};

type CommonMessageProperties = {
  readonly action?: 'start' | 'stop';
  readonly read_at: string;
  readonly delivered_at: string;
  readonly assignee_agent_id?: string;
  readonly agent_unread_count?: number;
  readonly desk_id?: string;
  readonly author_name?: string | null;
};

type BodyType = 'image' | 'file' | 'text' | 'video' | 'audio' | 'url';

type BrainMessage = {
  author_type: 'brain';
  body: ResponseMessage;
  text: string;
};

type OtherMessage = {
  author_type: 'agent' | 'visitor' | 'broadcast';
  body?: {
    type: BodyType;
    url?: string;
    text?: string;
    action_id?: string;
  };
  text: string;
};

export type LastMessage = (BaseMessage & (BrainMessage | OtherMessage)) | null;

export type Message = BaseMessage &
  CommonMessageProperties &
  (BrainMessage | OtherMessage);

export type Messages = {
  messages: Message[];
  pagination: {
    has_more: boolean;
    limit: number;
    next_cursor: string;
    prev_cursor: string;
  };
};

export type ChatState = {
  activeTab?: ChatStateType;
  messageSound: string | null;
  sideBarOpen: boolean;
  view: {
    selectedStatus?: ConversationStatusType;
    selectedAgent?: string;
    selectedDepartment?: string;
    selectedBrain?: string;
    selectedSort?: 'newest' | 'oldest';
    title?: string;
  };
};

export enum AuthorType {
  VISITOR = 'visitor',
  AGENT = 'agent',
  BRAIN = 'brain',
}

export type SessionActivity = {
  session_id: string;
  timestamp: number;
};

type CommonTypingFields = {
  request_id: string;
  session_id: string;
  action: 'start' | 'stop';
  author_id: string;
  timestamp: number;
};

type BrainTyping = CommonTypingFields & {
  author_type: AuthorType.BRAIN;
};

type AgentTyping = CommonTypingFields & {
  desk_id: string;
  author_name: string;
  author_type: AuthorType.AGENT;
};

type VisitorTyping = CommonTypingFields & {
  desk_id: string;
  author_name: string;
  author_type: AuthorType.VISITOR;
};

export type Typing = BrainTyping | AgentTyping | VisitorTyping;

export type MarkMessages = {
  request_id: string;
  session_id: string;
  timestamp: number;
  author_type: AuthorType;
};

export const ChatState = {
  ME: 'me',
  UNASSIGNED: 'unassigned',
  OPEN: 'open',
  BRAIN: 'brain',
  CLOSED: 'closed',
  MY_CLOSED: 'my_closed',
  SPAM: 'spam',
  TRASH: 'trash',
  ALL: 'all',
} as const;

export const ConversationStatus = {
  OPEN: 'open',
  CLOSED: 'closed',
  TRASH: 'trash',
  SPAM: 'spam',
} as const;

export type ConversationStatusType =
  (typeof ConversationStatus)[keyof typeof ConversationStatus];

export type ChatStateType = (typeof ChatState)[keyof typeof ChatState];

export type CountsKeys = 'inbox' | 'all' | 'unassigned' | 'spam' | 'trash';

export type Counts = {
  [key in CountsKeys]: number;
};

type AgentCount = {
  agent_id: string;
  open: number;
};

type BrainCount = {
  open: number;
};

export type AgentCounts = {
  agents: AgentCount[];
  brain: BrainCount;
};

type DepartmentCount = {
  department_id: string;
  open: number;
};

export type DepartmentCounts = {
  departments: DepartmentCount[];
};

export type Sort =
  | 'created'
  | 'updated'
  | 'last_message_at'
  | 'status'
  | '-last_message_at';

export const ConversationEvent = {
  CONTEXT_TAG: 'context_tag',
  CONTEXT_USER: 'context_user',
  CONTEXT_UPDATE: 'context_update',
  ASSIGN_AGENT: 'assign_agent',
  UNASSIGN_AGENT: 'unassign_agent',
  ASSIGN_DEPARTMENT: 'assign_department',
  UNASSIGN_DEPARTMENT: 'unassign_department',
  UNASSIGN_BRAIN: 'unassign_brain',
  REOPEN: 'reopen',
  RESOLVE: 'resolve',
  TRASH: 'trash',
  SPAM: 'spam',
} as const;

export type ConversationEventType =
  (typeof ConversationEvent)[keyof typeof ConversationEvent];

export type ConversationEventTag = {
  added_tags: string[];
  removed_tags: string[];
};

export type ConversationEventAgent = {
  agent_id: string;
  prev_agent_id: string | null;
};

export type ConversationEventChanges = { changes: string[] };

export type ConversationEventBrain = { prev_brain_id?: string | null };

export type ConversationEventDepartment = {
  department_id: string;
  prev_department_id: string | null;
};

export type ConversationEventStatus = {
  status: ConversationStatusType;
  prev_status: ConversationStatusType;
};

export type ConversationEventTarget =
  | ConversationEventTag
  | ConversationEventAgent
  | ConversationEventChanges
  | ConversationEventBrain
  | ConversationEventDepartment
  | ConversationEventStatus;

export type ConversationEvent = {
  session_id: string;
  event_id: string;
  type: ConversationEventType;
  created: string;
  source_id: string;
  source_type: 'user' | 'system' | 'brain';
  target: ConversationEventTarget;
};

export type ConversationEventResponse = {
  events: ConversationEvent[];
  pagination: {
    has_more: boolean;
    limit: number;
    next_cursor: string;
    prev_cursor: string;
  };
};
