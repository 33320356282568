import { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { TypographyVariant } from '@mui/material/styles';
import { TabProps } from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import ButtonTest from '@/components/atoms/Button/ButtonTest/ButtonTest';
import { EditableText } from '@/components/atoms/EditableText/EditableText';
import ChatLogs from '@/components/atoms/Icons/ChatLogs';
import Collections from '@/components/atoms/Icons/Collections';
import Dialogs from '@/components/atoms/Icons/Dialogs';
import Entities from '@/components/atoms/Icons/Entities';
import Intents from '@/components/atoms/Icons/Intents';
import CursorPointer2 from '@/components/atoms/Icons/Other/CursorPointer2';
import SettingsIcon from '@/components/atoms/Icons/Settings';
import Version from '@/components/atoms/Icons/Version';
import Webhooks from '@/components/atoms/Icons/Webhooks';
import InAppHelp from '@/components/atoms/InAppHelp/InAppHelp';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import StatusDot from '@/components/atoms/StatusDot/StatusDot';
import Tab from '@/components/atoms/Tab/Tab';
import Tabs from '@/components/atoms/Tabs/Tabs';
import Header from '@/components/organisms/Header/Header';
import {
  MODAL_TRYIT,
  MODAL_CONFIRM_CHANGES,
  MODAL_WHATSAPP_TEST,
} from '@/components/organisms/Modals/ModalConductor';
import { getRoleName } from '@/components/pages/ManageAccess/helper';
import { useVersionsTable } from '@/components/pages/Versions/useVersionsTable';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useDialogs from '@/hooks/useDialogs';
import useEntities from '@/hooks/useEntities';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { AccountUserPrefsEnum, INAPPHELP_KEYS } from '@/hooks/useHomeCheckList';
import useIntents from '@/hooks/useIntents';
import useMembers from '@/hooks/useMembers';
import { useRoles } from '@/hooks/useRoles';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useBillingState } from '@/modules/billing/hooks/useBillingState';
import { openTryIt, updateIsReplay } from '@/modules/TryIt/redux/actions';
import { selectDialogId } from '@/redux/dialogs/selectors';
import { selectIntentDraft } from '@/redux/expressions/selectors';
import { hideHelper } from '@/redux/helpers/actions';
import { pushModal, popModal } from '@/redux/modals/actions';
import { selectIsDirty } from '@/redux/nodes/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId, selectVersion } from '@/redux/session/selectors';
import { COLLECTION_LANGUAGES } from '@/util/constants';
import { getRestrictedNames } from '@/util/util';
import { getEditableTextValidationSchema, LENGTH_XS } from '@/util/validator';

import useBrainHeaderHelper from './useBrainHeaderHelper';

type HeaderItem = {
  id: string;
  text: string;
  link: string;
  icon: TabProps['icon'];
  disabled?: boolean;
  onClick?: () => void;
};
const BrainHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    setDismissTestHelp,
    setDismissVersionHelp,
    setDismissIntentHelp,
    showTestDialog,
    showIntentHelp,
    showVersionTooltip,
  } = useBrainHeaderHelper();
  const { slug } = useAccount();
  const brainId = useSelector(selectBrainId);
  const version = useSelector(selectVersion);
  const billingState = useBillingState();

  const hasPlanExpired = billingState?.has_expired;

  const { brain, brains, getStatus, updateBrain } = useBrains(brainId);
  const { handleCreateVersion } = useVersionsTable();
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const dialogId = useSelector(selectDialogId);

  const intentName = useSelector(selectIntentDraft, shallowEqual);

  const { entityName } = useParams();

  const { saveDialog } = useDialogs(brainId, dialogId);
  const { saveIntent } = useIntents(brainId, intentName);
  const { saveEntity } = useEntities(brainId, entityName);

  const isDirty = useSelector(selectIsDirty);

  const { pathname } = useLocation();

  const { enableCollectionsForRules } = useFeatureFlag();

  const enableCollectionsTab =
    COLLECTION_LANGUAGES.includes(brain?.language) &&
    !enableCollectionsForRules;

  const header: HeaderItem[] = useMemo(() => {
    return [
      {
        id: 'dialogs',
        text: t('common.dialogs'),
        link: `/${slug}/brains/${brainId}/dialogs`,
        icon: <Dialogs />,
      },
      {
        id: 'intents',
        text: t('common.intents'),
        link: `/${slug}/brains/${brainId}/intents`,
        icon: <Intents />,
        onClick: () => {
          dispatch(
            hideHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_INTENT])
          );
          setDismissIntentHelp(true);
        },
      },
      {
        id: 'entities',
        text: t('common.entities'),
        link: `/${slug}/brains/${brainId}/entities`,
        icon: <Entities />,
      },
      {
        id: 'collections',
        text: t('collections.collection'),
        link: `/${slug}/brains/${brainId}/collections`,
        icon: <Collections />,
        disabled: !enableCollectionsTab,
      },
      {
        id: 'webhooks',
        text: t('common.webhooks'),
        link: `/${slug}/brains/${brainId}/webhooks`,
        icon: <Webhooks />,
      },
      {
        id: 'logs',
        text: t('common.logs'),
        link: `/${slug}/brains/${brainId}/logs`,
        icon: <ChatLogs />,
      },
      {
        id: 'versions',
        text: t('version.versions'),
        link: `/${slug}/brains/${brainId}/versions`,
        icon: <Version />,
      },
      {
        id: 'settings',
        text: t('common.settings'),
        link: `/${slug}/brains/${brainId}/settings`,
        icon: <SettingsIcon />,
      },
    ];
  }, [brainId, dispatch, enableCollectionsTab, setDismissIntentHelp, slug, t]);

  const [tabValue, setTabValue] = useState(header[0].id);

  useEffect(() => {
    const foundItem =
      header.find((item) => pathname.includes(item?.id))?.id ?? header[0].id;
    setTabValue(foundItem);
  }, [header, pathname]);

  const warningModal = useCallback(async () => {
    return new Promise<void>((resolve, reject): void => {
      const onSave = async () => {
        try {
          if (dialogId) {
            await saveDialog();
          } else if (intentName) {
            await saveIntent();
          } else if (entityName) {
            await saveEntity();
          }
          resolve();
        } catch (e) {
          reject();
        }
      };
      dispatch(
        pushModal(MODAL_CONFIRM_CHANGES, {
          onSave,
          onDiscard: resolve,
          secondaryText: t('dialog.warningModal.secondary_try_it'),
          primaryText: t('dialog.warningModal.primary_try_it'),
        })
      );
    });
  }, [
    dialogId,
    dispatch,
    entityName,
    intentName,
    saveDialog,
    saveEntity,
    saveIntent,
    t,
  ]);

  const showTryItModal = useCallback(async () => {
    if (isDirty) {
      try {
        await warningModal();
      } catch (e) {
        return;
      }
    }
    dispatch(updateIsReplay(false));
    dispatch(openTryIt());
    dispatch(pushModal(MODAL_TRYIT, { brainId }));
  }, [brainId, dispatch, isDirty, warningModal]);

  const showWhatsAppTestModal = useCallback(async () => {
    if (isDirty) {
      try {
        await warningModal();
      } catch (e) {
        return;
      }
    }
    dispatch(pushModal(MODAL_WHATSAPP_TEST, { brainId }));
  }, [brainId, dispatch, isDirty, warningModal]);

  const handleSubmit = useCallback(
    (text: string) => {
      updateBrain(
        { name: text, brain_id: brainId },
        {
          onSuccess: () => {
            dispatch(popModal());
          },
        }
      );
    },
    [brainId, dispatch, updateBrain]
  );

  const handleArrowClick = useCallback(() => {
    navigate(`/${slug}/brains`);
  }, [navigate, slug]);

  // Filters out the current brain name from the list of restricted values
  const restrictedValues = getRestrictedNames(brains, brain?.name);

  const validationSchema = getEditableTextValidationSchema(
    LENGTH_XS,
    restrictedValues,
    t('brains.brain_name')
  );

  const editableTextProps = {
    defaultValue: brain?.name,
    onSubmit: handleSubmit,
    variant: 'heading1-medium' as TypographyVariant,
    canEdit: canWrite,
    validationSchema,
  };
  const { member } = useMembers();
  const { roles } = useRoles();
  const role = getRoleName(member, roles);

  return (
    <>
      <Header>
        <Header.Body noGutters>
          <Box display="flex" alignItems="center">
            <Header.Title
              title={
                <EditableText<typeof validationSchema> {...editableTextProps} />
              }
              onBackClick={handleArrowClick}
              isLoading={getStatus === 'pending'}
            />

            <Box mx={1}>
              <StatusBadge
                withIcon={false}
                label={
                  version === 0
                    ? t('common.draft')
                    : t('common.version_with_number', { 0: version })
                }
                variant="neutral"
              />
            </Box>
            <StatusDot status={brain?.status} />
          </Box>
        </Header.Body>
        <Header.Navigation>
          <Tabs value={tabValue} size="medium">
            {header
              .filter((tab) => !tab.disabled)
              .map((item, index) => {
                const helpProps =
                  index === 1
                    ? {
                        step: {
                          icon: <CursorPointer2 />,
                          body: (
                            <Typography variant="body-regular">
                              {t('home.help.visit_intent')}
                            </Typography>
                          ),
                        },
                        onClose: () => {
                          setDismissIntentHelp(true);
                          dispatch(
                            hideHelper(
                              INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_INTENT]
                            )
                          );
                        },
                        show: showIntentHelp,
                      }
                    : { step: { body: '' } };
                return (
                  <Tab
                    key={item.text}
                    label={item.text}
                    value={item?.id}
                    to={item.link}
                    component={Link}
                    icon={item.icon}
                    onClick={item?.onClick}
                    helpProps={helpProps}
                  />
                );
              })}
          </Tabs>
          <Header.Actions>
            <InAppHelp
              placement="bottom-end"
              darkTheme
              defaultOpen={showVersionTooltip}
              onClose={() => {
                setDismissVersionHelp(true);
                dispatch(
                  hideHelper(
                    INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_VERSION]
                  )
                );
              }}
              steps={[
                {
                  icon: <CursorPointer2 />,
                  body: (
                    <Typography variant="body-regular">
                      {t('home.help.create_version')}
                    </Typography>
                  ),
                },
              ]}
            >
              <span>
                <Button
                  variant="tertiary"
                  disabled={brain?.status !== 'available' || !canWrite}
                  onClick={handleCreateVersion}
                >
                  {role === 'Owner' || role === 'Admin'
                    ? t('version.publish')
                    : t('version.save_version')}
                </Button>
              </span>
            </InAppHelp>
            <InAppHelp
              placement="bottom-end"
              darkTheme
              defaultOpen={showTestDialog}
              onClose={() => {
                setDismissTestHelp(true);

                dispatch(
                  hideHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.TEST_DIALOG])
                );
              }}
              steps={[
                {
                  icon: <CursorPointer2 />,
                  body: (
                    <Typography variant="body-regular">
                      {t('auto_brain.test')}
                    </Typography>
                  ),
                },
              ]}
            >
              <Tooltip
                title={t('brain.expired_plan')}
                disableHoverListener={!hasPlanExpired}
              >
                <span>
                  <ButtonTest
                    disabled={!canWrite || hasPlanExpired}
                    onClickMoveo={showTryItModal}
                    onClickWhatsApp={showWhatsAppTestModal}
                  />
                </span>
              </Tooltip>
            </InAppHelp>
          </Header.Actions>
        </Header.Navigation>
      </Header>
    </>
  );
};

export default BrainHeader;
