import Containment from '../components/fieldsets/ContainmentFieldset/ContainmentFieldset';
import Coverage from '../components/fieldsets/CoverageFieldset/CoverageFieldset';
import Filters from '../components/filters/AccountFilters/AccountFilters';

export const BrainEffectivenessReport = () => {
  return (
    <>
      <Filters />
      <Coverage />
      <Containment />
    </>
  );
};
