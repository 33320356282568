import { useCallback, useEffect, useState } from 'react';

import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { useAccount } from '@/hooks/useAccount';
import useCollections from '@/hooks/useCollections';
import useDatasources from '@/hooks/useDatasources';
import useDialogs from '@/hooks/useDialogs';
import { Dialog } from '@/models/dialog';
import { NodeType, TryItCollection } from '@/models/tryIt';
import { useBroadcasts } from '@/modules/broadcast/hooks/useBroadcasts';
import { popModal } from '@/redux/modals/actions';

import { ResponseTypeFlags } from './useResponseTypeFlags';
import { selectTryItNode } from '../redux/actions';
import { selectNodesStackParentNodeNameByIndex } from '../redux/selectors';

const ResponseTypes = {
  BRAIN: 'brain',
  COLLECTION: 'collection',
  DISAMBIGUATION: 'disambiguation',
  UNKNOWN: 'unknown',
  BROADCAST: 'broadcast',
} as const;

const INITIAL_TITLE = {
  name: '',
  type: null,
};

// Response types
const brainResponse = (name: string) => ({
  name,
  type: ResponseTypes.BRAIN,
});

const unknownResponse = (name: string = 'Unknown') => ({
  name,
  type: ResponseTypes.UNKNOWN,
});

const collectionResponse = (collectionName: string) => ({
  name: collectionName,
  type: ResponseTypes.COLLECTION,
});

const broadcastResponse = (name: string) => ({
  name: name,
  type: ResponseTypes.BROADCAST,
});

const disambiguationResponse = {
  name: 'Disambiguation',
  type: ResponseTypes.DISAMBIGUATION,
};

export const useMessageNode = (
  id: string,
  brainId: string,
  tryItCollection: TryItCollection,
  index: number,
  author_type: string,
  steps?: NodeType['steps'],
  entitiesMatched: boolean = false,
  responseTypeFlags?: ResponseTypeFlags,
  broadcastId?: string
) => {
  const parentNodeName = useSelector(
    selectNodesStackParentNodeNameByIndex(index)
  );

  // Custom hooks
  const navigate = useNavigate();
  const { slug } = useAccount();
  const dispatch = useDispatch();
  const { dialogs } = useDialogs(brainId);
  const { collection } = useCollections(tryItCollection?.collection_id);
  const { datasources } = useDatasources(tryItCollection?.collection_id);
  const { broadcasts } = useBroadcasts();
  const broadcast = broadcasts?.find((b) => b.broadcast_id === broadcastId);
  // Local state
  const [title, setTitle] = useState(INITIAL_TITLE);
  const [icons, setIcons] = useState([]);

  // Determine icons (only for collection responses currently)
  useEffect(() => {
    // Set icons for collection responses
    if (datasources) {
      const datasourceIds = tryItCollection?.fragments?.map(
        (entry) => entry.datasource_id
      );
      const uniqueDatasourceIds = [...new Set(datasourceIds)];
      const iconTypes =
        uniqueDatasourceIds?.map((datasourceId) => {
          const foundDatasource = datasources?.find(
            (d) => d.datasource_id === datasourceId
          );

          return foundDatasource?.type;
        }) ?? [];

      const uniqueIconTypes = [...new Set(iconTypes)];

      setIcons(uniqueIconTypes);
    }

    // request_code: 4 means that the collection is empty
    const isDisambiguation = !!steps?.[0]?.disambiguation_node_ids;

    // Set icons for brain responses
    if (steps?.length > 0 && !isDisambiguation) {
      const filteredSteps = steps?.filter(
        (step) => step?.action_type !== 'event'
      );

      const finalIcons = filteredSteps?.map((step) => {
        let id = step?.action_type;

        // Cases where the action type is not used to determine the icon
        if (step?.intent_match_theshold) {
          id = 'intent';
        } else if (step?.requisite_id) {
          id = 'question';
        } else if (step?.condition_id) {
          id = 'condition';
        }

        return id;
      });

      // Add entity match icon at the first position
      if (entitiesMatched) {
        finalIcons?.unshift('entity');
      }

      setIcons(finalIcons);
    }
  }, [
    datasources,
    entitiesMatched,
    steps,
    tryItCollection?.fragments,
    tryItCollection?.response_code,
  ]);

  const getTitle = useCallback(
    (name: string | null) => {
      if (
        !responseTypeFlags?.isResponseCompleted ||
        responseTypeFlags?.isErrorResponse
      ) {
        return INITIAL_TITLE;
      }

      if (responseTypeFlags?.isUnknownResponse) {
        return unknownResponse(name);
      }
      if (responseTypeFlags?.isCollectionResponse) {
        return collectionResponse(collection?.name);
      }
      if (responseTypeFlags?.isBrainResponse) {
        return brainResponse(name);
      }
      if (responseTypeFlags?.isDisambiguationResponse) {
        return disambiguationResponse;
      }
      if (responseTypeFlags?.isBroadcastResponse) {
        return broadcastResponse(
          broadcast?.name ?? i18next.t('broadcasts.deleted_broadcast')
        );
      }

      return INITIAL_TITLE;
    },
    [responseTypeFlags, collection?.name, broadcast?.name]
  );

  useEffect(() => {
    if (author_type !== 'agent') {
      setTitle(getTitle(parentNodeName));
    }
  }, [author_type, getTitle, parentNodeName]);

  const findTriggerDialog = useCallback(
    (dialogs: Dialog[]) => {
      for (const dialog of dialogs) {
        if (dialog?.nodes[0]?.name === parentNodeName) {
          return dialog;
        }
      }

      return null;
    },
    [parentNodeName]
  );

  const handleGoToNode = useCallback(() => {
    if (author_type === 'broadcast' && broadcast) {
      dispatch(popModal());
      navigate(`/${slug}/broadcasts/${broadcastId}`);
      return;
    }

    let dialog = dialogs?.find((d) => d.nodes.some((n) => n.node_id === id));
    let nodeId = id;

    if (!dialogs) return;

    const triggerDialog = findTriggerDialog(dialogs);
    if (triggerDialog) {
      dialog = triggerDialog;
      nodeId = triggerDialog?.nodes?.[0]?.node_id;
    }

    if (
      !brainId ||
      (!dialog && title.type !== ResponseTypes.COLLECTION) ||
      (!id && title.type !== ResponseTypes.COLLECTION) ||
      title.type === ResponseTypes.DISAMBIGUATION
    ) {
      return;
    }

    dispatch(popModal());
    dispatch(selectTryItNode(nodeId));

    if (title.type === ResponseTypes.COLLECTION) {
      navigate(`/${slug}/collections/${collection.collection_id}`);
    } else {
      navigate(`/${slug}/brains/${brainId}/dialogs/${dialog.dialog_id}`);
    }
  }, [
    author_type,
    broadcastId,
    broadcast,
    title,
    dialogs,
    brainId,
    id,
    dispatch,
    slug,
    collection?.collection_id,
    findTriggerDialog,
    navigate,
  ]);

  return {
    title,
    icons,
    handleGoToNode,
  };
};
