import Skeleton from '@mui/material/Skeleton';
import { ResponsiveBar } from '@nivo/bar';
import { useTranslation } from 'react-i18next';

import { durationFormat, numberFormatter } from '@/util/util';
import './Bar.scss';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[];
  keys?: string[];
  indexBy?: string;
  axisY?: string;
  axisX?: string;
  errorColor?:
    | 'var(--icon-default-success)'
    | 'var(--icon-default-error)'
    | 'var(--analytics-07)';
  groupMode?: 'grouped' | 'stacked';
  BarTooltips?: React.ReactElement[];
  isTime?: boolean;
};
const Bar = ({
  data,
  axisY,
  axisX,
  keys,
  indexBy,
  errorColor = 'var(--analytics-07)',
  groupMode,
  BarTooltips = [],
  isTime = false,
}: Props) => {
  const { t } = useTranslation();

  if (!data) {
    return (
      <div className="bar-chart">
        <Skeleton variant="rectangular" width={250} height={150} />
      </div>
    );
  }

  return (
    <div className="bar-chart">
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={{ top: 20, right: 30, bottom: 60, left: 50 }}
        padding={0.8}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: false }}
        colors={
          groupMode
            ? ['var(--icon-default-blue)', errorColor]
            : ['var(--icon-default-blue)']
        }
        axisTop={null}
        axisRight={null}
        enableLabel={false}
        maxValue="auto"
        groupMode={groupMode}
        tooltip={({ index }) => BarTooltips[index] ?? null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: data?.length >= 10 ? 30 : 0,
          legend: axisX,
          legendPosition: 'start',
          legendOffset: 30,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 0,
          tickRotation: 0,
          tickValues: 5,
          legend: axisY,
          legendPosition: 'middle',
          legendOffset: -40,
          format: (e) => {
            if (isTime) {
              const time = durationFormat(t, e);
              if (e === 0) {
                return 0;
              }
              return time;
            }
            return numberFormatter(e);
          },
        }}
        theme={{
          axis: {
            legend: {
              text: {
                fill: 'var(--icon-default-gray)',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default Bar;
