import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useWindowSize } from 'react-use';

import { MODAL_SMALL_SCREEN } from '@/components/organisms/Modals/ModalConductor';
import { pushModal } from '@/redux/modals/actions';

export const useMobileWarning = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    if (width < 1023) {
      dispatch(pushModal(MODAL_SMALL_SCREEN));
    }
    // we want to show this modal only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
};
