import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Robot = forwardRef<SVGSVGElement, Icon>(
  ({ size = 16, color = 'var(--icon-default-gray)', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
      >
        <path d="M14.619 7.738c-.135-.325-.424-.403-.619-.422V5.333C14 4.598 13.402 4 12.667 4h-4v-.927A.992.992 0 0 0 9 2.333a1 1 0 0 0-2 0c0 .295.13.558.333.74V4h-4C2.598 4 2 4.598 2 5.333v1.998l-.055.004A.666.666 0 0 0 1.327 8v1.333c0 .369.298.667.666.667H2v3.333c0 .736.598 1.334 1.333 1.334h9.334c.735 0 1.333-.598 1.333-1.334V10a.666.666 0 0 0 .667-.667V8.041a.671.671 0 0 0-.048-.303ZM3.333 13.333v-8h9.334v4.004l.001 3.996H3.333Z" />
        <path d="M5.667 9.333c.552 0 1-.597 1-1.333s-.448-1.333-1-1.333c-.553 0-1 .597-1 1.333s.447 1.333 1 1.333ZM10.333 9.333c.553 0 1-.597 1-1.333s-.447-1.333-1-1.333c-.552 0-1 .597-1 1.333s.448 1.333 1 1.333ZM5.333 10.667h5.334V12H5.333v-1.333Z" />
      </svg>
    );
  }
);

Robot.displayName = 'Robot';

export default Robot;
