/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from 'react';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import At from '@/components/atoms/Icons/At';
import Dollar from '@/components/atoms/Icons/Dollar';
import Lock2 from '@/components/atoms/Icons/Lock2';
import NumberIcon from '@/components/atoms/Icons/NumberIcon';
import StringIcon from '@/components/atoms/Icons/StringIcon';
import { OptionBase } from '@/models/common';
import { removeAtSign, removeDollarSign } from '@/util/util';

import {
  listItemButtonStyles,
  listStyles,
  listSubheaderStyles,
  typographyStyles,
} from './styles';
import { useOptionsPopper } from './useOptionsPopper';
import { RenderPaletteProps } from '../../CommandPalette/CommandPalette';

export interface OptionsPopperProps extends RenderPaletteProps {
  options: any[];
  handleOnSelectPopper: (event, index?: number) => void;
  selectedIndex: number;
  searchResults: any[];
  setSearchResults: React.Dispatch<React.SetStateAction<any[]>>;
  noResultSearchLength: number;
  setNoResultSearchLength: React.Dispatch<React.SetStateAction<number>>;
  handleClosePopper: () => void;
  lastSearchStringLength: number;
  setLastSearchStringLength: React.Dispatch<React.SetStateAction<number>>;
}

const variablesDataMap = {
  lead_data_variable: {
    Name: 'Jane Doe',
    Email: 'email@moveo.ai',
    Phone: '+1 831-646-400',
    Language: 'English',
    Address: '1021 Second str.',
  },
  system_variable: {
    Channel: 'Web',
    Environment: 'Support',
    'Business hours': 'M-F, 8am-5pm',
    'Unknown counter': '2',
  },
  user_info_variable: {
    Verified: 'True',
    'External ID': '123456',
  },
};

const OptionsPopper = forwardRef<HTMLUListElement, OptionsPopperProps>(
  (props, ref) => {
    const {
      searchString,
      options,
      handleOnSelectPopper,
      selectedIndex,
      searchResults,
      setSearchResults,
      noResultSearchLength,
      setNoResultSearchLength,
      handleClosePopper,
      lastSearchStringLength,
      setLastSearchStringLength,
    } = props;

    const { t } = useTranslation();

    const { groupedOptions, index, getInfo, getFlatIndex } = useOptionsPopper({
      options,
      searchString,
      setSearchResults,
      lastSearchStringLength,
      setLastSearchStringLength,
      searchResults,
      setNoResultSearchLength,
      noResultSearchLength,
      handleClosePopper,
      selectedIndex,
    });
    const needsLocker = ['system_variable', 'user_info_variable'];
    const isContextVar = (type: string) => type === 'context_variable';
    const isEntity = (type: string) => type === 'entity';
    const handleScrollIntoView = (
      node: HTMLDivElement,
      shouldScroll: boolean
    ) => {
      if (shouldScroll) {
        node?.scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
          behavior: 'instant',
        });
      }
    };
    const renderLabel = (
      item: OptionBase<{ type: string; typeofVar?: 'number' | 'string' }>
    ) => {
      return isContextVar(item.type)
        ? removeDollarSign(item.label)
        : isEntity(item.type)
          ? removeAtSign(item.label)
          : item.label;
    };

    const renderVarTypeIcon = (
      item: OptionBase<{ type: string; typeofVar?: 'number' | 'string' }>
    ) => {
      if (isContextVar(item.type) || isEntity(item.type)) {
        return (
          <span>
            {item.typeofVar === 'string' ? (
              <Tooltip arrow title="String">
                <span>
                  <StringIcon />
                </span>
              </Tooltip>
            ) : item.typeofVar === 'number' ? (
              <Tooltip arrow title="Number">
                <span>
                  <NumberIcon />
                </span>
              </Tooltip>
            ) : null}
          </span>
        );
      }
      return null;
    };

    return (
      <List ref={ref} sx={listStyles} subheader={<li />}>
        {groupedOptions.length === 0 && (
          <li>
            <ul>
              <ListItemButton sx={listItemButtonStyles} disabled>
                <Typography
                  sx={typographyStyles}
                  color="var(--text-default-gray)"
                >
                  {t('analytics.no_results_found')}
                </Typography>
              </ListItemButton>
            </ul>
          </li>
        )}
        {groupedOptions.map((group, groupIndex) => {
          return (
            <li key={`section-${group.label}`}>
              <ul>
                <ListSubheader disableSticky sx={listSubheaderStyles}>
                  <Typography variant="label-caps-big">
                    {t(`auto_complete.group_labels.${group.label}`)}
                  </Typography>
                </ListSubheader>
                {group.options.map((item, itemIindex) => {
                  const selected =
                    index?.outerIndex === groupIndex &&
                    index?.innerIndex === itemIindex;
                  const varTypeIcon = renderVarTypeIcon(item);
                  return (
                    <ListItemButton
                      key={item.value}
                      sx={listItemButtonStyles}
                      selected={selected}
                      ref={(node) => {
                        handleScrollIntoView(node, selected);
                      }}
                      onMouseDown={(e) => {
                        handleOnSelectPopper(
                          e,
                          getFlatIndex(groupIndex, itemIindex)
                        );
                      }}
                    >
                      <span
                        style={{
                          marginRight:
                            isContextVar(item.type) || isEntity(item.type)
                              ? '6px'
                              : 0,
                          transform: 'translateY(1px)',
                        }}
                      >
                        {isContextVar(item.type) && (
                          <Dollar size={12} color="var(--icon-default-gray)" />
                        )}
                        {isEntity(item.type) && (
                          <At size={12} color="var(--icon-default-gray)" />
                        )}
                      </span>{' '}
                      <Typography
                        sx={typographyStyles}
                        color="var(--text-default-gray)"
                      >
                        {renderLabel(item)}{' '}
                        <span className="locker">
                          {needsLocker.includes(item.type) && <Lock2 />}
                        </span>
                      </Typography>
                      <Typography
                        color="var(--text-default-gray-light)"
                        sx={{
                          ...typographyStyles,
                          textAlign: 'right',
                          flex: varTypeIcon === null ? 0 : 1,
                        }}
                      >
                        {getInfo(item, variablesDataMap)}
                        {varTypeIcon}
                      </Typography>
                    </ListItemButton>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </List>
    );
  }
);

OptionsPopper.displayName = 'OptionsPopper';

export default OptionsPopper;
