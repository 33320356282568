import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import Assist from '@/components/atoms/Icons/Navigation/Assist';
import Input from '@/components/atoms/Input/Input';
import useDesks from '@/hooks/useDesks';
import { Channel, Log } from '@/modules/analytics/models';
import { popModal } from '@/redux/modals/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import { formatLogs } from '@/util/analytics';

import { ContainedCovered } from './ContainedCovered';
import { ContainedCoveredSection } from './ContainedCoveredSection';
import { ConversationIdSection } from './ConversationIdSection';
import { InfoFieldSection } from './InfoFieldsSection';
import { InformationFieldNew } from '../InformationField/InformationFieldNew';

import styles from './SessionSection.module.scss';

type Props = {
  isContained: boolean;
  isCovered: boolean;
  isTest: boolean;
  sessionId: string;
  deskId: string;
  participatedBrains: Log['participated_brains'];
  participatedAgents: Log['participated_agents'];
  participatedRules: Log['rule_ids'];
  tags: Log['tags'];
  channel: Channel;
  startTime: string;
  endTime: string;
  expiredTime: string;
  avgConfidence: number;
  isLoading?: boolean;
  messages: number;
};

export const SessionSection = ({
  isContained,
  isCovered,
  isTest,
  sessionId,
  deskId,
  channel,
  messages,
  startTime,
  endTime,
  expiredTime,
  participatedAgents,
  participatedBrains,
  participatedRules,
  isLoading,
  tags,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { desk } = useDesks(deskId);
  const slug = useSelector(selectAccountSlug);
  const { duration, sessionDuration: sessionDuration } = formatLogs({
    start_time: startTime,
    end_time: endTime,
    expired_time: expiredTime,
    channel,
  });

  const handleClick = () => {
    dispatch(popModal());
    navigate(`/${slug}/chats/${deskId}/conversations/${sessionId}`);
  };
  const isServiceDesk = !!desk?.is_service_desk;

  return (
    <div className={styles.container}>
      <ContainedCoveredSection>
        <span>{t('try_it.modal.status')}:</span>
        <ContainedCovered
          truthness={isContained}
          text={
            isContained
              ? t('try_it.modal.contained')
              : t('try_it.modal.not_contained')
          }
          isLoading={isLoading}
          tooltipText={t('try_it.modal.contained_tooltip')}
        />
        <ContainedCovered
          truthness={isCovered}
          text={
            isCovered
              ? t('try_it.modal.covered')
              : t('try_it.modal.not_covered')
          }
          isLoading={isLoading}
          tooltipText={t('try_it.modal.covered_tooltip')}
        />
      </ContainedCoveredSection>

      <ConversationIdSection>
        <Input
          readOnly
          value={sessionId}
          label={t('analytics.fields.session_id')}
          size="small"
        />

        <CopyButton
          className={cn(styles.copyButton, {
            [styles.isServiceDesk]: isServiceDesk,
          })}
          data={sessionId}
        />

        {isServiceDesk && (
          <Tooltip placement="top" title={t('analytics.navigate_to')} arrow>
            <Button onClick={handleClick}>
              <Assist color="var(--icon-default-white)" />
            </Button>
          </Tooltip>
        )}
      </ConversationIdSection>

      <InfoFieldSection>
        <InformationFieldNew
          label={t('common.environment')}
          type="desk"
          value={desk}
          isLoading={isLoading}
        />
        <InformationFieldNew
          label={t('common.channel')}
          type="channel"
          value={channel}
          isLoading={isLoading}
          isTest={isTest}
        />
        <InformationFieldNew
          type="duration"
          label={t('common.duration')}
          value={duration == 0 ? null : duration}
          isLoading={isLoading}
        />
        <InformationFieldNew
          type="duration"
          label={t('try_it.modal.expired_after')}
          value={sessionDuration}
          isLoading={isLoading}
        />
        <InformationFieldNew
          type="messages"
          label={t('try_it.modal.messages')}
          value={messages > 0 ? String(messages) : null}
          isLoading={isLoading}
        />
        <InformationFieldNew
          type="agents"
          label={t('try_it.modal.agents')}
          value={participatedAgents}
          isLoading={isLoading}
        />
        <InformationFieldNew
          type="brains"
          label={t('try_it.modal.brains')}
          value={participatedBrains}
          isLoading={isLoading}
          sessionId={sessionId}
        />
        <InformationFieldNew
          type="rules"
          label={t('try_it.modal.rules')}
          value={participatedRules}
          deskId={deskId}
          isLoading={isLoading}
        />
        <InformationFieldNew
          type="tags"
          label={t('common.tags')}
          value={tags}
          isLoading={isLoading}
        />
      </InfoFieldSection>
    </div>
  );
};
