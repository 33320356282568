import { useCallback, useMemo } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { desksEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { DESK_CREATE } from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import { useIntegrations } from '@/hooks/useIntegrations';
import { Desk, PartialDesk } from '@/models/desk';
import { Integration, IntegrationType } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { setDeskId } from '@/redux/session/actions';
import { selectAccountId } from '@/redux/session/selectors';
import { preventClickThrough } from '@/util/util';

import useFeatureFlag from './useFeatureFlag';

export const API = Object.freeze({
  listDesks: async (): Promise<Desks> => callGet(endpoints.desks),

  getDesk: async (deskId: string): Promise<Desk> =>
    callGet(endpoints.desk(deskId)),

  createDesk: async (newDesk: Partial<Desk>): Promise<Desk> =>
    callPost(endpoints.desks, newDesk),

  updateDesk: async ({ desk_id, ...desk }: PartialDesk): Promise<Desk> =>
    callPut(endpoints.desk(desk_id), desk),

  deleteDesk: async (deskId: string): Promise<Desk> =>
    callDelete(endpoints.desk(deskId)),
});

export type Desks = {
  desks: Desk[];
};

export const onDeskUpdated = (queryClient: QueryClient, desk: Desk) => {
  queryClient.setQueryData<Desk>(
    [endpoints.desk(desk.desk_id)],
    (prev: Desk) => (prev ? { ...prev, ...desk } : desk)
  );

  const queryKey = [endpoints.desks, desk.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Desks>(queryKey, (prev: Desks) => ({
      desks: (prev?.desks || []).map((item) =>
        item.desk_id === desk.desk_id ? { ...item, ...desk } : item
      ),
    }));
  }
};

export const onDeskCreated = (queryClient: QueryClient, desk: Desk) => {
  const queryKey = [endpoints.desks, desk.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Desks>(queryKey, (prev) => ({
      desks: [
        ...(prev?.desks || []).filter((acc) => acc.desk_id !== desk.desk_id),
        desk,
      ],
    }));
  } else {
    queryClient.setQueryData<Desks>(queryKey, { desks: [desk] });
  }
};

export const onDeskRemoved = (
  queryClient: QueryClient,
  account_id: string,
  desk_id: string
) => {
  const queryKey = [endpoints.desks, account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Desks>(
      [endpoints.desks, account_id],
      (prev: Desks) => ({
        desks: (prev?.desks || []).filter((acc) => acc.desk_id !== desk_id),
      })
    );
  }
  queryClient.invalidateQueries({ queryKey: [endpoints.desk(desk_id)] });
};

const useDesks = (deskId?: string) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const accountId = useSelector(selectAccountId);
  const { slug } = useAccount();
  const { getIntegration } = useIntegrations(undefined);
  const { enableLimits } = useFeatureFlag();

  const { account } = useAccount();

  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'desks', actions.READ)
  );

  const { data: desks, status: listStatus } = useQuery<Desks, Error>({
    queryKey: [endpoints.desks, accountId],
    queryFn: () => API.listDesks(),
    enabled: !!accountId && canRead,
  });

  const { data: desk, status: getStatus } = useQuery<Desk, Error>({
    queryKey: [endpoints.desk(deskId)],
    queryFn: () => API.getDesk(deskId),
    enabled: !!deskId && canRead,
  });

  const { mutate: updateDesk, status: updateStatus } = useMutation<
    Desk,
    Error,
    PartialDesk
  >({
    mutationFn: API.updateDesk,
    onSuccess: (resp) => {
      onDeskUpdated(queryClient, resp);
      dispatch(
        addTemporalToast(
          'success',
          t('environments.environment_updated', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const {
    mutate: createDesk,
    mutateAsync: createDeskAsync,
    status: createStatus,
  } = useMutation<Desk, Error, Partial<Desk>>({
    mutationFn: API.createDesk,
    onSuccess: (resp) => {
      onDeskCreated(queryClient, {
        ...resp,
        account_id: accountId,
      });
      dispatch(
        addTemporalToast(
          'success',
          t('environments.environment_created', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteDesk, status: deleteStatus } = useMutation<
    Desk,
    Error,
    string
  >({
    mutationFn: (id) => API.deleteDesk(id),
    onSuccess: (resp) => {
      dispatch(popModal());

      onDeskRemoved(queryClient, accountId, resp.desk_id);
      dispatch(setDeskId(null));
      const firstDeskIdId = desks?.desks?.filter(
        (desk) => desk.desk_id !== resp.desk_id
      )?.[0]?.desk_id;
      if (firstDeskIdId) {
        navigate(`/${slug}/environments/${firstDeskIdId}`);
      } else {
        navigate(`/${slug}/environments`);
      }
      dispatch(
        addTemporalToast('success', t('environments.environment_deleted'))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const handleCreateDesk = useCallback(
    (e) => {
      preventClickThrough(e);

      dispatch(pushModal(DESK_CREATE));
    },
    [dispatch]
  );

  const serviceDesks = useMemo(
    () => desks?.desks.filter((desk) => desk.is_service_desk),
    [desks?.desks]
  );

  const getAllIntegrations = useCallback(
    async (type: IntegrationType) => {
      if (desks?.desks) {
        const integrationsAllDesks = (await Promise.all(
          desks?.desks?.reduce((acc, desk) => {
            const integrationsPerDesk = desk.integrations
              .filter((i) => i.type === type)
              .map((int) => getIntegration(desk.desk_id, int.integration_id));

            return [...acc, ...integrationsPerDesk];
          }, [] as Integration[])
        )) as Integration[];

        return integrationsAllDesks;
      }
      return [];
    },
    [desks, getIntegration]
  );

  return {
    desks: desks?.desks,
    maxDesks: account?.max_desks,
    maxDesksReached: enableLimits
      ? desks?.desks?.length >= account?.max_desks
      : false,
    listStatus,
    getStatus,
    serviceDesks,
    handleCreateDesk,
    desk,
    createStatus,
    deleteStatus,
    updateStatus,
    createDesk,
    createDeskAsync,
    deleteDesk,
    updateDesk,
    getAllIntegrations,
  };
};

export default useDesks;
