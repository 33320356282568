import { memo, useCallback, useEffect, useState } from 'react';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconButton from '@/components/atoms/IconButton/IconButton';
import Edit from '@/components/atoms/Icons/Edit';
import CursorPointer2 from '@/components/atoms/Icons/Other/CursorPointer2';
import InAppHelp from '@/components/atoms/InAppHelp/InAppHelp';
import useLocalStorage from '@/hooks/useLocalStorage';

import { NavItem } from './NavItem/NavItem';
import { MenuType } from '../../hooks/useNavMenu';
import { selectView } from '../../redux/selectors';

import styles from './Nav.module.scss';

type CommonProps = {
  headerTitle: string;
  menu: MenuType;
  marginTop?: boolean;
  onEditClick?: () => void;
  noDesk?: boolean;
};

const arrowStyles = {
  transform: 'translateY(10px) !important',
};

const cardStyles = {
  top: '-30px',
};
export const NavList = memo((props: CommonProps) => {
  const { headerTitle, menu, onEditClick, marginTop } = props;
  const { t } = useTranslation();
  const [dismissTestHelp, setDismissTestHelp] = useLocalStorage(
    'chat-pin',
    false
  );

  const [showTooltip, setShowTooltip] = useState(false);

  const { selectedAgent, selectedDepartment, title, selectedBrain } =
    useSelector(selectView);

  const selected = useCallback(
    (id: string) => {
      if (title) {
        const converted = title === 'me' ? 'inbox' : title;
        return converted === id;
      }

      if (selectedAgent) {
        return selectedAgent === id;
      }

      if (selectedBrain) {
        return selectedBrain === id;
      }

      if (selectedDepartment) {
        return selectedDepartment === id;
      }
    },
    [selectedAgent, selectedBrain, selectedDepartment, title]
  );

  useEffect(() => {
    if (headerTitle === t('conversation.teammates') && !dismissTestHelp) {
      setShowTooltip(true);
    }
  }, [dismissTestHelp, headerTitle, t]);

  return (
    <>
      <div
        className={cn(styles.navListHeader, {
          [styles.marginTop]: marginTop,
          [styles.noDesk]: props.noDesk,
        })}
      >
        <Typography color="var(--text-default-gray)" variant="label-caps-big">
          {headerTitle}
        </Typography>
        {onEditClick && (
          <InAppHelp
            placement="left-start"
            darkTheme
            defaultOpen={showTooltip}
            onClose={() => {
              setDismissTestHelp(true);
            }}
            arrowStyles={arrowStyles}
            cardStyles={cardStyles}
            steps={[
              {
                icon: <CursorPointer2 />,
                body: (
                  <Typography variant="body-regular">
                    {t('conversation.pin_tooltip')}
                  </Typography>
                ),
              },
            ]}
          >
            <IconButton onClick={onEditClick} ariaLabel={t('common.edit')}>
              <Edit />
            </IconButton>
          </InAppHelp>
        )}
      </div>
      <List className={styles.navList}>
        <Scrollbars>
          {menu?.reduce((acc, menuItem) => {
            if (!menuItem) return acc;

            const { text, icon, onClick, id, sum, hasNotification, disabled } =
              menuItem;

            const navItem = (
              <NavItem
                hasNotification={hasNotification}
                text={text}
                icon={icon}
                onClick={onClick}
                sum={sum}
                key={id}
                selected={selected(id)}
                disabled={disabled}
              />
            );

            acc.push(navItem);

            return acc;
          }, [])}
        </Scrollbars>
      </List>
    </>
  );
});

NavList.displayName = 'NavList';
