import { useCallback, useEffect, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_VIDEO } from '@/components/organisms/Modals/ModalConductor';
import useHomeCheckList, {
  AccountUserPrefsEnum,
  INAPPHELP_KEYS,
  Step,
} from '@/hooks/useHomeCheckList';
import useLocalStorage from '@/hooks/useLocalStorage';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { hideHelper, showHelper } from '@/redux/helpers/actions';
import { selectHelper } from '@/redux/helpers/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug, selectBrainId } from '@/redux/session/selectors';

const useAnalyticsHelper = () => {
  const { accountUserChecklist, markAsComplete, getSteps } = useHomeCheckList();
  const slug = useSelector(selectAccountSlug);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [dismissVideo, setDismissVideo] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.ANALYTICS_OVERVIEW],
    false
  );
  const brainId = useSelector(selectBrainId);

  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'analytics', actions.READ)
  );

  const showAnalyticsVideo = useSelector((state: RootState) =>
    selectHelper(state, INAPPHELP_KEYS[AccountUserPrefsEnum.ANALYTICS_OVERVIEW])
  );
  const steps = useMemo(
    () => getSteps(accountUserChecklist, slug, brainId, undefined),
    [accountUserChecklist, brainId, getSteps, slug]
  );

  useEffect(() => {
    if (
      !dismissVideo &&
      accountUserChecklist &&
      !accountUserChecklist[AccountUserPrefsEnum.ANALYTICS_OVERVIEW] &&
      canRead
    ) {
      dispatch(
        showHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.ANALYTICS_OVERVIEW])
      );
      return () => {
        dispatch(popModal());
      };
    }
  }, [
    accountUserChecklist,
    canRead,
    dismissVideo,
    dispatch,
    showAnalyticsVideo,
  ]);

  const handleCloseVideo = useCallback(() => {
    setDismissVideo(true);
    dispatch(
      hideHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.ANALYTICS_OVERVIEW])
    );
  }, [dispatch, setDismissVideo]);

  useEffect(() => {
    if (showAnalyticsVideo) {
      const step = steps.reduce((_, tile) => {
        const analyticsStep = tile.steps.find(
          (s) => s.checklistStep === AccountUserPrefsEnum.ANALYTICS_OVERVIEW
        );
        if (analyticsStep) {
          return analyticsStep;
        }
      }, {} as Step);

      if (isEmpty(step)) {
        return;
      }
      dispatch(
        pushModal(MODAL_VIDEO, {
          title: t(step?.label),
          url: step.video,
          onClick: () => {
            handleCloseVideo();
            dispatch(popModal());
          },
          onPlayClick: () => {
            markAsComplete(step?.checklistStep);
          },
          onClose: handleCloseVideo,
          primaryButtonText: t('common.continue'),
        })
      );
      return () => {
        dispatch(popModal());
      };
    }
  }, [
    dispatch,
    handleCloseVideo,
    markAsComplete,
    showAnalyticsVideo,
    steps,
    t,
  ]);
};

export default useAnalyticsHelper;
