import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { EmailAction } from '@/models/action';

const Email = ({ action }: { action: EmailAction }) => {
  const { t } = useTranslation();
  const subject = action.subject ?? '';

  return (
    <>
      <Typography variant="label2-medium" color="var(--text-default-gray)">
        {t('actions.email.subject')}
      </Typography>
      <Typography variant="label2-regular" color="var(--text-default-gray)">
        {subject}
      </Typography>
    </>
  );
};

export default Email;
