import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTitle } from 'react-use';

import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import { AiProfileFieldset } from './fieldsets/AiProfileFieldset';
import ConfidenceThresholdFieldset from './fieldsets/ConfidenceThresholdFieldset';
import DeleteBrainFieldset from './fieldsets/DeleteBrainFieldset';
import DisambiguationFieldset from './fieldsets/DisambiguationFieldset';
import InactivityTimeoutFieldset from './fieldsets/InactivityTimeoutFieldset';
import InformationFieldset from './fieldsets/InformationFieldset';
import LanguageFieldset from './fieldsets/LanguageFieldset';
import RecommendationFieldset from './fieldsets/RecommendationsFieldset';

const inactivityTimeoutPerPlan = {
  trial: 300,
  pro: 600,
  enterprise: 3600,
};

const BrainSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const brainId = useSelector(selectBrainId);
  const { brain, deleteStatus } = useBrains(brainId);
  const { account, slug } = useAccount();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  useTitle(t('pages.brain_settings', { 0: brain?.name }));

  useEffect(() => {
    pageView(PageName.BRAIN_SETTINGS);
  }, []);

  useEffect(() => {
    if (deleteStatus === 'success') {
      navigate(`/${slug}/brains`);
    }
  }, [slug, deleteStatus, navigate]);

  const maxInactivityTimeout =
    account?.max_inactivity_timeout ||
    inactivityTimeoutPerPlan[account?.plan_id] ||
    inactivityTimeoutPerPlan.trial;

  return (
    <PageContentWrapper newPlain2 readOnly={!canWrite}>
      <InformationFieldset brain={brain} />
      <LanguageFieldset brain={brain} />
      <AiProfileFieldset brain={brain} />
      <RecommendationFieldset brain={brain} />
      <DisambiguationFieldset brain={brain} />
      <ConfidenceThresholdFieldset brain={brain} />
      <InactivityTimeoutFieldset
        brain={brain}
        maxInactivityTimeout={maxInactivityTimeout}
      />
      <DeleteBrainFieldset brain={brain} />
    </PageContentWrapper>
  );
};

export default BrainSettings;
