import { AlertColor } from '@mui/lab';
import {
  createSlice,
  nanoid,
  PayloadAction,
  ThunkAction,
} from '@reduxjs/toolkit';

import { RootState } from '@/models/state';
import { ToastAction } from '@/modules/notifications/models';

import { PROGRESS_BAR_DURATION } from '../utils';

export const defaultState: ToastAction[] = [];

const slice = createSlice({
  name: 'tryIt',
  initialState: defaultState,
  reducers: {
    addToast: (state, action: PayloadAction<ToastAction>) => [
      action.payload,
      ...state,
    ],
    removeToast: (state, action: PayloadAction<string>) =>
      state.filter((t) => t.id !== action.payload),
    resetToast: () => defaultState,
  },
});

export const { addToast, removeToast, resetToast } = slice.actions;

export const addTemporalToast =
  (
    type: AlertColor | 'plain',
    message: string | React.ReactNode,
    props?: {
      action?: {
        label: string;
        url: string;
      };
      avatar?: string;
      duration?: number;
    },
    duration?: number
  ): ThunkAction<void, RootState, unknown, ToastAction> =>
  (
    dispatch: (arg0: { toast?: ToastAction; type: string; id?: string }) => void
  ) => {
    const id = nanoid();
    const toast: ToastAction = {
      id,
      type,
      message,
      autoRemove: true,
      duration,
      ...props,
    };

    dispatch(addToast(toast));
    setTimeout(() => {
      dispatch(removeToast(id));
    }, duration || PROGRESS_BAR_DURATION);
  };

export const safelyGetErrorMessage = (
  error: unknown,
  fallback = 'There has been an error.'
) => {
  try {
    if (typeof error === 'string') {
      return error;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const message = (error as any).message as string | undefined;
    return message ?? fallback;
  } catch (err) {
    return fallback;
  }
};

/**
 * Extracs the error code from an error object or string
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const safelyGetErrorCode = (error: any, fallback = 'unknown') => {
  if (typeof error === 'string') {
    return error;
  }
  return error?.extra?.code || error.code || fallback;
};

export const addErrorTemporalToast = (error: Error | string) => (dispatch) => {
  const id = nanoid();
  dispatch(
    addToast({ id, type: 'error', message: safelyGetErrorMessage(error) })
  );
};

export default slice.reducer;
