import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/system';
import { Accept } from 'react-dropzone';
import {
  FieldErrors,
  UseFormRegisterReturn,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Banner } from '@/components/atoms/Banner/Banner';
import FileUpload from '@/components/atoms/FileUpload/FileUpload';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import Tab from '@/components/atoms/Tab/Tab';
import TabPanel from '@/components/atoms/TabPanel/TabPanel';
import Tabs from '@/components/atoms/Tabs/Tabs';
import { UploadedFile } from '@/models/server';
import { MAX_UPLOAD_SIZE } from '@/util/constants';
import { capitalizeFirstLetter } from '@/util/util';

import styles from './MediaField.module.scss';

const tabsStyle = {
  height: 40,
  boxShadow: 'inset 0px -1px 0px #E1E1E1',
  marginBottom: 'var(--space-16)',

  '.MuiBadge-root': {
    width: '50%',
    display: 'block',
    textAlign: 'center',
  },
  '.MuiTab-root.MuiButtonBase-root': {
    padding: 'var(--space-8)',
    width: '100%',
  },
};

interface BaseProps {
  error: boolean;
  register?: UseFormRegisterReturn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  label: string;
  urlValue: string;
  name: string;
  shouldCrop?: boolean;
  aspectRatio?: 'horizontal' | 'square';
  accept?: Accept | null;
  onFileRemoved?: () => void;
  isFileMode?: boolean;
  fileName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: FieldErrors<any>;
}

interface onChangeProps extends BaseProps {
  onChange: () => void;
}

interface onBlurProps extends BaseProps {
  onBlur: () => void;
}

type Props = onChangeProps | onBlurProps;

const MediaField = ({
  error,
  register,
  setValue,
  urlValue,
  label,
  name: inputName,
  shouldCrop = false,
  aspectRatio,
  accept = {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/gif': ['.gif'],
  },
  onFileRemoved,
  isFileMode = false,
  fileName,
  errors,
  ...rest
}: Props) => {
  const { onChange, onBlur } = rest as onChangeProps & onBlurProps;
  const { t } = useTranslation();
  // Show second tab when urlValue is empty
  const defaultIndex = urlValue ? 0 : 1;
  const [activeIndex, setActiveIndex] = useState(defaultIndex);

  const handleChange = (_: ChangeEvent, tabIndex: number) => {
    setActiveIndex(tabIndex);
  };
  const [uploadedUrl, setUploadedUrl] = useState<string>(null);
  const isUrlUploaded = useRef(false);

  useEffect(() => {
    if (isUrlUploaded.current && uploadedUrl) {
      if (onBlur) {
        onBlur();
      } else {
        onChange();
      }
      isUrlUploaded.current = false;
    }
  }, [onBlur, onChange, uploadedUrl]);

  const handleFileUpload = useCallback(
    async ({ url }: UploadedFile) => {
      setValue(inputName, url, {
        shouldDirty: true,
        shouldValidate: true,
      });
      isUrlUploaded.current = true;
      setUploadedUrl(url);
    },
    [inputName, setValue]
  );

  return (
    <>
      <Tabs value={activeIndex} onChange={handleChange} sx={tabsStyle}>
        <Tab label={t('file_upload.upload')} value={0} />
        <Tab label={label} value={1} />
      </Tabs>

      <TabPanel value={activeIndex} index={0} className={styles.tabPanel}>
        <div className={styles.fileUpload}>
          <FileUpload
            withThumbnail
            isFileMode={isFileMode}
            message={t('dialog.drop_file.image')}
            onFileUploaded={handleFileUpload}
            stream
            accept={accept}
            parseJson={false}
            shouldCrop={shouldCrop}
            aspectRatio={aspectRatio}
            maxSizeInBytes={MAX_UPLOAD_SIZE}
            urlValue={urlValue}
            onFileRemoved={onFileRemoved}
            fileName={fileName}
          />
        </div>

        {error && (
          <Box mt="var(--space-24)">
            <Banner variant="critical" relativePosition>
              {t('validation.is_required', {
                0: 'Url',
              })}
            </Banner>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={activeIndex} index={1} className={styles.tabPanel}>
        <div className={styles.textArea}>
          <TextAreaAsInput
            name={inputName}
            label={label}
            error={error}
            onChange={onChange}
            onBlur={onBlur}
            errorMessage={
              errors
                ? capitalizeFirstLetter(errors[inputName]?.message as string)
                : undefined
            }
            height="medium"
            placeholder={t('dialog.toolkit_url_placeholder')}
            enableScroll
            register={register}
            tooltip={t('dialog.url_tooltip', { 0: label })}
          />
        </div>
        {urlValue && (
          <div className={styles.fileUpload}>
            <FileUpload
              withThumbnail
              isFileMode={isFileMode}
              message={t('dialog.drop_file.image')}
              onFileUploaded={handleFileUpload}
              stream
              accept={accept}
              parseJson={false}
              shouldCrop={shouldCrop}
              aspectRatio={aspectRatio}
              maxSizeInBytes={MAX_UPLOAD_SIZE}
              urlValue={urlValue}
              onFileRemoved={onFileRemoved}
              noGutters
              fileName={fileName}
            />
          </div>
        )}
      </TabPanel>
    </>
  );
};

export default MediaField;
