import MeaningfulSessions from '../components/fieldsets/MeaningfulSessions/MeaningfulSessions';
import MostUsedBrains from '../components/fieldsets/MostUsed/MostUsedBrainFieldset';
import MostUsedDesks from '../components/fieldsets/MostUsed/MostUsedDesksFieldset';
import OverviewUsage from '../components/fieldsets/OverviewUsage/OverviewUsageFieldset';
import Filters from '../components/filters/AccountFilters/AccountFilters';

export const OverviewReport = () => {
  return (
    <>
      <Filters />
      <OverviewUsage />
      <MeaningfulSessions />
      <MostUsedDesks />
      <MostUsedBrains />
    </>
  );
};
