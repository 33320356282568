import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway } from 'react-use';

import useBrains from '@/hooks/useBrains';
import useDesks from '@/hooks/useDesks';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { popModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectDeskId } from '@/redux/session/selectors';

import ActionConfiguration, {
  BrainVersion,
} from '../ActionConfiguration/ActionConfiguration';

import styles from './DefaultConversationAssignment.module.scss';

const DefaultConversationAssignment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const deskId = useSelector(selectDeskId);
  const { desk, updateDesk } = useDesks(deskId);
  const { brains } = useBrains(desk?.brain_parent_id);
  const brainName = brains?.find(
    (brain) => brain.brain_parent_id === desk?.brain_parent_id
  )?.name;
  const brainVersion = desk?.brain_version;
  const brainRef = useRef<HTMLDivElement>();
  useClickAway(brainRef, () => {
    setIsConfigOpen(false);
  });

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'rules', actions.WRITE)
  );

  const [height, setHeight] = useState(0);
  const measuredRef = (node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().top);
    }
  };

  const handleBrainSubmit = (newBrain: BrainVersion) => {
    updateDesk(
      { desk_id: deskId, ...newBrain },
      {
        onSuccess: () => {
          dispatch(popModal());
          setIsConfigOpen(false);
        },
      }
    );
  };
  return (
    <>
      <div className={styles.title} ref={measuredRef}>
        {t('rules.default_assignment')}
      </div>
      <div className={styles.innerWrapper}>
        <span className={styles.text}>
          {t('rules.default_assignment_desc')}
        </span>
        <div className={styles.wrapper}>
          <button
            className={styles.button}
            disabled={!canWrite}
            onClick={() => setIsConfigOpen(true)}
          >
            <>
              {brainName ? (
                <>
                  {brainName}{' '}
                  {brainVersion === 0 ? t('common.draft') : `v${brainVersion}`}
                </>
              ) : (
                t('brains.select_a_brian')
              )}
            </>
          </button>
        </div>
        <span>
          {isConfigOpen && (
            <ActionConfiguration
              ref={brainRef}
              onSubmit={handleBrainSubmit}
              initiallySelected={desk?.brain_parent_id}
              disconnectButton
              renderOverElement={height > 500}
            />
          )}
        </span>
      </div>
    </>
  );
};

export default DefaultConversationAssignment;
