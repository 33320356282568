import { TextareaAutosize as MuiTextareaAutosize } from '@mui/base/TextareaAutosize';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Dialogs from '@/components/atoms/Icons/Dialogs';
import Entities from '@/components/atoms/Icons/Entities';
import Intents from '@/components/atoms/Icons/Intents';
import useBrains from '@/hooks/useBrains';
import { Dialog } from '@/models/dialog';
import { Entity } from '@/models/entity';
import { Intent } from '@/models/intent';
import { selectBrainId } from '@/redux/session/selectors';

import { defaultStartingText } from './util';

import styles from './ModalAutoBrain.module.scss';

interface Props {
  data: {
    intents: Intent[];
    entities: Entity[];
    dialog: Dialog;
  };
  prompt: string;
}

const AutoBrainResults = ({ data, prompt }: Props) => {
  const { intents, entities, dialog } = data ?? {};
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const intentsNames = intents?.map(({ intent }) => intent)?.join(', ');
  const entitiesNames = entities?.map(({ entity }) => entity)?.join(', ');

  return (
    <div className={styles.resultsContainer}>
      <MuiTextareaAutosize
        disabled
        value={`${defaultStartingText(brain?.language)}${prompt}`}
      />

      <Typography variant="heading2-regular">
        {t('auto_brain.we_generated')}:{' '}
      </Typography>
      <div className={styles.row}>
        <Typography variant="body-medium">
          <Dialogs color="var(--icon-default-blue)" />
          Dialogs:
        </Typography>
        <Typography>{dialog?.name}</Typography>
      </div>
      {intents?.length > 0 && (
        <div className={styles.row}>
          <Typography variant="body-medium">
            <Intents color="var(--icon-default-blue)" />
            {intents.length > 1 ? t('common.intents') : t('common.intent')}:
          </Typography>
          <Typography>{intentsNames}</Typography>
        </div>
      )}
      {entities?.length > 0 && (
        <div className={styles.row}>
          <Typography variant="body-medium">
            <Entities color="var(--icon-default-blue)" />
            {entities.length > 1 ? t('common.entities') : t('common.entity')}:
          </Typography>
          <Typography>{entitiesNames}</Typography>
        </div>
      )}
    </div>
  );
};

export default AutoBrainResults;
