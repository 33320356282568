import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/models/state';
import { User } from '@/models/user';

export const selectUser = (state: RootState): User => state.user.user;

export const selectUserId = (state: RootState) => state.user.user?.user_id;

export const selectIsAuthenticated = createSelector([selectUser], (user) =>
  Boolean(user?.user_id)
);

export const selectIsTempUser = createSelector([selectUser], (user) =>
  Boolean(user && !user?.user_id)
);

export const selectIsLoadingUser = (state: RootState) => {
  const { loading, error, loaded } = state.user?.loginStatus ?? {};

  if (!loading && !error && !loaded) {
    return false;
  }

  return loading || (!loaded && !error);
};

export const selectIsLoggedOut = (state: RootState) => {
  return state.user?.logoutStatus?.loaded;
};

export const selectIsLoadingLogout = (state: RootState) => {
  return state.user?.logoutStatus?.loading;
};
