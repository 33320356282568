import { Fragment, memo } from 'react';

import { User, Message as MessageType } from '@/models/chat';

import Message from '../Message';
import MessageStatus from '../MessageStatus/MessageStatus';

type Props = {
  message: MessageType;
  showTime: boolean;
  showAvatar: boolean;
  user: User;
  visitorStatus: string | null;
  lastVisitorActivity: string | null;
};

export const MessageGroup = memo(
  ({
    message,
    showTime,
    showAvatar,
    user,
    visitorStatus,
    lastVisitorActivity,
  }: Props) => {
    return (
      <Fragment key={message.message_id}>
        <Message
          message={message}
          showAvatar={showAvatar}
          user={user}
          group={showTime}
          visitorStatus={visitorStatus}
          lastVisitorActivity={lastVisitorActivity}
        />
        <MessageStatus
          created={message.created}
          authorType={message.author_type}
          shouldShow={showTime}
        />
      </Fragment>
    );
  }
);

MessageGroup.displayName = 'MessageGroup';
