import { PropsWithChildren, memo } from 'react';

import { NameAvatar } from './NameAvatar';
import { Time } from './Time';

import styles from './Events.module.scss';

type Props = {
  userId?: string;
  time: string;
  name?: string;
};

export const EventWrapper = memo(
  ({ userId, time, name, children }: PropsWithChildren<Props>) => {
    return (
      <div className={styles.event}>
        <NameAvatar id={userId} name={name} />
        {children}
        <Time time={time} />
      </div>
    );
  }
);

EventWrapper.displayName = 'EventWrapper';
