import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { popModal } from '@/redux/modals/actions';

import Modal from './Modal';

type Props = {
  title?: string;
  primaryButtonText?: string;
  children?: React.ReactNode;
  onPrimarySubmit?: () => void;
};

function ModalWarn({
  title,
  children,
  primaryButtonText,
  onPrimarySubmit,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getPrimaryButtonText = useCallback(() => {
    if (primaryButtonText) {
      return primaryButtonText;
    }
    return t('common.close');
  }, [primaryButtonText, t]);

  const handleButtonClick = () => {
    if (onPrimarySubmit) {
      onPrimarySubmit();
    } else {
      dispatch(popModal());
    }
  };

  return (
    <Modal
      title={title || t('common.warning')}
      primaryButtonText={getPrimaryButtonText()}
      hideSecondaryButton
      onPrimarySubmit={handleButtonClick}
    >
      {children}
    </Modal>
  );
}

export default ModalWarn;
