import { useAccount } from '@/hooks/useAccount';

const useFeatureFlag = () => {
  const { account } = useAccount();
  return {
    enableBundles: account?.features?.bundles,
    trackUser: Boolean(account?.features?.trackUser),
    enableLimits: Boolean(account?.features?.limits),
    enableUsageLimits: Boolean(account?.features?.usageLimits),
    // Brain Responses
    replay: Boolean(account?.features?.replay),
    // Collections
    enableCollectionsForRules: Boolean(
      account?.features?.enable_collections_for_rules
    ),

    // Integrations
    suncoTicketCreation: Boolean(
      account?.features?.create_ticket_on_session_expired
    ),
  };
};

export default useFeatureFlag;
