export const recipientErrorStatuses = {
  '0': 'broadcasts.errors.auth_exception',
  '3': 'broadcasts.errors.api_method',
  '10': 'broadcasts.errors.permission_denied',
  '190': 'broadcasts.errors.access_token_expired',
  '200': 'broadcasts.errors.api_permission',
  '4': 'broadcasts.errors.api_too_many_calls',
  '80007': 'broadcasts.errors.rate_limit_issues',
  '130429': 'broadcasts.errors.rate_limit_hit',
  '131048': 'broadcasts.errors.spam_rate_limit_hit',
  '131056': 'broadcasts.errors.pair_rate_limit_hit',
  '133016': 'broadcasts.errors.account_register_deregister_rate_limit_exceeded',
  '368': 'broadcasts.errors.temporarily_blocked_for_policies_violations',
  '131031': 'broadcasts.errors.account_locked',
  '1': 'broadcasts.errors.api_unknown',
  '2': 'broadcasts.errors.api_service',
  '33': 'broadcasts.errors.parameter_value_not_valid',
  '100': 'broadcasts.errors.invalid_parameter',
  '130472': 'broadcasts.errors.users_number_is_part_of_an_experiment',
  '131000': 'broadcasts.errors.something_went_wrong',
  '131005': 'broadcasts.errors.access_denied',
  '131008': 'broadcasts.errors.required_parameter_missing',
  '131009': 'broadcasts.errors.parameter_value_invalid',
  '131016': 'broadcasts.errors.service_unavailable',
  '131021': 'broadcasts.errors.recipient_cannot_be_sender',
  '131026': 'broadcasts.errors.message_undeliverable',
  '131042': 'broadcasts.errors.payment_error',
  '131045': 'broadcasts.errors.incorrect_certificate',
  '131047': 'broadcasts.errors.re_engagement_message',
  '131051': 'broadcasts.errors.unsupported_message_type',
  '131052': 'broadcasts.errors.media_download_error',
  '131053': 'broadcasts.errors.media_upload_error',
  '131057': 'broadcasts.errors.account_in_maintenance_mode',
  '132000': 'broadcasts.errors.template_param_count_mismatch',
  '132001': 'broadcasts.errors.template_does_not_exist',
  '132005': 'broadcasts.errors.template_hydrated_text_too_long',
  '132007': 'broadcasts.errors.template_format_character_policy_violated',
  '132012': 'broadcasts.errors.template_parameter_format_mismatch',
  '132015': 'broadcasts.errors.template_paused',
  '132016': 'broadcasts.errors.template_disabled',
  '132068': 'broadcasts.errors.flow_blocked',
  '132069': 'broadcasts.errors.flow_throttled',
  '133000': 'broadcasts.errors.incomplete_deregistration',
  '133004': 'broadcasts.errors.server_temporarily_unavailable',
  '133005': 'broadcasts.errors.two_step_verification_pin_mismatch',
  '133006': 'broadcasts.errors.phone_number_re_verification_needed',
  '133008': 'broadcasts.errors.too_many_two_step_verification_pin_guesses',
  '133009': 'broadcasts.errors.two_step_verification_pin_guessed_too_fast',
  '133010': 'broadcasts.errors.phone_number_not_registered',
  '133015': 'broadcasts.errors.wait_before_registering_phone_number',
  '135000': 'broadcasts.errors.generic_user_error',
};
