import { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { callGet } from '@/api/fetcher';
import { useIntegrations } from '@/hooks/useIntegrations';
import useUser from '@/hooks/useUser';
import {
  FacebookError,
  FacebookIntegration,
  Integration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import {
  FACEBOOK_SCOPES,
  FACEBOOK_AUTHORIZATION_URL,
  INSTAGRAM_SCOPES,
  getDocsUrl,
} from '@/util/constants';

interface metaInfoData {
  username: string;
  followers_count: string;
}

const infoEndpoint = (slug: string, deskId: string, integrationId: string) =>
  `/www/api/integrations/facebook/${slug}/desks/${deskId}/integrations/${integrationId}/meta`;

const createCallbackURL = () => {
  return `${window?.location?.origin}/www/api/integrations/facebook/callback`;
};

const imageUrl = (slug: string, deskId: string, integrationId: string) =>
  `/www/api/integrations/facebook/${slug}/desks/${deskId}/integrations/${integrationId}/image`;

const getScopes = (type: string) => {
  if (type === 'facebook') {
    return FACEBOOK_SCOPES.join(',');
  }
  return INSTAGRAM_SCOPES.join(',');
};

const getDocsType = (oauthError: string, type: string): string => {
  const docs_type =
    type === 'facebook' ? 'facebook_messenger' : 'instagram_messenger';

  let ext = '';
  switch (oauthError) {
    case 'missing_permissions':
      return getDocsUrl(
        `docs/integrations/${docs_type}#not-granting-all-requested-permissions`
      );
    case 'missing_page_manage_permission':
      return getDocsUrl(`docs/integrations/${docs_type}#not-page-admin`);
    case 'no_new_pages':
      ext =
        type === 'facebook'
          ? 'connecting-existing-facebook-pages'
          : 'connecting-existing-instagram-accounts';
      return getDocsUrl(`docs/integrations/${docs_type}#${ext}`);
    case 'more_than_one_page':
      ext =
        type === 'facebook'
          ? 'connecting-more-than-one-facebook-page'
          : 'connecting-more-than-one-instagram-account';
      return getDocsUrl(`docs/integrations/${docs_type}#${ext}`);
    default:
      return getDocsUrl(`docs/integrations/${docs_type}#troubleshooting`);
  }
};

const createOAuthURL = async (slug: string, integration: Integration) => {
  const callbackUrl = createCallbackURL();
  const { desk_id, integration_id } = integration;
  const moveoFbAppId = await callGet('/www/api/integrations/facebook/app-id');
  const queryParams = new URLSearchParams({
    redirect_uri: callbackUrl,
    client_id: moveoFbAppId,
    response_type: 'code granted_scopes',
    scope: getScopes(integration.type),
    state: JSON.stringify({ desk_id, integration_id, slug }),
  });

  return `${FACEBOOK_AUTHORIZATION_URL}?${queryParams}`;
};

const getLastConnection = (lastConnection: string) => {
  if (lastConnection != null) {
    return moment(lastConnection).fromNow();
  }
  return null;
};

export const useFacebook = (errorLoadingImage?) => {
  const { t } = useTranslation();
  const { deskId, integrationId } = useParams();
  const slug = useSelector(selectAccountSlug);
  const dispatch = useDispatch();
  const { search } = useLocation();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  const { integration, updateIntegration } =
    useIntegrations<FacebookIntegration>(deskId, integrationId);

  const { user } = useUser();

  const type = integration?.type;
  const config = integration?.config;
  const isManual = !!(config?.is_manual || config?.app_secret);
  const previousFBConnection = getLastConnection(
    user?.metadata?.facebook_last_connected as string
  );

  const [error, setError] = useState<FacebookError>();

  const onManualClick = useCallback(() => {
    updateIntegration({
      ...integration,
      config: {
        ...integration?.config,
        is_manual: true,
      },
    });
  }, [integration, updateIntegration]);

  const onOAuthClick = useCallback(async () => {
    const facebookUrl = await createOAuthURL(slug, integration);
    window.open(facebookUrl, '_self');
  }, [slug, integration]);

  const connectionStatus = useMemo(() => {
    if (integration?.config?.access_token && integration?.config?.page_id) {
      return 'connected';
    }
    return 'not-connected';
  }, [integration?.config.access_token, integration?.config.page_id]);

  // Parse errors from the query parameters
  useEffect(() => {
    const oauthError = new URLSearchParams(search).get('error');
    const pagesNames = new URLSearchParams(search).get('pages_names');

    if (oauthError) {
      const toastError = t(
        `integrations.facebook.errors.${oauthError}`,
        'integrations.facebook.errors.generic',
        { type, pagesNames }
      );

      const message = t(
        `integrations.facebook.errors.${oauthError}_field`,
        '',
        { type, pagesNames }
      );
      const url = getDocsType(oauthError, type);

      dispatch(addTemporalToast('error', toastError));
      setError({ message, url });
    }
  }, [t, dispatch, type, search]);

  const { data, isLoading } = useQuery<metaInfoData>({
    queryKey: [
      infoEndpoint(slug, deskId, integrationId),
      integration?.config?.access_token,
    ],
    queryFn: () => callGet(infoEndpoint(slug, deskId, integrationId)),
    enabled: !!deskId && !!integrationId && canWrite,
  });

  const { data: image, isLoading: imageLoading } = useQuery<
    Record<string, string>
  >({
    queryKey: [
      imageUrl(slug, deskId, integrationId),
      integration?.config?.access_token,
    ],
    queryFn: () => callGet(imageUrl(slug, deskId, integrationId)),
    enabled: !!errorLoadingImage && canWrite,
  });

  return {
    integration,
    isManual,
    previousFBConnection,
    connectionStatus,
    error,
    onManualClick,
    onOAuthClick,
    infoData: data,
    infoIsLoading: isLoading,
    image,
    imageLoading,
  };
};

export default useFacebook;
