import cn from 'classnames';

import Attachment from '@/components/atoms/Icons/Attachment';
import CarouselOld from '@/components/organisms/Dialogs/CarouselOld/CarouselOld';
import { useMarkdownToHtml } from '@/hooks/useMarkdownToHtml';
import { Message } from '@/models/chat';
import { ResponseMessage } from '@/models/tryIt';

import styles from './Response.module.scss';

type HasAvatar = {
  hasAvatar?: boolean;
};

export const Response = (
  props: Partial<ResponseMessage & Message & HasAvatar>
) => {
  const { html } = useMarkdownToHtml(props.text || '');
  switch (props.type) {
    case 'text':
    case 'disambiguation':
      return (
        <>
          <span
            className={cn(styles.bubble, styles.brain, styles.text, {
              [styles.hasAvatar]: props.hasAvatar,
            })}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {props?.options && (
            <div className={styles.optionsContainer}>
              {props.options.map((option) => (
                <button
                  key={`option-${option.text}`}
                  className={cn(styles.bubble, styles.option)}
                  onClick={() => {}}
                  type="button"
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </>
      );
    case 'url':
      return (
        <p className={cn(styles.bubble, styles.brain, styles.url)}>
          <a href={props.url} target="_blank" rel="noopener noreferrer">
            {props.url}
          </a>
        </p>
      );
    case 'audio':
      return (
        <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
          <audio src={props.url} controls />
        </p>
      );
    case 'image':
      return (
        <span className={cn(styles.bubble, styles.brain, styles.image)}>
          <img src={props.url} alt={props.name} referrerPolicy="no-referrer" />
        </span>
      );
    case 'video':
      return (
        <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
          <video src={props.url} controls />
        </p>
      );
    case 'file':
      return (
        <p className={cn(styles.bubble, styles.brain, styles.file)}>
          <a href={props.url} target="_blank" rel="noopener noreferrer">
            <Attachment color="var(--icon-default-blue)" />
            {props.name ? props.name : props.text}
          </a>
        </p>
      );
    case 'carousel':
      return (
        <div
          className={cn(styles.carouselContainer, styles.isChat, {
            [styles.oneCard]: props.cards.length === 1,
          })}
        >
          <CarouselOld isChat cards={props.cards} slidesPerView={2} />
        </div>
      );
    case 'webview':
    case 'survey':
      return (
        <div className={styles.webviewContainer}>
          <p>{props.name}</p>
          <div className={cn(styles.webviewButtonContainer, styles.disabled)}>
            <p>{props.label}</p>
          </div>
        </div>
      );
    default:
      return null;
  }
};
