import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography = {
  ['label-regular']: {
    fontSize: 'var(--space-12)',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: 'var(--space-16)',
  },

  ['label2-regular']: {
    fontSize: 'var(--space-12)',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: 'var(--space-18)',
  },

  ['label2-medium']: {
    fontSize: 'var(--space-12)',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: 'var(--space-18)',
  },

  ['label-caps-small']: {
    fontSize: 'var(--space-10)',
    letterSpacing: '0',
    fontWeight: '600',
    textTransform: 'uppercase',
    lineHeight: 'var(--space-18)',
  },

  ['label-caps-big']: {
    fontSize: 'var(--space-12)',
    letterSpacing: '0',
    fontWeight: '600',
    textTransform: 'uppercase',
    lineHeight: 'var(--space-12)',
  },
  ['label-bold']: {
    fontSize: 'var(--space-12)',
    letterSpacing: '0',
    lineHeight: 'var(--space-16)',
    fontWeight: '600',
  },
  ['label-caps-bold']: {
    fontSize: 'var(--space-12)',
    letterSpacing: '0',
    textTransform: 'uppercase',
    lineHeight: 'var(--space-12)',
    fontWeight: '800',
  },

  ['label-nav-regular']: {
    fontFamily: 'var(--font-primary)',
    fontSize: 'var(--space-10)',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: 'var(--space-14)',
  },

  ['code-small']: {
    fontFamily: 'var(--font-mono)',
    fontSize: 'var(--space-10)',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: 'var(--space-18)',
  },
  ['code-big']: {
    fontFamily: 'var(--font-mono)',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: 'var(--space-18)',

    fontSize: 'var(--space-12)',
  },

  ['italic']: {
    fontFamily: 'var(--font-primary)',
    fontSize: 'var(--space-10)',
    fontWeight: '400',
    lineHeight: 'var(--space-14)',
    fontStyle: 'italic',
  },
} as TypographyOptions;

export default typography;
