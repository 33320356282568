import { useSelector } from 'react-redux';

import { LogSessionContentRow } from '@/hooks/useLogs';
import {
  selectSelectedMessage,
  selectSelectedMessageIndex,
} from '@/modules/TryIt/redux/selectors';

import { BrainDetailsSection } from './BrainDetailsSection/BrainDetailsSection';
import { CollectionDetailsSection } from './CollectionDetailsSection/CollectionDetailsSection';
import { DisambiguationDetailsSection } from './DisambiguationDetailsSection';
import { UnknownDetailsSection } from './UnknownDetailsSection';
import { useResponseTypeFlags } from '../../../hooks/useResponseTypeFlags';

type DetailsSectionProps = {
  content?: LogSessionContentRow;
};

export const DetailsSection = ({ content }: DetailsSectionProps) => {
  const selectedMessageIndex = useSelector(selectSelectedMessageIndex);
  const selectedMessage = useSelector(selectSelectedMessage);

  const selectedNode = selectedMessage?.nodes[selectedMessageIndex];

  const {
    isAgentResponse,
    isBrainResponse,
    isUnknownResponse,
    isCollectionResponse,
    isDisambiguationResponse,
    isResponseCompleted,
  } = useResponseTypeFlags({
    index: selectSelectedMessageIndex,
    node: selectedNode,
  });

  if (isAgentResponse) return null;

  // Show details section only when we have content or when we have a completed response
  if (!content && !isResponseCompleted) return null;

  if (isCollectionResponse) {
    return <CollectionDetailsSection content={content} />;
  } else if (isDisambiguationResponse) {
    return <DisambiguationDetailsSection />;
  } else if (isUnknownResponse) {
    return <UnknownDetailsSection content={content} />;
  } else if (isBrainResponse) {
    return <BrainDetailsSection content={content} />;
  }

  return null;
};
