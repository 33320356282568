import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate, useParams } from 'react-router';

import DeskHeader from '@/components/organisms/Headers/DeskHeader';
import { SettingsNavigation } from '@/components/organisms/Navbar/SettingsNavigation/SettingsNavigation';
import BusinessHours from '@/components/pages/BusinessHours/BusinessHours';
import DeskSettings from '@/components/pages/Desk/DeskSettings';
import Layout from '@/components/templates/Layout/Layout';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import Bundles from '@/modules/bundles/pages/Bundles/Bundles';
import Departments from '@/modules/departments/pages/Departments';
import { setDeskId, setIntegrationId } from '@/redux/session/actions';

const EnvironmentRouter: React.FC = () => {
  const dispatch = useDispatch();
  const { deskId, integrationId } = useParams();
  const { enableBundles } = useFeatureFlag();

  useEffect(() => {
    if (deskId) {
      dispatch(setDeskId(deskId));
    }
  }, [dispatch, deskId]);

  useEffect(() => {
    if (integrationId) {
      dispatch(setIntegrationId(integrationId));
    }
    return () => {
      dispatch(setIntegrationId(null));
    };
  }, [dispatch, integrationId]);

  return (
    <Layout withSidebar>
      <SettingsNavigation />
      <DeskHeader />
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={enableBundles ? 'context_bundles' : 'settings'}
              replace
            />
          }
        />
        <Route path="/business_hours" element={<BusinessHours />} />
        <Route path="/context_bundles" element={<Bundles />} />
        <Route path="/departments/*" element={<Departments />} />
        <Route path="/settings" element={<DeskSettings />} />
      </Routes>
    </Layout>
  );
};

EnvironmentRouter.displayName = 'EnvironmentRouter';

export default EnvironmentRouter;
