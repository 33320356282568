import { memo, useCallback, useState } from 'react';

import { MutationStatus } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import {
  useForm,
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
  UseFormReset,
  UseFormGetValues,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import { useTemplates } from '@/hooks/useTemplates';
import { popModal } from '@/redux/modals/actions';

import { WizardBrain } from './WizardBrain';
import { Wrapper } from './Wrapper';
import Modal from '../Modal';

type TFieldValues = {
  name: string;
  description: string;
  language: string;
};

export type NewBrainProps = {
  register: UseFormRegister<TFieldValues>;
  errors: FieldErrors;
  isDirty: boolean;
  isValid: boolean;
  setStep: (step: number) => void;
  setValue: UseFormSetValue<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  reset: UseFormReset<TFieldValues>;
};

export type CommonProps = {
  step: number;
  t: TFunction;
};

export type TemplateProps = {
  language: string;
  setStep: (step: number) => void;
  setTemplateId: (templateId: string) => void;
  onSubmit: () => void;
  createStatus: MutationStatus;
};

export type TemplateTryProps = {
  templateId: string;
  language: string;
  name: string;
  description: string;
};

const ModalBrainCreationNew = () => {
  // Custom hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { slug } = useAccount();
  const { createStatus } = useBrains();
  const { createBrainFromTemplateAsync } = useTemplates();

  // Local state
  const [step, setStep] = useState<number>(1);
  const [templateId, setTemplateId] = useState('');

  // RHF
  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    watch,
    register,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      language: '',
    },
  });

  // Callbacks
  const onSubmit = useCallback(
    async (newBrain) => {
      createBrainFromTemplateAsync(
        { ...newBrain, brain_id: 'playground' },
        {
          onSuccess: (b) => {
            dispatch(popModal());
            navigate(`/${slug}/brains/${b.brain_id}`);
          },
        }
      );
    },
    [createBrainFromTemplateAsync, dispatch, navigate, slug]
  );

  return (
    <Modal cleanModal noGutters size="large">
      <Wrapper step={step} setStep={setStep}>
        <WizardBrain
          step={step}
          setStep={setStep}
          t={t}
          register={register}
          errors={errors}
          isDirty={isDirty}
          isValid={isValid}
          language={watch('language')}
          templateId={templateId}
          setTemplateId={setTemplateId}
          onSubmit={handleSubmit(onSubmit)}
          createStatus={createStatus}
          name={getValues('name')}
          description={getValues('description')}
          setValue={setValue}
          reset={reset}
          getValues={getValues}
        />
      </Wrapper>
    </Modal>
  );
};

export default memo(ModalBrainCreationNew);
