import { useCallback, useState } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import Tab from '@/components/atoms/Tab/Tab';
import Tabs from '@/components/atoms/Tabs/Tabs';
import Header from '@/components/organisms/Header/Header';
import {
  MODAL_MEMBER_INVITE,
  MODAL_TEAM,
} from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { trackEvent } from '@/segment/segment';

export const AccessHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { slug } = useAccount();
  const location = useLocation();

  const isTeamsPage = location.pathname.includes('team-access');

  const canAssign = useSelector((state: RootState) =>
    getPermissions(state, 'roles', actions.ASSIGN)
  );

  const handleClick = useCallback(() => {
    if (!isTeamsPage) {
      dispatch(pushModal(MODAL_MEMBER_INVITE));
    } else {
      trackEvent(EventName.ClickCreateAccessTeam);
      dispatch(pushModal(MODAL_TEAM));
    }
  }, [dispatch, isTeamsPage]);

  const header = [
    {
      text: t('common.members'),
      link: `/${slug}/account/manage/member-access`,
    },
    {
      text: t('common.teams'),
      link: `/${slug}/account/manage/team-access`,
    },
  ];

  const [tabValue, setTabValue] = useState(
    isTeamsPage ? header[1]?.text : header[0]?.text
  );

  const primaryText = !isTeamsPage
    ? t('members.invite')
    : t('teams.create_team');

  return (
    <Header>
      <Header.Body noGutters>
        <Header.Title
          title={
            <Typography
              variant="heading1-medium"
              color="var(--text-default-gray)"
            >
              {t('permissions.manage_access')}
            </Typography>
          }
        />
      </Header.Body>
      <Header.Navigation>
        <Tabs
          value={tabValue}
          size="medium"
          onChange={(_, newValue) => {
            setTabValue(newValue);
          }}
        >
          {header.map((item) => (
            <Tab
              key={item.link}
              label={item.text}
              value={item.text}
              to={item.link}
              component={NavLink}
            />
          ))}
        </Tabs>
        <Header.Actions>
          <Button disabled={!canAssign} onClick={handleClick}>
            {primaryText}
          </Button>
        </Header.Actions>
      </Header.Navigation>
    </Header>
  );
};
