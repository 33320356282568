import { PropsWithChildren, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import ProgressBar from '@/components/atoms/ProgressBar/ProgressBar';
import { MODAL_INGESTION_REPORT } from '@/components/organisms/Modals/ModalConductor';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { pushModal } from '@/redux/modals/actions';

import { FragmentBanner } from '../FragmentBanner';

import styles from './CollectionSidebar.module.scss';

interface Props {
  percentage: number;
  total: number;
  available: number;
  data: {
    label: string;
    value: number;
  }[];
  type?: string;
  emptyMessage?: string;
  modalReportData?:
    | {
        title: string;
        value: string;
        urls?: string[];
      }[]
    | [];
  hasBanner?: boolean;
}

const CollectionSidebar = ({
  percentage,
  total,
  available,
  data,
  children,
  type = 'files',
  emptyMessage = '',
  modalReportData,
  hasBanner,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enableLimits } = useFeatureFlag();

  const handleIngestionReportClick = useCallback(() => {
    dispatch(
      pushModal(MODAL_INGESTION_REPORT, { data: modalReportData, hasBanner })
    );
  }, [dispatch, hasBanner, modalReportData]);

  return (
    <div className={styles.sidebar}>
      <div className={styles.wrapper}>
        <div className={styles.sidebarHeader}>
          {data.map((item) => {
            return (
              <div className={styles.box} key={item.label}>
                <Typography variant="label-caps-big">{item.label}</Typography>
                <Typography variant="heading1-bold">{item.value}</Typography>
              </div>
            );
          })}
        </div>
        <div className={styles.progressContainer}>
          <span className={styles.report}>
            <Typography variant="body-medium">
              {t('collections.ingest_progress')}
            </Typography>
            {modalReportData && (
              <Button
                variant="tertiary"
                noGutters
                onClick={handleIngestionReportClick}
              >
                {t('collections.view_report')}
              </Button>
            )}
          </span>
          <ProgressBar percentage={percentage} />
          {total > 0 ? (
            <Typography variant="label2-regular">
              {available} / {total} {type.toLowerCase()}
            </Typography>
          ) : (
            <Typography variant="label2-regular">{emptyMessage}</Typography>
          )}
        </div>
      </div>
      {enableLimits && <FragmentBanner />}
      {children}
    </div>
  );
};

export default CollectionSidebar;
