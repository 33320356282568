interface Props {
  name: string;
  children: React.ReactNode;
}

import styles from './ThemeButton.module.scss';

const ThemeButton = ({ name, children }: Props) => {
  return (
    <div className={styles.wrapper}>
      {children}
      <span className={styles.name}>{name}</span>
    </div>
  );
};

export default ThemeButton;
