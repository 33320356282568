import { OptionBase } from './common';

export interface AuditLogs {
  pagination: Pagination;
  audit_logs?: AuditLog[] | null;
}
export interface Pagination {
  limit: number;
  has_more: boolean;
  next_cursor: string;
}
export interface AuditLog {
  log_id: string;
  account_id: string;
  source_id: string;
  source_name: string;
  source_type: 'user' | 'system';
  target_id: string;
  target_name: string;
  target_type: string;
  extra: Record<string, unknown>;
  log_type: string;
  verb: string;
  brain_id: string;
  desk_id: string;
  created: string;
}

export const enum AuditLogsType {
  ACCOUNT = 'filters',
}

export type FilterType = 'brainIds' | 'deskIds';

export interface Filters {
  type: FilterType;
  deskIds?: OptionBase[];
  brainIds?: OptionBase[];
}

export interface AuditLogsFilters {
  startDate?: string | null;
  endDate?: string | null;
  filters: Filters[];
  filtersLoaded?: boolean;
}
