import { useCallback, useEffect, useState } from 'react';

import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import Create from '@/components/atoms/Icons/Create';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import { OptionBase } from '@/models/common';
import {
  AccordionFieldsetProps,
  WebWidgetIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { languageAndFlagByCountryCode } from '@/util/languageCodes';
import { LENGTH_XXXL, errorMessage, headlineValidator } from '@/util/validator';

import VisitorFormCard, {
  fieldOptions,
} from './VisitorFormCard/VisitorFormCard';

import styles from './VisitorInformation.module.scss';

type Fields = {
  field: string;
  required: boolean;
  custom?: string;
  languages?: string[];
};

type Form = {
  fields: Fields[];
  headline: string;
  disclaimer: string;
  disclaimer_checkbox: boolean;
  collect_data_checkbox: boolean;
};

const VisitorInformation = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, WebWidgetIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const dispatch = useDispatch();
  const { updateIntegration, updateStatus } =
    useIntegrations<WebWidgetIntegration>(
      integration?.desk_id,
      integration?.integration_id
    );

  const [languageOptions, setLanguageOptions] = useState<OptionBase[]>();

  const checkForCustomField = useCallback(
    (fields, fieldOptions) => {
      if (!fields || fields.length === 0) {
        return;
      }
      const availableOptions = fieldOptions.map((x) => x.value);

      return fields?.map((field) => {
        if (field.field === 'language' && languageOptions) {
          return {
            ...field,
            languages: field?.languages?.map((key) => ({
              label: languageAndFlagByCountryCode[key]?.name,
              value: key,
            })),
          };
        } else if (availableOptions.includes(field.field)) {
          return field;
        } else {
          return { ...field, custom: field.field };
        }
      });
    },
    [languageOptions]
  );

  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });

  const {
    register,
    reset,
    formState: { errors, isDirty },
    control,
    handleSubmit,
    getValues,
    watch,
    trigger,
  } = formMethods;

  const [collectDataCheckbox, disclaimerCheckbox] = useWatch({
    control,
    name: ['collect_data_checkbox', 'disclaimer_checkbox'],
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fields',
  });

  useEffect(() => {
    reset({
      fields: checkForCustomField(
        integration?.config?.visitor_information?.fields,
        fieldOptions
      ),
      headline: integration?.config?.visitor_information?.headline || '',
      disclaimer:
        integration?.config?.visitor_information?.disclaimer?.html || '',
      disclaimer_checkbox:
        !!integration?.config?.visitor_information?.disclaimer?.html,
      collect_data_checkbox:
        !!integration?.config?.visitor_information?.fields ||
        !!integration?.config?.visitor_information?.headline,
    });
  }, [
    checkForCustomField,
    integration?.config?.visitor_information?.disclaimer?.enabled,
    integration?.config?.visitor_information?.disclaimer?.html,
    integration?.config?.visitor_information?.fields,
    integration?.config?.visitor_information?.headline,
    reset,
  ]);

  useEffect(() => {
    const options = Object.keys(languageAndFlagByCountryCode).reduce(
      (acc, key) => {
        if (!languageAndFlagByCountryCode[key]?.display_only) {
          return [
            ...acc,
            {
              label: languageAndFlagByCountryCode[key]?.name,

              value: key,
            },
          ];
        }
        return acc;
      },
      []
    );
    setLanguageOptions(options);
  }, [reset]);

  const handleAddField = useCallback(() => {
    append({
      field: '',
      required: true,
    });
  }, [append]);

  const handleDelete = useCallback(
    (index) => {
      remove(index);
    },
    [remove]
  );

  const prepareFields = (fields) => {
    if (!fields || fields.length === 0) {
      return;
    }
    return fields?.map((x) => {
      if (x.custom && x.custom === x.field) {
        x.field = x.custom;
      }
      return {
        field: x.field,
        required: x.required,
        languages:
          x.field === 'language'
            ? x?.languages.map((item) => item.value)
            : undefined,
      };
    });
  };

  const handleUpdateIntegration = useCallback(() => {
    const {
      headline,
      fields,
      disclaimer,
      collect_data_checkbox,
      disclaimer_checkbox,
    } = getValues();
    const trimmedDisclaimer = disclaimer.trim();
    const newFields = prepareFields(fields);
    updateIntegration(
      {
        ...integration,
        config: {
          ...integration?.config,
          visitor_information:
            collect_data_checkbox || disclaimer_checkbox
              ? {
                  headline: collect_data_checkbox
                    ? headline || undefined
                    : undefined,
                  fields: collect_data_checkbox ? newFields : undefined,
                  disclaimer: disclaimer_checkbox
                    ? { html: trimmedDisclaimer, enabled: disclaimer_checkbox }
                    : undefined,
                }
              : undefined,
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, getValues, integration, type, updateIntegration]);

  usePrompt(
    isDirty && canWrite,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [checkForCustomField, dispatch, expanded, isDirty, reset, type]);

  return (
    <Accordion
      title={t('integrations.web_visitor.title')}
      subtitle={t('integrations.web_visitor.subtitle')}
      footerText={t('integrations.web_visitor.footer')}
      onSubmit={handleSubmit(handleUpdateIntegration)}
      disabled={!isDirty}
      isLoading={updateStatus === 'pending'}
      expanded={expanded === type}
      handleChange={toggleAccordion(type)}
      readOnly={!canWrite}
      ref={registerAccordion(
        type,
        handleSubmit(handleUpdateIntegration),
        trigger
      )}
    >
      <div className={styles.formConfigWrapper}>
        <Controller
          render={({ field: { onChange, value } }) => {
            return (
              <Checkbox
                label={
                  <TitleSubtitle
                    title={t('integrations.web_visitor.title2')}
                    subtitle={t('integrations.web_visitor.subtitle2')}
                    noGutters
                  />
                }
                onChange={onChange}
                checked={value}
                disabled={!canWrite}
              />
            );
          }}
          name="collect_data_checkbox"
          control={control}
        />
        {collectDataCheckbox && (
          <>
            <div className={styles.textArea}>
              <TextAreaAsInput
                id="headline"
                name="headline"
                label="headline"
                placeholder={t('integrations.web_visitor.placeholder')}
                size="large"
                register={register('headline')}
                height="medium"
                trimValue
                readOnly={!canWrite}
                errorMessage={errorMessage({
                  field: errors.headline,
                  maxLength: headlineValidator.maxLength,
                })}
              />
            </div>
            <span className={styles.textAreaHelp}>
              {t('integrations.web_visitor.help')}
            </span>
            <div
              className={styles.interactive}
              role="button"
              onClick={handleAddField}
              onKeyDown={() => {}}
              tabIndex={0}
            >
              <span>
                <Create color="var(--icon-default-blue)" />
              </span>
              {t('integrations.web_visitor.add_field')}
            </div>
            <div>
              {fields.map((field, index) => (
                <VisitorFormCard
                  key={field.id}
                  index={index}
                  errors={errors}
                  register={register}
                  onDelete={() => handleDelete(index)}
                  watch={watch}
                  reset={reset}
                  control={control}
                  languageOptions={languageOptions}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <Checkbox
              label={
                <TitleSubtitle
                  title={t('integrations.web_visitor.disclaimer_title')}
                  subtitle={t('integrations.web_visitor.disclaimer_subtitle')}
                  noGutters
                />
              }
              onChange={onChange}
              checked={value}
              disabled={!canWrite}
            />
          );
        }}
        name="disclaimer_checkbox"
        control={control}
      />
      {disclaimerCheckbox && (
        <>
          <div className={styles.textArea}>
            <Typography
              variant="label-caps-big"
              component="label"
              htmlFor="disclaimer"
              className={styles.textArea__label}
            >
              {t('integrations.web_visitor.textarea_label')}
            </Typography>

            <TextareaAutosize
              id="disclaimer"
              name="disclaimer"
              placeholder={t('integrations.web_visitor.disclaimer_placeholder')}
              {...register('disclaimer', {
                minLength: 2,
                required: true,
                maxLength: LENGTH_XXXL,
              })}
              disabled={!canWrite}
              minRows={3}
              maxRows={30}
              className={cn({
                [styles.error]: !!errors.disclaimer,
              })}
            />
            {errors?.disclaimer && (
              <Typography
                variant="label-regular"
                component="p"
                className={styles.errorMessage}
                my="var(--space-4)"
              >
                {errorMessage({
                  field: errors.disclaimer,
                  minLength: 2,
                  maxLength: LENGTH_XXXL,
                })}
              </Typography>
            )}

            <span className={styles.disclaimerText}>
              {t('integrations.web_visitor.disclaimer_text')}
            </span>
          </div>
        </>
      )}
    </Accordion>
  );
};

export default VisitorInformation;
