import { createSelector } from '@reduxjs/toolkit';

import { actions as permissionActions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import { Subscriber, WhatsappTemplate } from '../models';
import { getContextVariableOptions, isScheduledToBeSentNow } from '../utils';
export const selectBroadcast = (state: RootState) => state.broadcasts.broadcast;

export const selectBroadcastDeskId = (state: RootState) =>
  state.broadcasts.deskId;

export const selectIsDraft = (state: RootState) => state.broadcasts.isDraft;

export const selectIsDirty = (state: RootState) => state.broadcasts.isDirty;

export const selectIsSubscribersDirty = (state: RootState) =>
  state.broadcasts.isSubscribersDirty;

export const selectErrorCounter = (state: RootState) =>
  state.broadcasts.errorCounter;

export const selectSubscribers = (state: RootState) =>
  state.broadcasts.subscribers;

export const selectValidSubscribers = createSelector(
  [selectSubscribers],
  (subscribers) => subscribers.filter((subscriber) => !subscriber.status_code)
);

const selectTemplates = (state: RootState) => state.broadcasts.templates;

export const selectValidTemplates = createSelector(
  [selectTemplates],
  (templates) =>
    templates.reduce((acc, template) => {
      const header = template.components.find(
        (component) => component.type === 'HEADER'
      );
      if (template.status !== 'APPROVED') {
        return acc;
      }
      if (!header || header.format === 'TEXT') {
        return [...acc, template];
      }
      return acc;
    }, [] as WhatsappTemplate[])
);

export const selectContextVariableOptions = createSelector(
  [selectSubscribers],
  (subscribers: Partial<Subscriber>[]) => {
    return getContextVariableOptions(subscribers);
  }
);

export const selectIsReadOnly = createSelector(
  [
    selectBroadcast,
    (state: RootState) =>
      getPermissions(state, 'broadcasts', permissionActions.WRITE),
  ],
  (broadcast, hasPermissions) => {
    const computedStatus = isScheduledToBeSentNow(broadcast)
      ? 'in_progress'
      : broadcast?.status;

    return (
      computedStatus === 'sent' ||
      computedStatus === 'in_progress' ||
      !hasPermissions
    );
  }
);

export const selectDraftSubscribers = createSelector(
  [selectSubscribers],
  (subscribers) => subscribers.filter((s) => !s.created)
);
