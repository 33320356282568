import ConversationsHandled from '../components/fieldsets/ConversationsHandled/ConversationsHandled';
import AgentPerformance from '../components/fieldsets/Performance/AgentPerformance';
import BrainPerformance from '../components/fieldsets/Performance/BrainPerformance';
import RatingCount from '../components/fieldsets/RatingCount/RatingCount';
import TeamResponseTime from '../components/fieldsets/TeamResponseTime/TeamResponseTime';
import Filters from '../components/filters/AccountFilters/AccountFilters';

export const AgentsPerformance = () => {
  return (
    <>
      <Filters />
      <ConversationsHandled />
      <TeamResponseTime />
      <RatingCount />
      <AgentPerformance />
      <BrainPerformance />
    </>
  );
};
