import { useCallback, useRef } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import './CopyButton.scss';
import { useDispatch } from 'react-redux';

import { addTemporalToast } from '@/modules/notifications/redux/actions';

import Duplicate from '../Icons/Duplicate';

export type CopyButtonProps = {
  data: string;
  className?: string;
  onCopy?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  iconPosition?: 'left' | 'right' | 'center';
};

type NoIcon = {
  hideIcon?: boolean;
  iconSize?: never;
};

type IconSize = {
  iconSize?: number;
  hideIcon?: never;
};

type Props = CopyButtonProps & (NoIcon | IconSize);
const CopyButton = ({
  className,
  data,
  children,
  disabled,
  iconSize = 18,
  hideIcon = false,
  iconPosition = 'right',
}: Props) => {
  const { t } = useTranslation();

  const textRef = useRef(null);
  const dispatch = useDispatch();

  const copyToClipboard = useCallback(
    (e) => {
      e.preventDefault();
      textRef.current.select();
      const text = textRef.current.defaultValue;
      navigator.clipboard.writeText(text);
      e.target.focus();
      if (!disabled) {
        dispatch(addTemporalToast('success', t('common.copied')));
      }
    },
    [t, dispatch, disabled]
  );

  return (
    <span className={cn(className)}>
      <input
        readOnly
        data-testid="input-copy"
        className="copy copy__input"
        type="text"
        ref={textRef}
        value={data}
      />
      <button
        data-testid="button-copy"
        className="button-copy"
        aria-label={t('common.copy')}
        onClick={copyToClipboard}
        type="button"
        disabled={disabled}
      >
        {!hideIcon && iconPosition === 'left' && <Duplicate size={iconSize} />}
        {children ? (
          <span
            className={cn(
              'copy_children',
              !hideIcon && `icon_position_${iconPosition}`
            )}
          >
            {children}
          </span>
        ) : null}
        {!hideIcon && iconPosition === 'right' && <Duplicate size={iconSize} />}
      </button>
    </span>
  );
};

export default CopyButton;
