import Typography from '@mui/material/Typography';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';

import styles from './AnalyticsBox.module.scss';

interface Props {
  label: string;
  percentage: number;
  value: string;
  isDisabled?: boolean;
}
export const AnalyticsBox = ({
  label,
  percentage,
  value,
  isDisabled = false,
}: Props) => {
  const disabledValue = `-/-`;
  return (
    <div className={styles.box}>
      <Typography variant="label-caps-big" color="var(--text-default-gray)">
        {label}
      </Typography>
      <Gauge
        width={130}
        height={80}
        value={isDisabled ? 0 : percentage}
        startAngle={-90}
        endAngle={90}
        innerRadius="85%"
        outerRadius="100%"
        text={({ value }) => `${value}%`}
        sx={() => ({
          [`& .${gaugeClasses.valueArc}`]: {
            fill:
              percentage < 70
                ? 'var(icon-default-blue)'
                : 'var(--icon-default-success)',
          },
          [`& .${gaugeClasses.valueText} text`]: {
            fill: isDisabled
              ? 'var(--text-disabled-gray)'
              : 'var(--text-default-gray)',
            fontWeight: 'bold',

            transform: 'translateY(-12px)',
          },
        })}
      />
      <StatusBadge
        label={isDisabled ? disabledValue : value}
        variant="neutral"
      />
    </div>
  );
};
