import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import { BAD_EXECUTION_TIME, MODERATE_EXECUTION_TIME } from '@/util/constants';

import styles from './Webhook.module.scss';

interface Props {
  statusCode: number;
  executionTime: number;
}

const TestResults = ({ statusCode, executionTime }: Props) => {
  const { t } = useTranslation();
  const statusCodeColor = useCallback(() => {
    if ((statusCode >= 200 && statusCode < 300) || !statusCode) {
      return 'success';
    }
    return 'critical';
  }, [statusCode]);

  const executionTimeColor = useCallback(() => {
    if (executionTime < MODERATE_EXECUTION_TIME) {
      return 'success';
    }
    if (executionTime < BAD_EXECUTION_TIME) {
      return 'warning';
    }
    return 'critical';
  }, [executionTime]);

  return (
    <div className={styles.testResults}>
      <span>{t('webhook.test.test_results')}</span>
      <span className={styles.results}>
        <StatusBadge
          variant={statusCodeColor()}
          label={t('webhook.test.status_code')}
          value={`${statusCode}`}
          withIcon={false}
          valueBadge
        />
        <StatusBadge
          variant={executionTimeColor()}
          label={t('webhook.test.execution_time')}
          value={`${executionTime}ms`}
          withIcon={false}
          valueBadge
        />
      </span>
    </div>
  );
};

export default TestResults;
