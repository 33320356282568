import { useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_VIDEO } from '@/components/organisms/Modals/ModalConductor';
import useHomeCheckList, {
  AccountUserPrefsEnum,
  INAPPHELP_KEYS,
} from '@/hooks/useHomeCheckList';
import useLocalStorage from '@/hooks/useLocalStorage';
import { RootState } from '@/models/state';
import { hideHelper, showHelper } from '@/redux/helpers/actions';
import { selectHelper } from '@/redux/helpers/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import { selectAccountSlug, selectBrainId } from '@/redux/session/selectors';

const useDialogHelper = () => {
  const { accountUserChecklist, markAsComplete, getSteps } = useHomeCheckList();
  const slug = useSelector(selectAccountSlug);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [dismissVideo, setDismissVideo] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_BRAIN],
    false
  );
  const brainId = useSelector(selectBrainId);

  const showWhatisBrainVideo = useSelector((state: RootState) =>
    selectHelper(state, INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_BRAIN])
  );
  const steps = useMemo(
    () => getSteps(accountUserChecklist, slug, brainId, undefined),
    [accountUserChecklist, brainId, getSteps, slug]
  );

  useEffect(() => {
    if (
      !dismissVideo &&
      accountUserChecklist &&
      !accountUserChecklist[AccountUserPrefsEnum.WHAT_IS_BRAIN]
    ) {
      dispatch(showHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_BRAIN]));
      return () => {
        dispatch(popModal());
      };
    }
  }, [accountUserChecklist, dismissVideo, dispatch, showWhatisBrainVideo]);

  const handleCloseVideo = useCallback(() => {
    setDismissVideo(true);
    dispatch(hideHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_BRAIN]));

    dispatch(showHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.GENERATE_DIALOG]));
  }, [dispatch, setDismissVideo]);

  useEffect(() => {
    if (showWhatisBrainVideo && steps?.[1]) {
      const step = steps[1]?.steps[3];
      dispatch(
        pushModal(MODAL_VIDEO, {
          title: t(step?.label),
          url: step.video,
          onClick: () => {
            handleCloseVideo();
            dispatch(popModal());
          },
          onPlayClick: () => {
            markAsComplete(step?.checklistStep);
          },
          onClose: handleCloseVideo,
          primaryButtonText: t('home.checklist.impove'),
        })
      );
      return () => {
        dispatch(popModal());
      };
    }
  }, [
    dispatch,
    handleCloseVideo,
    markAsComplete,
    showWhatisBrainVideo,
    steps,
    t,
  ]);
};

export default useDialogHelper;
