import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useDrop } from 'react-dnd';

import styles from './ReorderPlaceholder.module.scss';

type DragItem = {
  name: string;
  index: number;
  type: string;
} & (
  | {
      nodeId: string;
      actionId?: never;
    }
  | {
      actionId: string;
      nodeId?: never;
    }
);

type PlaceholderProps = {
  label?: string;
  accept: string;
  index?: number;
  onMove: (item: DragItem, index: number) => void;
} & (
  | {
      type: 'condition';
      position: string;
      isCollapsed: boolean;
      nodeId: string;
      actionId?: never;
    }
  | {
      type: 'option';
      position: string;
      actionId: string;
      isCollapsed?: never;
      nodeId?: never;
    }
);

function ReorderPlaceholder({
  label = null,
  accept,
  index = undefined,
  onMove,
  position,
  isCollapsed,
  type,
  nodeId,
  actionId,
}: PlaceholderProps) {
  const dropNode = useCallback(
    (item: DragItem) => {
      onMove(item, index);
    },
    [index, onMove]
  );

  const [{ draggedItem, isOver, canDrop }, drop] = useDrop({
    accept,
    canDrop: (item) => {
      switch (type) {
        case 'condition':
          return item.index !== index && item.nodeId === nodeId;
        case 'option':
          return item.index !== index && item.actionId === actionId;
      }
    },
    drop: dropNode,
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const pos = index < draggedItem?.index ? 'left' : 'right';

  return (
    <div
      ref={drop}
      className={cn({
        [styles.placeholder]: true,
        [styles[`placeholder--${pos}`]]: true,
        [styles['placeholder--collapsed']]: isCollapsed,
        [styles[`placeholder--over`]]: isOver && pos === position,
        [styles[`placeholder--can-drop`]]: canDrop && pos === position,
      })}
    >
      {label && (
        <Typography
          color="var(--text-default-blue)"
          component="p"
          variant="body-medium"
        >
          {label}
        </Typography>
      )}
    </div>
  );
}

export default ReorderPlaceholder;
