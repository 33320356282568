import i18n, { TFunction } from 'i18next';
import moment from 'moment';

import { emailPattern, LENGTH_XS } from '@/util/validator';

import { Broadcast, BroadcastStatus, Subscriber } from './models';
import { userContextVariables } from '../TryIt/components/VariablesSection/constants';

export const SEND_NOW = 'Send now';

export const getVariablesLength = (str: string) => {
  if (!str) {
    return 0;
  }
  const pattern = /\{\{\d+\}\}/g;
  const matches = str.match(pattern);
  const count = matches ? matches.length : 0;
  return count;
};

export const broadcastRules = {
  name: (usedNames?: string[]) => ({
    required: true,
    maxLength: LENGTH_XS,
    validate: (value: string) => {
      if (usedNames?.includes(value.toLowerCase())) {
        return i18n.t('validation.name_already_exists');
      }

      return true;
    },
  }),
  channel: {
    required: true,
  },
};

export const addPlusSignToPhone = (phone: string) => {
  if (phone[0] !== '+') {
    return `+${phone}`;
  }
  return phone;
};

export const extractContentFromContextVariable = (str: string) => {
  const regex = /\{\{\$(.*?)\}\}/;
  const match = str.match(regex);
  return match ? match[1] : null;
};

export const formatOptionsFromText = (text: string) => {
  const content = extractContentFromContextVariable(text);
  if (content) {
    return {
      value: text,
      label: content,
    };
  }
  if (text) {
    return {
      value: text,
      label: text,
    };
  }

  return null;
};

export const getScheduleTime = (day: string, hour: string): number => {
  if (day === SEND_NOW) {
    return moment
      .tz(moment().add(5, 'second'), moment.tz.guess())
      .utc()
      .valueOf();
  }
  if (day && hour) {
    const resultWithLocal = moment.tz(
      `${day} ${hour}`,
      'YYYY-MM-DD hh:mma',
      moment.tz.guess()
    );
    const resultUtc = moment(resultWithLocal).utc().valueOf();

    return resultUtc;
  } else if (day) {
    return moment.tz(day, moment.tz.guess()).startOf('day').utc().valueOf();
  } else if (hour) {
    const today = moment().format('YYYY-MM-DD');
    return moment.tz(`${today} ${hour}`, moment.tz.guess()).utc().valueOf();
  }
  return;
};

const allowedUserFields = userContextVariables.map(
  (variable) => variable.value
);

/**
 * Sets a nested property in an object given an array of keys.
 *
 * @param {Object} obj - The object in which to set the property.
 * @param {string[]} keys - An array of strings representing the keys, in order, for the nested property.
 * @param {*} value - The value to set at the specified nested property.
 */
export const setNestedProperty = (obj, keys, value) => {
  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = value;
    } else {
      acc[key] = acc[key] || {};
    }
    return acc[key];
  }, obj);
};

/**
 * Formats file context data by converting rows of data into a structured object format.
 *
 * @param {string[]} firstRow - An array of strings representing the headers/keys for each column in the data.
 * @param {string[][]} data - A 2D array where each sub-array represents a row of data.
 * @returns {Object} An object containing errorCounter and an array of fileSubscribers.
 */
export const formatFileContext = (
  firstRow: string[],
  data: string[][]
): { fileSubscribers: Partial<Subscriber>[] } => {
  const fileSubscribers: Partial<Subscriber>[] = [];

  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    let hasEmptyValue = false;
    let hasInvalidEmail = false;
    const id = row[0]?.trim();
    const formattedRow = {
      external_id: id ? addPlusSignToPhone(id) : '',
      metadata: { source: 'file' as const },
      context: { user: {} },
    };

    for (let j = 1; j < firstRow.length; j++) {
      const key = firstRow[j];
      const value = row[j]?.trim();
      const keys = key.split('.');
      // Allow user.key if it belongs to user context
      if (allowedUserFields.includes(key)) {
        if (key === 'user.email' && !emailPattern.test(value)) {
          hasInvalidEmail = true;
        }
        formattedRow.context.user[keys[1]] = value;
        //Save user.key in root context if it doesn't belong to user context
      } else if (key.includes('user.')) {
        formattedRow.context[keys[1]] = value;
      } else {
        setNestedProperty(formattedRow.context, keys, value);
      }
      if (!value) {
        hasEmptyValue = true;
      }
    }

    // Remove the user object if it is empty
    if (Object.keys(formattedRow.context.user).length === 0) {
      delete formattedRow.context.user;
    }
    if (
      fileSubscribers.find((s) => s.external_id === formattedRow.external_id)
    ) {
      continue;
    }
    if (hasEmptyValue) {
      fileSubscribers.push({
        ...formattedRow,
        status_code: 'MISSING_VARIABLE',
      });
    } else if (hasInvalidEmail) {
      fileSubscribers.push({
        ...formattedRow,
        status_code: 'INVALID_EMAIL',
      });
    } else {
      fileSubscribers.push(formattedRow);
    }
  }

  return { fileSubscribers };
};
const TWO_MINUTES = 2;
export const isScheduledToBeSentNow = (broadcast: Partial<Broadcast>) => {
  if (!broadcast.broadcast_id || !broadcast?.scheduled_at) {
    return false;
  }

  if (broadcast.status === 'draft') {
    return false;
  }

  const now = moment.utc();
  const date = moment.utc(broadcast.scheduled_at);

  return Math.abs(now.diff(date, 'minutes')) <= TWO_MINUTES;
};

export const calculatePercentage = (numerator, denominator) => {
  if (!denominator || !numerator) {
    return 0;
  }
  return ((numerator / denominator) * 100).toFixed(0);
};

export const showBroadcastBanner = (
  status: BroadcastStatus,
  broadcast: Partial<Broadcast>,
  t: TFunction
) => {
  if (status === 'ready' && broadcast?.scheduled_at) {
    return {
      show: true,
      bannerVariant: 'info',
      bannerTitle: t('broadcasts.banner_scheduled', {
        0: moment(broadcast.scheduled_at).local().format('YYYY-MM-DD h:mma'),
      }),
      bannerSubstitle: t('broadcasts.banner_scheduled_body'),
    };
  }
  if (status === 'sent') {
    return {
      show: true,
      bannerVariant: 'success',
      bannerTitle: t('broadcasts.banner_sent', {
        0: moment(broadcast.updated).local().format('YYYY-MM-DD h:mma'),
      }),
    };
  }
  if (status === 'in_progress') {
    return {
      show: true,
      bannerVariant: 'info',
      bannerTitle: t('broadcasts.banner_in_progress'),
    };
  }
  return {
    show: false,
  };
};

export const flattenKeys = (obj, prefix = '') =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'object' && value !== null) {
      acc.push(...flattenKeys(value, prefixedKey));
    } else {
      acc.push(prefixedKey);
    }
    return acc;
  }, []);

export const getContextVariableOptions = (
  subscribers: Partial<Subscriber>[]
) => {
  if (!subscribers || subscribers.length === 0) {
    return [];
  }
  const allKeys = Array.from(
    new Set(
      subscribers.flatMap((subscriber) => flattenKeys(subscriber.context))
    )
  );
  const options = allKeys.map((key) => ({
    label: key,
    value: `{{$${key}}}`,
    type: 'context_variable',
  }));
  return options;
};
