import ResponseFieldset from '../components/fieldsets/ResponseFieldset/ResponseFieldset';
import ResponseTimeOverview from '../components/fieldsets/ResponseTimeOverview/ResponseTimeOverview';
import Filters from '../components/filters/AccountFilters/AccountFilters';

export const HumanAgentsEffectiveness = () => {
  return (
    <>
      <Filters />
      <ResponseTimeOverview />
      <ResponseFieldset isFirstMessage />
      <ResponseFieldset />
    </>
  );
};
