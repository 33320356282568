import './Tags.scss';

import { ReactElement } from 'react';

import DeleteIcon from '@mui/icons-material/Clear';
import isEmpty from 'lodash/isEmpty';

import Chip from '@/components/atoms/Chip/Chip';
import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import { preventClickThrough } from '@/util/util';

interface TagsProps {
  label?: string;
  customVariant?: 'neutral' | 'highlighted' | 'subdued';
  onClick: (tag: string) => void | undefined;
  icon?: ReactElement;
  tooltip?: string;
  tags?: string[];
  placeholder?: string;
  name: string;
}

function Tags({
  name,
  label,
  tooltip,
  tags = [],
  customVariant = 'highlighted',
  placeholder,
  icon,
  onClick,
}: TagsProps) {
  const handleClick =
    (tag: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      preventClickThrough(e);
      onClick(tag);
    };

  return (
    <div className="tags">
      {label && (
        <label className="tags__label" htmlFor={name}>
          {label}
          <HelpTooltip tooltip={tooltip} />
        </label>
      )}
      {isEmpty(tags) && placeholder ? (
        <p className="placeholder">{placeholder}</p>
      ) : (
        tags.map((t: string) => (
          <Chip
            key={t}
            customVariant={customVariant}
            size="small"
            onDelete={handleClick(t)}
            deleteIcon={<button tabIndex={0}>{icon || <DeleteIcon />}</button>}
            label={t}
          />
        ))
      )}
    </div>
  );
}
export default Tags;
