import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import useBrains from '@/hooks/useBrains';
import { Brain } from '@/models/brain';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { BRAIN_COLLECTION_OPTIMAL_THRESHOLD } from '@/util/constants';
import { brainRules, errorMessage } from '@/util/validator';

import styles from './styles.module.scss';

type Form = {
  confidence_threshold: string;
};

interface Props {
  brain?: Brain;
}

const ConfidenceThresholdFieldset = ({ brain }: Props) => {
  const { t } = useTranslation();
  const brain_id = brain?.brain_id;

  const { updateBrain, updateStatus } = useBrains(brain_id);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<Form>({ mode: 'onChange' });

  const onSubmit = useCallback<(form: Form) => void>(
    (form) => {
      const parsedThreshold = parseFloat(form?.confidence_threshold);
      updateBrain({
        confidence_threshold: parsedThreshold,
        brain_id,
      });
    },
    [brain_id, updateBrain]
  );

  useEffect(() => {
    if (brain_id) {
      reset({
        confidence_threshold: brain?.confidence_threshold?.toString(),
      });
    }
  }, [brain?.confidence_threshold, brain_id, reset]);

  return (
    <div className={styles.brain_settings_fields}>
      <FormFieldset
        title={t('brains.confidence_title')}
        subtitle={t('brains.confidence_subtitle')}
        primaryButton={{
          children: t('common.save'),
          disabled: !isDirty,
          isLoading: updateStatus === 'pending',
        }}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={!brain}
      >
        <Input
          name="confidence_threshold"
          label={t('brains.threshold')}
          register={register(
            'confidence_threshold',
            brainRules.confidenceThreshold
          )}
          errorMessage={errorMessage({
            field: errors.confidence_threshold,
            maxValue: 1,
            minValue: 0,
          })}
          placeholder=""
          size="small"
          defaultValue={brain?.confidence_threshold}
          type="number"
          step="0.01"
          readOnly={!canWrite}
        />
        {brain?.collection_id && (
          <Banner relativePosition>
            {t('collections.brain_settings', {
              0: BRAIN_COLLECTION_OPTIMAL_THRESHOLD,
            })}
          </Banner>
        )}
      </FormFieldset>
    </div>
  );
};
export default ConfidenceThresholdFieldset;
