import Tooltip from '@mui/material/Tooltip/Tooltip';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Table from '@/components/atoms/Table/Table';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import { convertTimestampToDate } from '@/util/analytics';
import { numberFormatter } from '@/util/util';

import useMostUsedDesks from '../../../hooks/useMostUsedDesks';

export const sortColumns = (row1, row2, columnName) =>
  row1.values[columnName] > row2.values[columnName] ? 1 : -1;

const getColumns = (t) => [
  {
    Header: t('analytics.env_name'),
    accessor: 'name',
  },
  {
    Header: t('common.conversations'),
    accessor: 'num_sessions',
    sortType: sortColumns,
    Cell: ({ value }) => {
      const formattedValue = numberFormatter(value);
      return (
        <Tooltip arrow title={formattedValue !== value ? value : ''}>
          <span>{formattedValue}</span>
        </Tooltip>
      );
    },
  },
  {
    Header: t('common.messages'),
    accessor: 'num_requests',
    sortType: sortColumns,
    Cell: ({ value }) => {
      const formattedValue = numberFormatter(value);
      return (
        <Tooltip arrow title={formattedValue !== value ? value : ''}>
          <span>{formattedValue}</span>
        </Tooltip>
      );
    },
  },
  {
    Header: t('common.customers'),
    accessor: 'num_users',
    sortType: sortColumns,
    Cell: ({ value }) => {
      const formattedValue = numberFormatter(value);
      return (
        <Tooltip arrow title={formattedValue !== value ? value : ''}>
          <span>{formattedValue}</span>
        </Tooltip>
      );
    },
  },
  {
    Header: t('common.last_used'),
    accessor: 'last_used',
    sortType: sortColumns,
    Cell: ({ value }) => {
      return (
        <Tooltip
          arrow
          title={moment
            .utc(value * 1000)
            .local()
            .format('YYYY-MM-DD HH:mm:ss')}
        >
          <span key={value}>{value ? convertTimestampToDate(value) : '-'}</span>
        </Tooltip>
      );
    },
  },
];

const MostUsedDeskFieldset = () => {
  const { t } = useTranslation();
  const { isLoading, data: desksData, error, refetch } = useMostUsedDesks();
  const title = t('analytics.most_environments');

  const handleReload = () => {
    refetch();
  };

  return (
    <AnalyticsFieldset
      fullWidth
      title={title}
      hasFooter={false}
      isEmpty={!desksData && !isLoading}
      isLoading={isLoading}
      error={error}
      onReload={handleReload}
      data={desksData}
    >
      {desksData && (
        <Table
          data={desksData}
          columns={getColumns(t)}
          sortable
          filterable={desksData.length > 10}
          sortBy="last_used"
        />
      )}
    </AnalyticsFieldset>
  );
};

export default MostUsedDeskFieldset;
