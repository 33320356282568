import React, { memo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import { useAccount } from '@/hooks/useAccount';
import useCollections from '@/hooks/useCollections';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';

import { USAGE_PERCENTAGE_THRESHOLD } from '../Collections/util';
import { getFragmentsPercentage } from '../Datasource/utils';

const BANNER_NEUTRAL_PERCENTAGE = 60;
const BANNER_WARNING_PERCENTAGE = 98;

export const FragmentBanner = memo(() => {
  const navigate = useNavigate();
  const { account, accountStatus } = useAccount();
  const { collectionId } = useParams();
  const slug = useSelector(selectAccountSlug);
  const { t } = useTranslation();

  const { collection, getStatus, totalFragments } =
    useCollections(collectionId);

  const canWriteBilling = useSelector((state: RootState) =>
    getPermissions(state, 'billing', actions.WRITE)
  );
  const fragmentsPercentage = getFragmentsPercentage(
    totalFragments,
    account?.max_fragments
  );

  const getPagesNumber = () => {
    return Math.round(account?.max_fragments / 2);
  };

  const isLoading = getStatus === 'pending' || accountStatus === 'pending';

  const variant =
    fragmentsPercentage < BANNER_NEUTRAL_PERCENTAGE
      ? 'neutral'
      : fragmentsPercentage < BANNER_WARNING_PERCENTAGE
        ? 'warning'
        : 'critical';

  const showCollectionPercentage =
    getFragmentsPercentage(collection?.fragment_count, account?.max_fragments) >
    USAGE_PERCENTAGE_THRESHOLD;

  const shouldShowUpgradeButton =
    fragmentsPercentage > BANNER_WARNING_PERCENTAGE && canWriteBilling;

  return (
    <Banner
      variant={variant}
      relativePosition
      primaryButton={
        shouldShowUpgradeButton
          ? {
              onClick: () => {
                navigate(`/${slug}/account/billing`, {
                  state: { shouldTriggerAction: Date.now() },
                });
              },
              label: t('billing.upgrade'),
            }
          : null
      }
    >
      <Typography variant="body-medium">
        {t('limits.collection_limit', {
          0: getFragmentsPercentage(totalFragments, account?.max_fragments),
        })}
      </Typography>
      {isLoading ? (
        <>
          <Skeleton width={250} height={120} />
        </>
      ) : (
        <Typography component="p">
          <>
            <Trans
              i18nKey="limits.fragments"
              values={{
                0: account?.max_fragments,
                1: getPagesNumber(),
              }}
            />
            {showCollectionPercentage && (
              <>
                {' '}
                <Trans
                  i18nKey="limits.collection_heavy"
                  values={{
                    0: getFragmentsPercentage(
                      collection?.fragment_count,
                      account?.max_fragments
                    ),
                  }}
                />
              </>
            )}
          </>
        </Typography>
      )}
    </Banner>
  );
});

FragmentBanner.displayName = 'FragmentBanner';
