import { useCallback, useMemo } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { collectionsEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import {
  Collection,
  Collections,
  Datasource,
  DatasourceType,
  PartialCollection,
} from '@/models/collections';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountId } from '@/redux/session/selectors';

import { useAccount } from './useAccount';
import useDatasources from './useDatasources';
import useFeatureFlag from './useFeatureFlag';

interface DeleteProps {
  collection_id: string;
  force: boolean;
}

export const API = Object.freeze({
  listCollections: async (): Promise<Collections> =>
    callGet(endpoints.collections),

  getCollection: async (collectionId: string): Promise<Collection> =>
    callGet(endpoints.collection(collectionId)),

  createCollection: async (
    newCollection: Partial<Collection>
  ): Promise<Collection> => callPost(endpoints.collections, newCollection),

  updateCollection: async ({
    collection_id,
    ...collection
  }: PartialCollection): Promise<Collection> =>
    callPut(endpoints.collection(collection_id), collection),

  deleteCollection: async (
    collectionId: string,
    force?: boolean
  ): Promise<Collection> => {
    const queryParams = force ? `?force=${force}` : '';
    return callDelete(`${endpoints.collection(collectionId)}${queryParams}`);
  },
});

export const onCollectionUpdated = (
  queryClient: QueryClient,
  collection: Collection
) => {
  queryClient.setQueryData<Collection>(
    [endpoints.collection(collection.collection_id)],
    (prev: Collection) => ({ ...prev, ...collection })
  );
  const queryKey = [endpoints.collections, collection.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Collections>(queryKey, (prev: Collections) => ({
      collections: (prev?.collections || []).map((item) =>
        item.collection_id === collection.collection_id
          ? { ...item, ...collection }
          : item
      ),
    }));
  }
};

export const onCollectionCreated = (
  queryClient: QueryClient,
  collection: Collection
) => {
  const queryKey = [endpoints.collections, collection.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Collections>(queryKey, (prev) => ({
      collections: [
        collection,
        ...(prev?.collections || []).filter(
          (acc) => acc.collection_id !== collection.collection_id
        ),
      ],
    }));
  }
};

export const onCollectionRemoved = (
  queryClient: QueryClient,
  account_id: string,
  collection_id: string
) => {
  const queryKey = [endpoints.collections, account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Collections>(queryKey, (prev: Collections) => ({
      collections: (prev?.collections || []).filter(
        (acc) => acc.collection_id !== collection_id
      ),
    }));
  }
  queryClient.removeQueries({
    queryKey: [endpoints.collection(collection_id)],
  });
};

const useCollections = (collectionId?: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const accountId = useSelector(selectAccountId);
  const { account } = useAccount();
  const { listDatasources } = useDatasources(undefined);

  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.READ)
  );
  const { enableLimits } = useFeatureFlag();

  const {
    data: collections,
    status: listStatus,
    isLoading: isCollectionsLoading,
  } = useQuery<Collections, Error>({
    queryKey: [endpoints.collections, accountId],
    queryFn: () => API.listCollections(),
    enabled: !!accountId && canRead,
  });

  const { data: collection, status: getStatus } = useQuery<Collection, Error>({
    queryKey: [endpoints.collection(collectionId)],
    queryFn: () => API.getCollection(collectionId),
    enabled: !!collectionId && canRead,
  });

  const { mutate: updateCollection, status: updateStatus } = useMutation<
    Collection,
    Error,
    PartialCollection
  >({
    mutationFn: API.updateCollection,
    onSuccess: (resp) => {
      onCollectionUpdated(queryClient, resp);
      dispatch(
        addTemporalToast('success', t('collections.updated', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: createCollection, status: createStatus } = useMutation<
    Collection,
    Error,
    Partial<Collection>
  >({
    mutationFn: API.createCollection,
    onSuccess: (resp) => {
      onCollectionCreated(queryClient, resp);
      dispatch(
        addTemporalToast('success', t('collections.created', { 0: resp?.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const {
    mutate: deleteCollection,
    mutateAsync: deleteCollectionAsync,
    status: deleteStatus,
  } = useMutation<Collection, Error, DeleteProps>({
    mutationFn: ({ collection_id, force }) =>
      API.deleteCollection(collection_id, force),
    onSuccess: (resp) => {
      onCollectionRemoved(queryClient, accountId, resp.collection_id);
      dispatch(addTemporalToast('success', t('collections.deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const collectionsByLanguage = useCallback(
    (language: string) => {
      return collections?.collections?.filter((c) => c.language === language);
    },
    [collections?.collections]
  );

  const collectionNames = useMemo(
    () => collections?.collections?.map((c) => c.name.toLowerCase()),
    [collections?.collections]
  );

  const listAllDatasources = useCallback(
    async (type: DatasourceType): Promise<Datasource[]> => {
      const cols = collections?.collections || [];
      const responses = await Promise.all(
        cols.map((collection) => listDatasources(collection.collection_id))
      );

      const datasources = responses
        .map((r) => r?.datasources)
        .flat()
        .filter((d) => d.type === type);

      return datasources;
    },
    [collections?.collections, listDatasources]
  );
  const totalFragments = useMemo(
    () =>
      collections?.collections?.reduce(
        (acc, col) => acc + col.fragment_count,
        0
      ),
    [collections?.collections]
  );
  return {
    collections: collections?.collections,
    collection,
    maxCollections: account?.max_collections,
    maxCollectionsReached: enableLimits
      ? collections?.collections?.length >= account?.max_collections
      : false,
    totalFragments,
    totalFragmentsReached:
      totalFragments > account?.max_fragments && enableLimits,
    getStatus,
    isCollectionsLoading,
    listStatus,
    createStatus,
    deleteStatus,
    updateStatus,
    collectionNames,
    createCollection,
    deleteCollection,
    deleteCollectionAsync,
    updateCollection,
    collectionsByLanguage,
    listAllDatasources,
  };
};

export default useCollections;
