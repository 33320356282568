import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useAccount } from '@/hooks/useAccount';
import useDatasources from '@/hooks/useDatasources';
import useDocuments from '@/hooks/useDocuments';
import { DatasourceType } from '@/models/collections';

import { datasourceIconByType } from '../../../Messages/MessageNode/MessageNode';

import styles from '../DetailsSection.module.scss';

type DocumentLineProps = {
  collectionId: string;
  datasourceId: string;
  documentId: string;
};

export const DocumentLine = ({
  collectionId,
  datasourceId,
  documentId,
}: DocumentLineProps) => {
  const { t } = useTranslation();
  const { slug } = useAccount();
  const { datasource } = useDatasources(collectionId, datasourceId);
  const { document } = useDocuments(collectionId, datasourceId, documentId);

  if (!collectionId || !datasourceId || !documentId) return null;

  const documentLink =
    datasource?.type === DatasourceType.FILES
      ? `/${slug}/collections/${collectionId}/datasources/${datasourceId}?documentId=${documentId}`
      : document?.external_url;

  return (
    <Typography
      className={styles.retrievedDocuments__item}
      variant="label2-regular"
      color="var(--text-default-gray)"
      component="li"
    >
      <NavLink
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        to={documentLink}
      >
        {document?.name}
      </NavLink>
      {t('try_it.from_datasource')}{' '}
      <NavLink
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        to={`/${slug}/collections/${collectionId}/datasources/${datasourceId}`}
      >
        {datasourceIconByType[datasource?.type]}
        {datasource?.name}
      </NavLink>
    </Typography>
  );
};
