import { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Header from '@/components/organisms/Header/Header';
import useDesks from '@/hooks/useDesks';
import { setDeskId } from '@/redux/session/actions';
import { selectDeskId } from '@/redux/session/selectors';

const ChatsHeader = () => {
  const { t } = useTranslation();
  const { desks } = useDesks();
  const dispatch = useDispatch();
  const deskId = useSelector(selectDeskId);

  useEffect(() => {
    if (!deskId && !isEmpty(desks)) {
      dispatch(setDeskId(desks[0]?.desk_id));
    }
  }, [deskId, desks, dispatch]);

  return (
    <Header>
      <Header.Body>
        <Header.Title
          title={
            <Typography
              variant="heading1-medium"
              mr="var(--space-24)"
              color="var(--text-default-gray)"
            >
              {t('common.chats')}
            </Typography>
          }
          withDesk
        />
      </Header.Body>
    </Header>
  );
};

export default ChatsHeader;
