import { useEffect, memo } from 'react';

import { useSelector } from 'react-redux';

import useDesktopNotifications from '@/hooks/useDesktopNotifications';
import { selectDeskId } from '@/redux/session/selectors';

import { Nav } from './LeftSidebar/Nav';
import { MessageList } from './MessagesList/MessageList';
import { useRtmConversationsNew } from '../hooks/useRtmConversationsNew';

export const SubNavConversationNew = memo(() => {
  const { allowNotifications } = useDesktopNotifications();
  const deskId = useSelector(selectDeskId);

  useRtmConversationsNew(deskId);

  useEffect(() => {
    allowNotifications();
  }, [allowNotifications]);

  return (
    <>
      <Nav />
      <MessageList />
    </>
  );
});

SubNavConversationNew.displayName = 'SubNavConversationNew';
