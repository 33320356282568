import { useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import Select from '@/components/atoms/Select/Select';
import Bar from '@/components/organisms/Analytics/Bar/Bar';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';

import { ResponseRatingType } from '../../../hooks/useRatingCount';
import useRatingsInterval from '../../../hooks/useRatingsInterval';
import { getOptions } from '../RatingCount/RatingCount';

import styles from '../ResponseFieldset/ResponseFieldset.module.scss';
interface Props {
  isMoreThan3?: boolean;
}
const RatingByDay = ({ isMoreThan3 }: Props) => {
  const { t } = useTranslation();
  const title = `${
    isMoreThan3
      ? `${t('analytics.percent_more3')}`
      : t('analytics.rating_by_day')
  }`;

  const [optionValue, setOptionValue] = useState<ResponseRatingType | 'growth'>(
    getOptions(t)[0]?.value
  );

  const ratings = useRatingsInterval({
    type: isMoreThan3 ? 'growth' : optionValue,
  });

  const handleReload = () => {
    ratings.refetchCurrent();
  };
  const handleOptionClick = useCallback((e) => {
    setOptionValue(e.target.value);
  }, []);

  const getisEmpty = useCallback(() => {
    if (!isMoreThan3) {
      return !ratings?.currentError && !ratings?.data && !ratings?.isLoading;
    }
    return (
      (!ratings?.currentError && !ratings?.data && !ratings?.isLoading) ||
      (ratings?.data?.series.length === 1 &&
        ratings?.data?.series[0]?.primary === 0)
    );
  }, [isMoreThan3, ratings?.currentError, ratings?.data, ratings?.isLoading]);

  return (
    <AnalyticsFieldset
      fullWidth
      title={title}
      hasFooter={false}
      error={ratings.currentError}
      onReload={handleReload}
      isEmpty={getisEmpty()}
      isLoading={ratings?.isLoading}
      data={ratings.data?.series}
    >
      <div className={styles.barContainer}>
        <Bar
          axisY={`${t('analytics.rating')}  ${isMoreThan3 ? '%' : ''}`}
          axisX={t('common.date')}
          keys={['primary']}
          indexBy="date"
          data={ratings.data?.series}
        />
        {!isMoreThan3 && (
          <div className={styles.checkbox}>
            <Select
              options={getOptions(t)}
              value={optionValue}
              onChange={handleOptionClick}
              size="small"
            />
          </div>
        )}
      </div>
    </AnalyticsFieldset>
  );
};

export default RatingByDay;
