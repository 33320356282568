import i18n from 'i18next';
import moment from 'moment';

import { getIntegrationByType } from '@/components/organisms/Integrations';
import { Channel } from '@/modules/analytics/models';

export type Bar = {
  date: string;
  primary: number;
  secondary: number;
  difference?: number;
};

export type GeneralRow = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const TEN_DAYS = 10;
const FOURTY_DAYS = 40;
const THREE_DAYS = 3;
const SEVEN_DAYS = 7;

interface Props {
  currentTotal: GeneralRow[];
  previousTotal?: GeneralRow[];
  shouldComputeDifference?: boolean;
  type?: string;
}

export const prepareBar = ({
  currentTotal,
  previousTotal,
  shouldComputeDifference,
  type = 'counts',
}: Props) => {
  let newBar: Bar[] = [];

  let currentTotalCounts = 0;
  let previousTotalCounts = 0;

  const firstTypeKey = 'primary';
  const secondTypeKey = 'secondary';
  const keyToUse = type;

  if (currentTotal.length < TEN_DAYS) {
    for (let i = 0; i < currentTotal.length; i += 1) {
      const currentValue = shouldComputeDifference
        ? currentTotal[i][keyToUse] - previousTotal[i][keyToUse]
        : currentTotal[i][keyToUse];

      const previousValue = previousTotal ? previousTotal[i][keyToUse] : 0;

      newBar = [
        ...newBar,
        {
          date: moment(currentTotal[i].date).format('D MMM'),
          [firstTypeKey]: currentValue,
          [secondTypeKey]: previousValue,
          difference: 1,
        },
      ];
      currentTotalCounts += currentValue;
      if (previousTotal) {
        previousTotalCounts += previousValue;
      }
    }
    return { newBar, currentTotalCounts, previousTotalCounts };
  } else {
    const separator =
      currentTotal.length < FOURTY_DAYS ? THREE_DAYS : SEVEN_DAYS;

    let currentCounts = 0;
    let previousCount = 0;

    let currentTotalSum = 0;
    let previousTotalSum = 0;

    for (let i = 0; i < currentTotal.length; i += 1) {
      const currentValue = shouldComputeDifference
        ? currentTotal[i][keyToUse] - previousTotal[i][keyToUse]
        : currentTotal[i][keyToUse];

      const previousValue = previousTotal ? previousTotal[i][keyToUse] : 0;

      currentTotalSum += currentValue;

      previousTotalSum += previousValue;

      currentCounts += currentValue;
      previousCount += previousValue;

      const isFirstGroup = i === separator - 1;
      const skipFirst =
        i % separator === separator - 1 && i !== 0 && i !== separator;
      if (isFirstGroup || skipFirst) {
        let groupStartDate = '';
        let groupEndDate = '';

        groupEndDate = moment(currentTotal[i].date).format('D MMM');
        groupStartDate =
          i === separator
            ? moment(currentTotal[i - separator].date).format('D MMM')
            : moment(currentTotal[i - separator + 1].date).format('D MMM');

        newBar = [
          ...newBar,
          {
            date: `${groupStartDate} - ${groupEndDate}`,
            [firstTypeKey]: currentCounts,
            [secondTypeKey]: previousCount,
            difference:
              moment(currentTotal[i].date).diff(
                i === separator
                  ? moment(currentTotal[i - separator].date)
                  : moment(currentTotal[i - separator + 1].date),
                'days'
              ) + 1,
          } as unknown as Bar,
        ];

        //If there is not another full group after that
        if (!currentTotal[i + separator]) {
          if (currentTotal[i + 1]) {
            groupStartDate = moment(currentTotal[i + 1].date).format('D MMM');
            groupEndDate = moment(
              currentTotal[currentTotal.length - 1].date
            ).format('D MMM');

            //If the next item is the last item
            if (i + 1 === currentTotal.length - 1) {
              const currentValue = shouldComputeDifference
                ? currentTotal[i + 1][keyToUse] - previousTotal[i + 1][keyToUse]
                : currentTotal[i + 1][keyToUse];

              const previousValue = previousTotal
                ? previousTotal[i + 1][keyToUse]
                : 0;

              newBar = [
                ...newBar,
                {
                  date: groupStartDate,
                  [firstTypeKey]: currentValue,
                  [secondTypeKey]: previousValue,
                  difference: 1,
                },
              ];
            } else {
              currentCounts = 0;
              previousCount = 0;
              let difference = 0;

              for (let j = i + 1; j <= currentTotal.length - 1; j += 1) {
                const currentValue = shouldComputeDifference
                  ? currentTotal[j][keyToUse] - previousTotal[j][keyToUse]
                  : currentTotal[j][keyToUse];

                const previousValue = previousTotal
                  ? previousTotal[j][keyToUse]
                  : 0;

                currentCounts += currentValue;

                previousCount += previousValue;
                difference += 1;
              }
              newBar = [
                ...newBar,
                {
                  date: `${groupStartDate} - ${groupEndDate}`,
                  [firstTypeKey]: currentCounts,
                  [secondTypeKey]: previousCount,
                  difference,
                } as unknown as Bar,
              ];
            }
          }
        }
        currentCounts = 0;
        previousCount = 0;
      }
    }
    return {
      newBar,
      currentTotalCounts: currentTotalSum,
      previousTotalCounts: previousTotalSum,
    };
  }
};

export const formatLogs = ({
  channel,
  start_time,
  end_time,
  expired_time,
}: {
  channel: Channel;
  start_time: string;
  end_time: string;
  expired_time?: string;
}) => {
  const Icon = getIntegrationByType(channel?.toLowerCase())?.Icon;
  const duration = moment(end_time).diff(moment(start_time)) / 60000;

  let sessionDuration: number;
  if (expired_time) {
    sessionDuration = moment(expired_time).diff(start_time) / 60000;
  }

  const formattedTime = moment
    .utc(start_time)
    .local()
    .format('YYYY-MM-DD HH:mm:ss');

  return {
    Icon,
    duration: duration || '0 sec',
    sessionDuration,
    formattedTime,
  };
};
export const ONE_DAY = '1 day';

export const getDateRangeText = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return '';
  }
  const endDateIsToday = moment(endDate).diff(moment(), 'days') === 0;

  if (endDateIsToday) {
    const startDateIsToday = moment(startDate).diff(moment(), 'days') === 0;
    if (startDateIsToday) {
      return i18n.t('analytics.periods.today');
    }

    const days = moment(endDate).diff(moment(startDate), 'days');
    return i18n.t('analytics.last_days', { 0: days + 1 });
  }

  const dateIsYesterday =
    moment().diff(moment(startDate), 'days') === 1 &&
    moment().diff(moment(endDate), 'days') === 1;
  if (dateIsYesterday) {
    return i18n.t('analytics.periods.yesterday');
  }

  return `${moment(startDate).format('MMM DD, YYYY')} - ${moment(
    endDate
  ).format('MMM DD, YYYY')}`;
};

export const convertTimestampToDate = (value: number) => {
  const date = moment(value * 1000).format('YYYY-MM-DD HH:mm:ss');
  return moment.utc(date).local().fromNow();
};

export const analyticsKeyToValue = {
  deskIds: 'desk_ids',
  brainIds: 'brain_ids',
  agentIds: 'agent_ids',
  isTest: 'is_preview',
  minNumUserMessages: 'min_messages',
  channels: 'channels',
};

export const analyticsValueToKey = {
  desk_ids: 'deskIds',
  brain_ids: 'brainIds',
  agent_ids: 'agentIds',
  is_preview: 'isTest',
  min_messages: 'minNumUserMessages',
  channels: 'channels',
};

export const getGrowthArrowColor = (value: number): string => {
  if (value > 0) {
    return 'var(--icon-default-success)';
  }
  if (value < 0) {
    return 'var(--icon-default-error)';
  }
  return 'var(--icon-default-black)';
};

/**
 *Converts the given start and end date strings to local time, then to UTC, and returns the date range in UTC format.
 *
 * @param {string} start - The start date as a string. If null or undefined, startDate will be null.
 * @param {string} end - The end date as a string. If null or undefined, endDate will be null.
 * @returns {{ startDate: string | null, endDate: string | null }} - An object containing startDate and endDate in UTC format. Both values will be null if the corresponding input is null or undefined.
 */
export const getAnalyticsRange = (start, end) => {
  const startDate = start ? moment(start).startOf('day').utc().format() : null;
  const endDate = end ? moment(end).endOf('day').utc().format() : null;
  return { startDate, endDate };
};
