import { useCallback } from 'react';

import { PaymentMethod } from '@stripe/stripe-js';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Stripe from 'stripe';
import type { PartialDeep } from 'type-fest';

import {
  accountsEndpoints,
  billingEndpoints as endpoints,
} from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { useAccount } from '@/hooks/useAccount';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import {
  InvoicesResponse,
  PaymentMethodsResponse,
  Plan,
  PlansResponse,
  Subscription,
} from '@/modules/billing/model';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { PLAN_IDS } from '@/util/constants';

import { useBillingState } from './useBillingState';

const API = Object.freeze({
  listPlans: () => callGet(endpoints.plans),
  getSubscription: () => callGet(endpoints.subscription),
  getCustomer: () => callGet(endpoints.customer),
  listPaymentMethods: () => callGet(endpoints.paymentMethods),
  getTaxId: () => callGet(endpoints.taxId),
  listPastInvoices: () => callGet(endpoints.pastInvoices),
  listUpcomingInvoices: () => callGet(endpoints.upcomingInvoices),
  updateCustomer: async (
    updates: PartialDeep<Stripe.Customer>
  ): Promise<Stripe.Customer> => callPut(endpoints.customer, updates),
  updateTaxId: async (updates: Partial<TaxRegion>): Promise<TaxRegion> =>
    callPut(endpoints.taxId, updates),
  attachPaymentMethod: async (
    paymentMethod: Partial<PaymentMethod>
  ): Promise<PaymentMethod> =>
    callPost(endpoints.attachPaymentMethod(paymentMethod.id), {}),
  detachPaymentMethod: async (pmId: string): Promise<PaymentMethod> =>
    callPost(endpoints.detachPaymentMethod(pmId), {}),
  createSubscription: async (planId: string): Promise<Subscription> =>
    callPost(endpoints.subscription, { plan_id: planId }),
  deleteSubscription: async (): Promise<Subscription> =>
    callDelete(endpoints.subscription),
  redeemPromoCode: async (code: string): Promise<Stripe.Coupon> =>
    callPut(endpoints.redeemPromoCode, { code }),
});

/**
 * Extend Stripe TaxId to include the region+vat: eu_vat|Greece|GR
 */
interface TaxRegion extends Stripe.TaxId {
  region: string | undefined;
}

export const enterprisePlan = {
  name: 'Enterprise',
  id: PLAN_IDS.ENTERPRISE,
  plan_id: 'enterprise',
} as unknown as Plan;

export const useBilling = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const accountSlug = useSelector(selectAccountSlug);
  const billingState = useBillingState();
  const { account, accountRole } = useAccount();

  const canWriteBilling = useSelector((state: RootState) =>
    getPermissions(state, 'billing', actions.WRITE)
  );

  const queryConfig = {
    enabled: !!accountSlug && canWriteBilling,
  };

  const { data: plans } = useQuery<PlansResponse>({
    queryKey: [endpoints.plans, accountSlug],
    queryFn: () => API.listPlans(),
    ...queryConfig,
  });
  const { data: currentSubscription } = useQuery<Subscription>({
    queryKey: [endpoints.subscription, accountSlug],
    queryFn: () => API.getSubscription(),
    ...queryConfig,
  });
  const { data: customer } = useQuery<Stripe.Customer>({
    queryKey: [endpoints.customer, accountSlug],
    queryFn: () => API.getCustomer(),
    ...queryConfig,
  });
  const { data: taxId } = useQuery<TaxRegion>({
    queryKey: [endpoints.taxId, accountSlug],
    queryFn: () => API.getTaxId(),
    ...queryConfig,
  });
  const { data: paymentMethods } = useQuery<PaymentMethodsResponse>({
    queryKey: [endpoints.paymentMethods, accountSlug],
    queryFn: () => API.listPaymentMethods(),
    ...queryConfig,
  });

  const { data: pastInvoices } = useQuery<InvoicesResponse>({
    queryKey: [endpoints.pastInvoices, accountSlug],
    queryFn: () => API.listPastInvoices(),
    enabled: !!account?.stripe_subscription_id && canWriteBilling,
  });

  const { data: upcomingInvoices } = useQuery<InvoicesResponse>({
    queryKey: [endpoints.upcomingInvoices, accountSlug],
    queryFn: () => API.listUpcomingInvoices(),
    enabled: !!account?.stripe_subscription_id && canWriteBilling,
  });

  const {
    mutate: updateCustomer,
    mutateAsync: updateCustomerAsync,
    status: updateCustomerStatus,
  } = useMutation<Stripe.Customer, Error, PartialDeep<Stripe.Customer>>({
    mutationFn: (params) => API.updateCustomer(params),
    onSuccess: (resp) => {
      queryClient.setQueryData<Stripe.Customer>(
        [endpoints.customer, accountSlug],
        (prev) => ({ ...prev, ...resp })
      );
      dispatch(addTemporalToast('success', t('billing.customer_updated')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: updateTaxId, status: updateTaxIdStatus } = useMutation<
    TaxRegion,
    Error,
    Partial<TaxRegion>
  >({
    mutationFn: (params) => API.updateTaxId(params),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [endpoints.taxId, accountSlug],
      });
      dispatch(addTemporalToast('success', t('billing.tax_id_updated')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: attachPaymentMethod, mutateAsync: attachPaymentMethodAsync } =
    useMutation<PaymentMethod, Error, Partial<PaymentMethod>>({
      mutationFn: (params) => API.attachPaymentMethod(params),
      onSuccess: async () => {
        await queryClient.refetchQueries({
          queryKey: [endpoints.paymentMethods, accountSlug],
        });
      },
      onError: (error) => {
        dispatch(addErrorTemporalToast(error));
      },
    });

  const { mutate: detachPaymentMethod, mutateAsync: detachPaymentMethodAsync } =
    useMutation<PaymentMethod, Error, string>({
      mutationFn: (pid) => API.detachPaymentMethod(pid),
      onSuccess: async (_, pid) => {
        queryClient.setQueryData<PaymentMethodsResponse>(
          [endpoints.paymentMethods, accountSlug],
          (prev: PaymentMethodsResponse) => ({
            payment_methods: prev?.payment_methods.filter(
              (item) => item.id !== pid
            ),
          })
        );
        await queryClient.refetchQueries({
          queryKey: [endpoints.paymentMethods, accountSlug],
        });
      },
      onError: (error) => {
        dispatch(addErrorTemporalToast(error));
      },
    });

  const { mutate: createSubscription, mutateAsync: createSubscriptionAsync } =
    useMutation<Subscription, Error, string>({
      mutationFn: (planId) => API.createSubscription(planId),
      onSuccess: async (resp) => {
        queryClient.setQueryData<Subscription>(
          [endpoints.subscription, accountSlug],
          resp
        );
        queryClient.invalidateQueries({
          queryKey: [endpoints.billingState, accountSlug],
        });

        queryClient.invalidateQueries({
          queryKey: [accountsEndpoints.account, accountSlug],
        });
        queryClient.invalidateQueries({
          queryKey: [endpoints.upcomingInvoices, accountSlug],
        });
      },
      onError: (error) => {
        dispatch(addErrorTemporalToast(error));
      },
    });

  const { mutate: redeemPromoCode, status: reedeemPromoCodeStatus } =
    useMutation<Stripe.Coupon, Error, string>({
      mutationFn: (code) => API.redeemPromoCode(code),
      onSuccess: async () => {
        queryClient.invalidateQueries({
          queryKey: [endpoints.billingState, accountSlug],
        });
        queryClient.invalidateQueries({
          queryKey: [accountsEndpoints.account, accountSlug],
        });
        queryClient.invalidateQueries({
          queryKey: [endpoints.upcomingInvoices, accountSlug],
        });
        dispatch(addTemporalToast('success', t('billing.coupon_success')));
      },
    });

  const { mutate: deleteSubscription, mutateAsync: deleteSubscriptionAsync } =
    useMutation<Subscription, Error>({
      mutationFn: () => API.deleteSubscription(),
      onSuccess: async () => {
        queryClient.setQueryData<Subscription>(
          [endpoints.subscription, accountSlug],
          (prev) => ({
            ...(prev as Subscription),
            status: 'canceled',
          })
        );
        queryClient.invalidateQueries({
          queryKey: [endpoints.billingState, accountSlug],
        });
      },
      onError: (error) => {
        dispatch(addErrorTemporalToast(error));
      },
    });

  const updateSubscription = useCallback(
    async (planId: string) => {
      try {
        await deleteSubscriptionAsync();
        await createSubscriptionAsync(planId);
        dispatch(addTemporalToast('success', t('billing.plan_changed')));
      } catch (error) {
        dispatch(addErrorTemporalToast(error));
      }
    },
    [createSubscriptionAsync, deleteSubscriptionAsync, dispatch, t]
  );

  const showUpgradeButton =
    billingState?.plan_id === PLAN_IDS.TRIAL && accountRole === 'owner';

  const plansWithEnterprise = [...(plans?.plans || []), enterprisePlan];

  return {
    account,
    customer,
    taxId,
    paymentMethods: paymentMethods?.payment_methods,
    currentSubscription,
    plans: plansWithEnterprise,
    pastInvoices: pastInvoices?.invoices || [],
    upcomingInvoice: upcomingInvoices?.invoices?.[0] || undefined,
    billingState,
    updateCustomer,
    updateCustomerAsync,
    updateCustomerStatus,
    updateTaxId,
    updateTaxIdStatus,
    attachPaymentMethod,
    attachPaymentMethodAsync,
    detachPaymentMethod,
    detachPaymentMethodAsync,
    createSubscription,
    createSubscriptionAsync,
    deleteSubscription,
    deleteSubscriptionAsync,
    updateSubscription,
    showUpgradeButton,
    redeemPromoCode,
    reedeemPromoCodeStatus,
  };
};
