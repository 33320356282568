interface Props {
  text: string;
}

import Typography from '@mui/material/Typography';

import styles from './Divider.module.scss';

export const Divider = ({ text }: Props) => {
  return (
    <div className={styles.lineWrapper}>
      <div className={styles.line}>
        <span className={styles.bar}></span>
        <Typography className={styles.text} variant="label2-medium">
          {text}
        </Typography>
        <span className={styles.bar}></span>
      </div>
    </div>
  );
};
