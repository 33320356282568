import { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography/Typography';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FieldsetSlider from '@/components/atoms/Fieldset/FieldsetSlider';
import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import Link from '@/components/atoms/Link/Link';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import Switch from '@/components/atoms/Switch/Switch';
import useBrains from '@/hooks/useBrains';
import useDialogs from '@/hooks/useDialogs';
import { AutopilotProfile, Brain } from '@/models/brain';
import { removeEmptyValues } from '@/util/util';
import { aiProfile } from '@/util/validator';

import styles from './styles.module.scss';

interface Props {
  brain?: Brain;
}

export const AiProfileFieldset = ({ brain }: Props) => {
  const { t } = useTranslation();
  const { updateBrain, maxCustomInstructions } = useBrains(brain?.brain_id);
  const { hasHandover } = useDialogs(brain?.brain_id);
  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { isDirty, errors },
    watch,
    reset,
  } = useForm<AutopilotProfile>({
    mode: 'onChange',
    defaultValues: brain?.metadata?.autopilot || {
      response_size: 'short',
      custom_instructions: '',
      support_handover: false,
    },
    resolver: yupResolver(
      aiProfile(maxCustomInstructions)
    ) as Resolver<AutopilotProfile>,
  });

  useEffect(() => {
    reset(brain?.metadata?.autopilot);
  }, [brain?.metadata?.autopilot, reset]);

  const onSubmit = useCallback<(p: AutopilotProfile) => void>(
    (autopilot) => {
      updateBrain({
        brain_id: brain?.brain_id,
        metadata: {
          ...brain.metadata,
          autopilot: removeEmptyValues(autopilot),
        },
      });
    },
    [brain, updateBrain]
  );

  const marksHumor = [
    { value: -1, label: t('brains.ai_profile.funny') },
    { value: 0, label: t('brains.ai_profile.neutral') },
    { value: 1, label: t('brains.ai_profile.serious') },
  ];
  const marksFormality = [
    { value: -1, label: t('brains.ai_profile.casual') },
    { value: 0, label: t('brains.ai_profile.neutral') },
    { value: 1, label: t('brains.ai_profile.formal') },
  ];
  const marksRespectfulness = [
    { value: -1, label: t('brains.ai_profile.irreverent') },
    { value: 0, label: t('brains.ai_profile.neutral') },
    { value: 1, label: t('brains.ai_profile.respectful') },
  ];
  const marksCreativity = [
    { value: 1, label: t('brains.ai_profile.matter_of_fact') },
    { value: 0, label: t('brains.ai_profile.neutral') },
    { value: -1, label: t('brains.ai_profile.creative') },
  ];

  return (
    <div className={styles.brain_settings_fields}>
      <FormFieldset
        title={t('brains.ai_profile.title')}
        subtitle={t('brains.ai_profile.subtitle')}
        primaryButton={{
          children: t('common.save'),
          isLoading: !brain,
          disabled: !isDirty || !!Object.keys(errors).length,
        }}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={!brain}
      >
        <Input
          name={t('brains.ai_profile.company_name')}
          label={t('brains.ai_profile.company_name')}
          register={register('name', {
            required: t('common.required_field'),
          })}
          errorMessage={t(errors?.name?.message)}
          placeholder="ACME Inc."
          size="small"
        />
        <div className={styles.brain_field__label}>
          {t('brains.ai_profile.response_lenght_title')}
        </div>
        <div>
          <Controller
            name="response_size"
            control={control}
            render={({ field }) => (
              <RadioGroup
                onChange={(e) => field.onChange(e.target.value)}
                className={styles.radio_group}
                row
              >
                <FormControlLabel
                  key="response_size_short"
                  value="short"
                  control={
                    <Radio
                      checked={field.value === 'short'}
                      size="small"
                      className={styles.radio_circle}
                    />
                  }
                  label={
                    <>
                      <span className={styles.radio_option_title}>
                        {t(`brains.ai_profile.short_response_title`)}
                      </span>
                      <p className={styles.radio_option_description}>
                        {t('brains.ai_profile.short_response_subtitle')}
                      </p>
                    </>
                  }
                />
                <FormControlLabel
                  key="response_siz_long"
                  value="long"
                  control={
                    <Radio
                      checked={field.value === 'long'}
                      size="small"
                      className={styles.radio_circle}
                    />
                  }
                  label={
                    <div className={styles.radio_option}>
                      <span className={styles.radio_option_title}>
                        {t(`brains.ai_profile.long_response_title`)}
                      </span>
                      <p className={styles.radio_option_description}>
                        {t('brains.ai_profile.long_response_subtitle')}
                      </p>
                    </div>
                  }
                />
              </RadioGroup>
            )}
          />
        </div>
        <div className={styles.sliders}>
          <p className={styles.brain_field__label}>
            {t('brains.ai_profile.tone_of_voice')}
          </p>
          <div className={styles.sliders_container}>
            <FieldsetSlider
              name="creativity_level"
              control={control}
              marks={marksCreativity}
              value={getValues('creativity_level')}
              className={styles.slider}
            />
            <FieldsetSlider
              name="humor_level"
              control={control}
              marks={marksHumor}
              value={getValues('humor_level')}
              className={styles.slider}
            />
            <FieldsetSlider
              name="formality_level"
              control={control}
              marks={marksFormality}
              value={getValues('formality_level')}
              className={styles.slider}
            />
            <FieldsetSlider
              name="respectfulness_level"
              control={control}
              marks={marksRespectfulness}
              value={getValues('respectfulness_level')}
              className={styles.slider}
            />
          </div>
          <Controller
            control={control}
            name="support_handover"
            render={({ field }) => (
              <div>
                <Typography
                  variant="subtitle1"
                  className={styles.brain_switch__title}
                >
                  {t('brains.ai_profile.handover_title')}
                </Typography>
                {hasHandover() && (
                  <StatusBadge
                    label={t('brains.ai_profile.badge_recommended')}
                    variant="info"
                  />
                )}
                <Typography
                  variant="body1"
                  className={styles.brain_switch__body}
                >
                  {t('brains.ai_profile.handover_description')}
                </Typography>
                <div className={styles.brain_field__data}>
                  <Switch
                    label={t('common.enabled')}
                    size="medium"
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={!!field.value}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Box mt={4}>
          <TextAreaAsInput
            id="custom_instructions"
            name="custom_instructions"
            label={t('brains.ai_profile.custom_instructions')}
            tooltip={
              <>
                {t('brains.ai_profile.custom_instructions_tooltip')}{' '}
                <Link
                  href="https://docs.moveo.ai/docs/get_started/collections-overview#custom-instructions"
                  external
                >
                  {t('common.learn_more')}
                </Link>
              </>
            }
            size="large"
            register={register('custom_instructions')}
            height="large"
            trimValue
            value={watch('custom_instructions')}
            error={!!errors?.custom_instructions}
            errorMessage={errors?.custom_instructions?.message}
            maxCharacters={maxCustomInstructions}
          />
        </Box>
      </FormFieldset>
    </div>
  );
};
