import { useMemo } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  datasourceEndpoints,
  documentsEndpoints as endpoints,
} from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import {
  Datasource,
  Document,
  DocumentStatus,
  Documents,
  PartialDocument,
} from '@/models/collections';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';

export const API = Object.freeze({
  listDocuments: async (
    collectionId: string,
    datasourceId: string
  ): Promise<Documents> =>
    callGet(endpoints.documents(collectionId, datasourceId)),

  getDocument: async (
    collectionId: string,
    datasourceId: string,
    document_id: string
  ): Promise<Document> =>
    callGet(endpoints.document(collectionId, datasourceId, document_id)),

  createDocument: async (
    collectionId: string,
    datasourceId: string,
    document: Partial<Document>
  ): Promise<Document> =>
    callPost(endpoints.documents(collectionId, datasourceId), document),

  updateDocument: async (
    collectionId: string,
    datasource_id: string,
    { document_id, ...document }: PartialDocument
  ): Promise<Document> =>
    callPut(
      endpoints.document(collectionId, datasource_id, document_id),
      document
    ),

  deleteDocument: async (
    collectionId: string,
    datasourceId: string,
    documentId: string
  ): Promise<Document> =>
    callDelete(endpoints.document(collectionId, datasourceId, documentId)),
});

export const onDocumentUpdated = (
  queryClient: QueryClient,
  collectionId: string,
  datasourceId: string,
  document: Document
) => {
  queryClient.setQueryData<Document>(
    [endpoints.document(collectionId, datasourceId, document.document_id)],
    (prev: Document) => ({ ...prev, ...document })
  );
  const queryKey = [endpoints.documents(collectionId, datasourceId)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Documents>(queryKey, (prev: Documents) => ({
      documents: (prev?.documents || []).map((item) =>
        item.document_id === document.document_id
          ? { ...item, ...document }
          : item
      ),
    }));
  }
};

export const onDocumentCreated = (
  queryClient: QueryClient,
  collectionId: string,
  datasourceId: string,
  document: Document
) => {
  const queryKey = [endpoints.documents(collectionId, datasourceId)];
  const queryKeyDatasources = datasourceEndpoints.datasource(
    collectionId,
    datasourceId
  );

  queryClient.setQueryData<Datasource>([queryKeyDatasources], (prev) => {
    if (document.status === DocumentStatus.AVAILABLE) {
      return {
        ...prev,
        document_count: prev.document_count + 1,
      };
    } else {
      return {
        ...prev,
        document_count: prev.document_count + 1,
        [`${document.status}_count`]: prev[`${document.status}_count`] + 1,
      };
    }
  });

  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Documents>(queryKey, (prev) => ({
      documents: [
        ...(prev?.documents || []).filter(
          (acc) => acc.document_id !== document.document_id
        ),
        document,
      ],
    }));
  }
};

export const onDocumentRemoved = (
  queryClient: QueryClient,
  collectionId: string,
  datasource_id: string,
  document: Document
) => {
  const queryKey = [endpoints.documents(collectionId, datasource_id)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Documents>(queryKey, (prev: Documents) => ({
      documents: (prev?.documents || []).filter(
        (acc) => acc.document_id !== document.document_id
      ),
    }));
  }
  queryClient.removeQueries({
    queryKey: [
      endpoints.document(collectionId, datasource_id, document.document_id),
    ],
  });

  const queryKeyDatasources = datasourceEndpoints.datasource(
    collectionId,
    datasource_id
  );

  queryClient.setQueryData<Datasource>([queryKeyDatasources], (prev) => {
    if (document.status === DocumentStatus.AVAILABLE) {
      return {
        ...prev,
        document_count: prev.document_count - 1,
      };
    } else {
      return {
        ...prev,
        document_count: prev.document_count - 1,
        [`${document.status}_count`]: prev[`${document.status}_count`] - 1,
      };
    }
  });
};

const useDocuments = (
  collectionId: string,
  datasourceId: string,
  documentId?: string
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data: documents, status: listStatus } = useQuery<Documents, Error>({
    queryKey: [endpoints.documents(collectionId, datasourceId)],
    queryFn: () => API.listDocuments(collectionId, datasourceId),
    enabled: !!collectionId && !!datasourceId,
  });

  const { data: document, status: getStatus } = useQuery<Document, Error>({
    queryKey: [endpoints.document(collectionId, datasourceId, documentId)],
    queryFn: () => API.getDocument(collectionId, datasourceId, documentId),
    enabled: !!collectionId && !!datasourceId && !!documentId,
  });

  const { mutate: updateDocument, status: updateStatus } = useMutation<
    Document,
    Error,
    PartialDocument
  >({
    mutationFn: (data) => API.updateDocument(collectionId, datasourceId, data),
    onSuccess: (resp) => {
      onDocumentUpdated(queryClient, collectionId, datasourceId, resp);
      dispatch(
        addTemporalToast(
          'success',
          t('collections.documents.updated', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: createDocument, status: createStatus } = useMutation<
    Document,
    Error,
    Partial<Document>
  >({
    mutationFn: (data) => API.createDocument(collectionId, datasourceId, data),
    onSuccess: (resp) => {
      onDocumentCreated(queryClient, collectionId, datasourceId, resp);
      dispatch(
        addTemporalToast(
          'success',
          t('collections.documents.created', { 0: resp?.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const {
    mutate: deleteDocument,
    mutateAsync: deleteDocumentAsync,
    status: deleteStatus,
  } = useMutation<Document, Error, string>({
    mutationFn: (id) =>
      API.deleteDocument(collectionId, datasourceId, id ?? documentId),
    onSuccess: (resp) => {
      const document = documents?.documents.find(
        (d) => resp.document_id === d.document_id
      );
      onDocumentRemoved(queryClient, collectionId, datasourceId, document);
      dispatch(addTemporalToast('success', t('collections.documents.deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const documentNames = useMemo(
    () => documents?.documents?.map((d) => d.name),
    [documents?.documents]
  );

  return {
    documents: documents?.documents,
    document,
    getStatus,
    listStatus,
    createStatus,
    deleteStatus,
    updateStatus,
    createDocument,
    deleteDocument,
    deleteDocumentAsync,
    updateDocument,
    documentNames,
  };
};

export default useDocuments;
