import { t } from 'i18next';
import moment from 'moment';

import { Conversation } from '@/models/chat';

import { MessageOrEvent } from './MessageArea';

const ONE_MINUTE = 60000;

export const compareTimestamps = (
  timestamp1: string,
  timestamp2: string,
  duration: number
): boolean => {
  const difference = Date.parse(timestamp1) - Date.parse(timestamp2);
  return Math.abs(difference) > duration;
};

const isSameAuthor = (message1, message2) =>
  message1?.author_id === message2?.author_id;

export const shouldShowTime = ({ currentMessage, nextMessage, index }) => {
  if (index === 0) return true;

  const isNextSameAuthor = isSameAuthor(currentMessage, nextMessage);
  const isNextSameAuthorType =
    currentMessage?.author_type === nextMessage?.author_type;
  const isSignificantTimeGap = compareTimestamps(
    currentMessage?.created,
    nextMessage?.created,
    ONE_MINUTE
  );

  return !isNextSameAuthorType || !isNextSameAuthor || isSignificantTimeGap;
};

export const shouldShowAvatar = ({
  currentMessage,
  nextMessage,
  prevMessage,
  index,
}) => {
  if (index === 0 || shouldShowTime({ currentMessage, nextMessage, index })) {
    return true;
  }

  const isPrevSameAuthor = isSameAuthor(currentMessage, prevMessage);
  const isNextSameAuthor = isSameAuthor(currentMessage, nextMessage);

  if (currentMessage.author_type !== 'visitor') {
    if (currentMessage.author_type !== nextMessage?.author_type) {
      return true;
    }

    if (currentMessage.author_type !== 'brain' && isNextSameAuthor) {
      return false;
    }

    if (currentMessage.author_type !== 'brain' && isPrevSameAuthor) {
      return true;
    }
  }

  return false;
};

export const isSameDay = (timestamp1: string, timestamp2: string) => {
  const date1 = moment(timestamp1);
  const date2 = moment(timestamp2);

  return date1.isSame(date2, 'day');
};

const fromNowCustom = (date: moment.Moment) => {
  const hoursDiff = moment().diff(date, 'hours');

  if (hoursDiff < 24) {
    return t('analytics.periods.today');
  } else {
    return date.fromNow();
  }
};

export const getTimeElement = (item) => {
  const createdDate = moment(item.created);
  const currentDate = moment();
  const oneDayAgo = currentDate.clone().subtract(1, 'days');

  if (
    createdDate.isAfter(oneDayAgo) &&
    createdDate.isSame(currentDate, 'day')
  ) {
    return {
      time_element: true,
      created: fromNowCustom(createdDate),
    };
  } else {
    return {
      time_element: false,
      created: createdDate.format('DD/MM/YYYY'),
    };
  }
};

export const mergeAndSortData = (
  flatMessages: MessageOrEvent[],
  events: MessageOrEvent[]
): MessageOrEvent[] => {
  const earliestDate = new Date(
    flatMessages?.[flatMessages.length - 1]?.created
  );
  const filteredEvents = events.filter(
    (event) => new Date(event.created) >= earliestDate
  );
  const combined: MessageOrEvent[] = [...flatMessages, ...filteredEvents];

  combined.sort(
    (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
  );

  // Insert day dividers
  for (let i = 0; i < combined.length - 1; i++) {
    if (i === 0) {
      const timeElement = getTimeElement(combined[i]);
      combined.splice(i, 0, timeElement);
      i++;
      continue;
    }

    if (!isSameDay(combined[i].created, combined[i + 1].created)) {
      const timeElement = getTimeElement(combined[i + 1]);
      combined.splice(i + 1, 0, timeElement);
      i++;
    }
  }
  return combined;
};

export const getVisitorStatus = (conversation: Conversation) => {
  const state = conversation?.state;

  if (!state) {
    return { visitorStatus: null, lastVisitorActivity: null };
  }

  const { last_activity_at, active } = state;
  const lastActivityMoment = moment(last_activity_at);
  const lastActivityFromNow = lastActivityMoment.fromNow();

  if (!active) {
    return { visitorStatus: null, lastVisitorActivity: lastActivityFromNow };
  }

  const currentMoment = moment();
  const diff = currentMoment.diff(lastActivityMoment, 'milliseconds');

  if (diff < ONE_MINUTE) {
    return {
      visitorStatus: 'online',
      lastVisitorActivity: lastActivityFromNow,
    };
  } else {
    return { visitorStatus: 'away', lastVisitorActivity: lastActivityFromNow };
  }
};
