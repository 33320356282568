import CustomerRemarks from '../components/fieldsets/CustomerRemarks/CustomerRemarks';
import RatingByDay from '../components/fieldsets/RatingByDay/RatingByDay';
import Filters from '../components/filters/AccountFilters/AccountFilters';

export const CustomerSatisfaction = () => {
  return (
    <>
      <Filters />
      <RatingByDay />
      <RatingByDay isMoreThan3 />
      <CustomerRemarks />
    </>
  );
};
