import clarityInit from './react-clarity';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clarity: any;
  }
}

export function clarity(...params: string[]): void {
  if (!window.clarity) {
    throw new Error('Clarity is not initialized');
  }
  window.clarity.apply(undefined, params);
}

export function init(id: string): void {
  clarityInit(id);
}

export function hasStarted(): boolean {
  return typeof window.clarity === 'function';
}

export function identify(
  userId: string,
  customSessionId?: string | null,
  customPageId?: string | null,
  friendlyName?: string
): void {
  clarity('identify', userId, customSessionId, customPageId, friendlyName);
}

export function consent(): void {
  clarity('consent');
}

export function setTag(key: string, value: string): void {
  clarity('set', key, value);
}

export function upgrade(reason: string): void {
  clarity('upgrade', reason);
}
