import { createElement, useCallback, useMemo } from 'react';

import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BrowserIcon } from '@/components/atoms/Icons/BrowserIcon/BrowserIcon';
import { PlatformIcon } from '@/components/atoms/Icons/PlatformIcon/PlatformIcon';
import { IntegrationIcon } from '@/components/organisms/Integrations';
import { useConversationContext } from '@/hooks/useConversationContext';
import { usePreferences } from '@/hooks/usePreferences';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';
import { getCountryByCode, getLanguageByLocale, noop } from '@/util/util';

import { useConversationsNew } from './useConversationsNew';
import { IconByField } from '../components/RightSidebar/InformationSection/InformationSection';
import { MenuItem } from '../models';

export const useInformationSection = () => {
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);
  const { conversation } = useConversationsNew({
    deskId,
    sessionId,
  });
  const { t } = useTranslation();
  const { user } = useConversationContext();
  const { accountUserPrefs, updateAccountUserPreferences } = usePreferences();

  const { location, phone, locales, address, email } = user || {};
  const country = location?.country;

  const languages = useMemo(() => {
    return uniq(
      locales?.map((locale) => getLanguageByLocale(locale)).filter((l) => !!l)
    ).join(', ');
  }, [locales]);

  const cityAndCountry = useMemo(() => {
    return (
      [location?.city, location?.region, getCountryByCode(country)]
        .filter((l) => !!l)
        .join(', ') || '-'
    );
  }, [location, country]);

  const usersTime = useMemo(() => {
    const options = {
      timeZone: user?.timezone,
      hour: 'numeric' as const,
      minute: 'numeric' as const,
      hour12: true,
      timeZoneName: 'short' as const,
    };
    const formatter = new Intl.DateTimeFormat([], options);
    return (
      formatter
        .format(new Date())
        .split(' ')
        .map((x, index, array) => {
          if (index === 2) {
            return (array[index] = `(${x})`);
          }
          return x;
        })
        .join(' ') || '-'
    );
  }, [user?.timezone]);

  const pinnedItems = useMemo(() => {
    return accountUserPrefs?.pinned_input_fields || [];
  }, [accountUserPrefs?.pinned_input_fields]) as string[];

  const onInformationFieldClick = useCallback(
    (key: string) => {
      const index = pinnedItems.findIndex((id) => id === key);
      let newPinnedItems: string[];

      if (index > -1) {
        // If found, remove pin from the field
        newPinnedItems = pinnedItems.filter((itemKey) => itemKey !== key);
      } else {
        // If not found, add pin to the field
        newPinnedItems = [...pinnedItems, key];
      }

      updateAccountUserPreferences({
        ...accountUserPrefs,
        pinned_input_fields: newPinnedItems,
      });
    },
    [accountUserPrefs, pinnedItems, updateAccountUserPreferences]
  );

  const emailValue = useMemo(() => {
    return email ?? t('contacts.set_email');
  }, [email, t]);

  const addressValue = useMemo(() => {
    return address ?? t('contacts.set_address');
  }, [address, t]);

  const phoneValue = useMemo(() => {
    return phone ?? t('contacts.set_phone');
  }, [phone, t]);

  const items: MenuItem[] = useMemo(
    () => [
      {
        id: 'email',
        text: emailValue,
        onClick: noop,
        onPinClick: () => onInformationFieldClick('email'),
        icon: IconByField.email,
        disabled: !email,
        pinned: true,
      },
      {
        id: 'channel',
        text: conversation?.channel,
        onClick: noop,
        onPinClick: () => onInformationFieldClick('channel'),
        icon: createElement(IntegrationIcon, {
          type: conversation?.channel,
          size: 16,
        }),
      },
      {
        id: 'browser',
        text: user?.browser,
        onClick: noop,
        onPinClick: () => onInformationFieldClick('browser'),
        icon: createElement(BrowserIcon, {
          browser: user?.browser,
        }),
      },
      {
        id: 'platform',
        text: user?.platform,
        onClick: noop,
        onPinClick: () => onInformationFieldClick('platform'),
        icon: createElement(PlatformIcon, {
          platform: user?.platform,
        }),
      },
      {
        id: 'local_time',
        text: usersTime,
        onClick: noop,
        onPinClick: () => onInformationFieldClick('local_time'),
        icon: IconByField.local_time,
        pinned: true,
      },
      {
        id: 'languages',
        text: languages,
        onClick: noop,
        onPinClick: () => onInformationFieldClick('languages'),
        icon: IconByField.languages,
        pinned: true,
      },
      {
        id: 'address',
        text: addressValue,
        onClick: noop,
        onPinClick: () => onInformationFieldClick('address'),
        icon: IconByField.address,
        disabled: !address,
      },
      {
        id: 'phone',
        text: phoneValue,
        onClick: noop,
        onPinClick: () => onInformationFieldClick('phone'),
        icon: IconByField.phone,
        disabled: !phone,
      },
      {
        id: 'location',
        text: cityAndCountry,
        onClick: noop,
        onPinClick: () => onInformationFieldClick('location'),
        icon: IconByField.location,
        pinned: true,
      },
    ],
    [
      address,
      addressValue,
      cityAndCountry,
      conversation?.channel,
      email,
      emailValue,
      languages,
      onInformationFieldClick,
      phone,
      phoneValue,
      user?.browser,
      user?.platform,
      usersTime,
    ]
  );

  return {
    items,
    pinnedItems,
    email,
    cityAndCountry,
    usersTime,
    languages,
    address,
    phone,
    channel: conversation?.channel,
    browser: user?.browser,
    platform: user?.platform,
  };
};
