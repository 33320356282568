import { useCallback, useEffect } from 'react';

import Box from '@mui/material/Box/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DropdownList from '@/components/atoms/DropdownList/DropdownList';
import IconButton from '@/components/atoms/IconButton/IconButton';
import ArrowLeft from '@/components/atoms/Icons/ArrowLeft';
import EnvironmentIcon from '@/components/atoms/Icons/Environment';
import useDesks from '@/hooks/useDesks';
import { setDeskId } from '@/redux/session/actions';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';

import styles from './Header.module.scss';

interface Props {
  title: string | React.ReactNode;
  icon?: React.ReactNode;
  withDesk?: boolean;
  onBackClick?: () => void;
  isLoading?: boolean;
}
const Title = ({ icon, title, withDesk, isLoading, onBackClick }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const slug = useSelector(selectAccountSlug);
  const { t } = useTranslation();

  const { desks } = useDesks();

  const deskId = useSelector(selectDeskId);
  const { deskId: paramsDeskId } = useParams();

  useEffect(() => {
    if (paramsDeskId && paramsDeskId !== deskId) {
      dispatch(setDeskId(paramsDeskId));
    }
  }, [deskId, dispatch, paramsDeskId]);

  const selectedDesk = desks?.find((desk) => desk.desk_id === deskId)?.name;

  const options = desks?.map((desk) => ({
    label: desk.name,
    value: desk.desk_id,
    icon: <EnvironmentIcon />,
  }));

  const handleChange = useCallback(
    (item: { value: string }) => {
      let path = location.pathname;
      if (path.endsWith('/')) {
        path = path.substring(0, path.length - 1);
      }
      const route = path.split('/').pop();

      if (route === slug || route === 'chats') {
        dispatch(setDeskId(item.value));
      } else {
        navigate(`/${slug}/environments/${item.value}/${route}`);
      }
    },
    [dispatch, location.pathname, navigate, slug]
  );

  return (
    <>
      <div className={styles.titleWrapper}>
        {onBackClick && (
          <Box mr={1}>
            <IconButton
              onClick={onBackClick}
              ariaLabel={t('brains.back_to_brains')}
            >
              <ArrowLeft />
            </IconButton>
          </Box>
        )}
        {icon}

        {isLoading ? <Skeleton height={15} width={100} /> : title}

        {withDesk && (
          <div className={styles.dropdownWrapper}>
            <DropdownList
              options={options}
              size="auto"
              height="medium"
              optionClick={handleChange}
              selected={selectedDesk}
              disabled={!deskId}
            >
              {deskId ? (
                <div className={styles.label}>
                  <EnvironmentIcon color="var(--icon-default-blue)" />
                  <Typography variant="body-medium">{selectedDesk}</Typography>
                </div>
              ) : (
                <div className={styles.labelDisabled}>
                  <EnvironmentIcon color="var(--icon-disabled-gray)" />
                  <span>{t('environments.no_env')}</span>
                </div>
              )}
            </DropdownList>
          </div>
        )}
      </div>
    </>
  );
};

export default Title;
