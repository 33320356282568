import { useSelector } from 'react-redux';

import DeleteFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/DeleteFieldset';
import { useSunco } from '@/hooks/useSunco';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import AuthFieldset from './components/AuthFieldset';
import SuncoSettings from './components/SuncoSettings';

export default function IntegrationSunco() {
  const { onOAuthClick, integration, isConnected } = useSunco();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  if (!integration) {
    return null;
  }
  return (
    <>
      {isConnected && <SuncoSettings integration={integration} />}
      {!isConnected && (
        <AuthFieldset
          type="Sunshine Conversations"
          onOAuthClick={onOAuthClick}
        />
      )}
      <DeleteFieldset integration={integration} disabled={!canWrite} />
    </>
  );
}
