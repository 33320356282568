import { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import { useAccount } from '@/hooks/useAccount';
import useIntents from '@/hooks/useIntents';
import { Intent } from '@/models/intent';
import { EventName } from '@/models/segment';
import { popModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';

import { ErrorDescription } from './ErrorDescription';
import { ErrorRequest } from './ErrorRequest';
import { errorHandler } from './util';

import styles from './ModalAutoBrain.module.scss';

interface Props {
  error: {
    extra: {
      internal_message?: string;
    };
  };
}

const getConflictingIntent = (
  intents: Intent[],
  errors: { [key: string]: string },
  isConflict: boolean
) => {
  if (!isConflict) {
    return null;
  }
  let intent;
  for (const value of Object.values(errors)) {
    intent = intents?.find((intent) => intent.intent == value);
    if (intent) {
      break;
    }
  }
  return intent;
};

const AutoBrainError = ({ error }: Props) => {
  const navigate = useNavigate();
  const brainId = useSelector(selectBrainId);
  const { slug } = useAccount();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { intents } = useIntents(brainId);

  const { intentConflict, notGenerated, title, subtitle } = errorHandler({
    message: error?.extra?.internal_message,
  });
  const intent = getConflictingIntent(intents, error.extra, intentConflict);

  const navigateToIntent = () => {
    dispatch(popModal());
    navigate(`/${slug}/brains/${brainId}/intents/${intent.intent}`);
  };

  useEffect(() => {
    trackEvent(EventName.AutoDialogError, {
      error: error?.extra?.internal_message,
    });
  }, [error?.extra?.internal_message]);

  return (
    <div className={styles.errorContainer}>
      {notGenerated ? <ErrorDescription /> : <ErrorRequest />}
      <Typography variant="heading1-bold">{title}</Typography>
      <Typography>{subtitle}</Typography>

      {intent && (
        <div className={styles.intent}>
          <Typography>{t('common.intent')}:</Typography>{' '}
          <Button
            underline
            variant="tertiary"
            size="small"
            onClick={navigateToIntent}
          >
            {intent.intent}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AutoBrainError;
