import { Fragment, useCallback, useState } from 'react';

import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import Entities from '@/components/atoms/Icons/Entities';
import Intents from '@/components/atoms/Icons/Intents';
import IntentModal from '@/components/organisms/TryIt/IntentModal/IntentModal';
import { useAccount } from '@/hooks/useAccount';
import useDialogs from '@/hooks/useDialogs';
import { LogSessionContentRow } from '@/hooks/useLogs';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { CollectionMessage, Entity, TryIt, UserMessage } from '@/models/tryIt';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { NodeIcon } from '@/modules/TryIt/Icons/NodeIcon';
import { TriggerMessageIcon } from '@/modules/TryIt/Icons/TriggerMessageIcon';
import { selectTryItNode } from '@/modules/TryIt/redux/actions';
import {
  selectIsTryItReplay,
  selectSelectedMessage,
  selectStandaloneQuestion,
} from '@/modules/TryIt/redux/selectors';
import { popModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';

import { BrainSteps } from './BrainSteps';

import styles from '../DetailsSection.module.scss';

type BrainDetailsSectionProps = {
  content: LogSessionContentRow;
};

export type BrainDetailsSectionContentProps = {
  slug: string;
  brainId: string;
  nodes: TryIt['selectedMessage']['nodes'];
  index: number;
  isReplay: boolean;
  canWrite: boolean;
  handleGoToNode: (id: string) => void;
  closeModal: () => void;
  session_id?: string;
  standAloneQuestion?: string;
};

export const BrainDetailsSectionContent = ({
  slug,
  brainId,
  nodes,
  index,
  isReplay,
  canWrite,
  handleGoToNode,
  closeModal,
  session_id,
  standAloneQuestion,
}: BrainDetailsSectionContentProps) => {
  const { t } = useTranslation();
  const userMessage = (nodes[index]?.messages[0] as CollectionMessage)?.text;
  const entities = uniqBy(
    (nodes[index]?.messages[0] as UserMessage)?.entities,
    'entity'
  ) as unknown as Entity[];
  const isRequisite =
    nodes[index]?.steps && nodes[index]?.steps?.[0]?.type !== 4;

  const intent =
    (nodes[index]?.messages?.[0] as UserMessage)?.intents?.[0] ||
    nodes[index]?.intents?.[0];
  const threshold = nodes[index]?.steps?.[0]?.intent_match_theshold;
  const percentageThreshold = `${(threshold * 100).toFixed()}%`;
  const triggeredNode = nodes[index]?.nodes_stack?.find(
    (node) => node.node_id === nodes[index]?.steps?.[0]?.node_id
  );
  const brainSteps = !isRequisite
    ? nodes[index]?.steps?.slice(1)
    : nodes[index]?.steps;

  const [expressionToTrain, setExpressionToTrain] = useState('');
  const showAddTrainingPhrase = isReplay && intent?.confidence !== 1;
  const [showIntentsModal, setShowIntentsModal] = useState(false);

  return (
    <Stack data-testid="newDebugLogs">
      {isReplay && showIntentsModal && (
        <IntentModal
          close={() => {
            setExpressionToTrain('');
            setShowIntentsModal(false);
          }}
          expression={expressionToTrain}
          brainId={brainId}
        />
      )}

      <div className={styles.step}>
        <div className={styles.title}>
          <TriggerMessageIcon />

          <Tooltip
            arrow
            title={standAloneQuestion}
            PopperComponent={(props) => (
              <Popper
                {...props}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -10],
                    },
                  },
                ]}
              />
            )}
          >
            <Typography
              variant="body-regular"
              color="var(--text-default-gray)"
              className={styles.triggerMessage}
              component="div"
            >
              {userMessage}
            </Typography>
          </Tooltip>
        </div>
        <div className={styles.details}>
          {showAddTrainingPhrase && (
            <Button
              disabled={!canWrite}
              variant="tertiary"
              onClick={() => {
                setExpressionToTrain(userMessage);
                setShowIntentsModal(true);
                trackEvent(EventName.ClickAddTrainingPhrase, { session_id });
              }}
            >
              {t('try_it.details.add_training_phrase')}
            </Button>
          )}
        </div>
      </div>

      {entities.length > 0 && (
        <div className={styles.step}>
          <div className={styles.title}>
            <Entities />
            <Typography
              variant="body-regular"
              color="var(--text-default-gray)"
              component="div"
            >
              {t('try_it.entity', {
                count: entities?.length,
              })}{' '}
              {entities?.map((entity, index) => (
                <Fragment key={entity.value}>
                  <NavLink
                    to={`/${slug}/brains/${brainId}/entities/${entity.entity}`}
                    className={styles.link}
                    onClick={closeModal}
                  >
                    {entity.entity}
                  </NavLink>
                  {index < entities.length - 1 && ', '}
                </Fragment>
              ))}{' '}
              {t('try_it.matched')}
            </Typography>
          </div>
          <div className={styles.details}></div>
        </div>
      )}
      {!isRequisite && (
        <>
          <div className={styles.step}>
            <div className={styles.title}>
              <Intents />
              <Typography
                variant="body-regular"
                color="var(--text-default-gray)"
                component="div"
              >
                {t('common.intent')}{' '}
                <Typography variant="body-regular">
                  <NavLink
                    to={`/${slug}/brains/${brainId}/intents/${intent?.intent}`}
                    className={styles.link}
                    onClick={closeModal}
                  >
                    {intent?.intent}
                  </NavLink>
                </Typography>{' '}
                {t('try_it.matched_with_confidence', {
                  percentage: (intent?.confidence * 100).toFixed(0),
                })}
              </Typography>
            </div>
            <div className={styles.details}>
              {!!threshold && (
                <Typography
                  className={styles.details__content}
                  variant="body-regular"
                  color="var(--text-default-gray-light)"
                  component="div"
                >
                  {t('brains.threshold').toLocaleLowerCase()}:{' '}
                  {percentageThreshold}
                </Typography>
              )}
            </div>
          </div>

          {brainSteps?.length > 0 && (
            <div className={styles.step}>
              <div className={styles.title}>
                <NodeIcon />
                <Typography
                  variant="body-regular"
                  color="var(--text-default-gray)"
                  component="div"
                >
                  {t('common.node')}{' '}
                  <Typography
                    className={styles.link}
                    variant="body-regular"
                    color="var(--text-default-blue)"
                    onClick={() => handleGoToNode(triggeredNode.node_id)}
                    role="link"
                  >
                    {triggeredNode?.name}
                  </Typography>{' '}
                  {t('try_it.triggered')}
                </Typography>
              </div>
              <div className={styles.details}></div>
            </div>
          )}
        </>
      )}

      <BrainSteps steps={brainSteps} />
    </Stack>
  );
};

export const BrainDetailsSection = ({ content }: BrainDetailsSectionProps) => {
  const standAloneQuestion = useSelector(selectStandaloneQuestion);
  const navigate = useNavigate();
  const brainId = useSelector(selectBrainId);
  const selectedMessage = useSelector(selectSelectedMessage);
  const isReplay = useSelector(selectIsTryItReplay);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const { session_id } = content || {};

  const { dialogs } = useDialogs(brainId);
  const { slug } = useAccount();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { nodes, index } = selectedMessage;

  const closeModal = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  const handleGoToNode = useCallback(
    (id: string) => {
      const dialog = dialogs.find((d) => d.nodes.some((n) => n.node_id === id));

      // If the dialog is deleted, we show an error message
      if (!dialog?.dialog_id) {
        dispatch(addTemporalToast('error', t('errors.deleted_dialog')));
        return;
      }

      // We get error here
      navigate(`/${slug}/brains/${brainId}/dialogs/${dialog.dialog_id}`);

      closeModal();
      dispatch(selectTryItNode(id));
    },
    [brainId, closeModal, dialogs, dispatch, navigate, slug, t]
  );

  return (
    <BrainDetailsSectionContent
      {...{
        slug,
        brainId,
        nodes,
        index,
        isReplay,
        canWrite,
        handleGoToNode,
        closeModal,
        session_id,
        standAloneQuestion,
      }}
    />
  );
};
