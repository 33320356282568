import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography = {
  ['body-regular']: {
    fontSize: 'var(--space-14)',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: 'var(--space-20)',
  },
  ['body-medium']: {
    fontSize: 'var(--space-14)',
    letterSpacing: '0',
    lineHeight: 'var(--space-20)',
    fontWeight: '600',
  },
  ['body-bold']: {
    fontSize: 'var(--space-14)',
    letterSpacing: '0',
    lineHeight: 'var(--space-20)',
    fontWeight: '700',
  },
  ['body-hyperlink']: {
    fontSize: 'var(--space-14)',
    letterSpacing: '0',
    lineHeight: 'var(--space-20)',
    fontWeight: '700',
  },
} as TypographyOptions;

export default typography;
