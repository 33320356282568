import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router';

import Logo from '@/components/atoms/Icons/Logo';

import RightContainer from './RightContainer';

import styles from './BackgroundWrapper.module.scss';

interface props {
  children: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  showBranding?: boolean;
  useLeftContainer?: boolean;
}

export const BackgroundWrapper = ({
  title,
  subtitle,
  showBranding = false,
  children,
  useLeftContainer = true,
}: props) => {
  const { pathname } = useLocation();
  const isWelcomePage = pathname.includes('signup/welcome');
  return (
    <div className={styles.content}>
      {!useLeftContainer && (
        <div className={styles.floatingWrapper}>
          <Logo height={20} width={120} />
          <div className={styles.floatingContainer}>
            <div className={styles.subheader}>
              {title && (
                <Typography
                  variant="heading1-xlarge"
                  component="h1"
                  className={styles.title}
                >
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography
                  className={styles.subtitle}
                  component="h2"
                  variant="heading2-regular"
                >
                  {subtitle}
                </Typography>
              )}
            </div>
            {children}
          </div>
        </div>
      )}
      {useLeftContainer && (
        <div className={styles.content__left}>
          <Logo height={20} width={120} />
          <div className={styles.subheader}>
            {title && (
              <Typography
                variant="heading1-xlarge"
                component="h1"
                className={styles.title}
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                className={styles.subtitle}
                component="h2"
                variant="heading2-regular"
              >
                {subtitle}
              </Typography>
            )}
          </div>
          {children}
        </div>
      )}

      <div className={styles.content__right}>
        <RightContainer
          variant={isWelcomePage ? 'welcome' : 'regular'}
          showBranding={showBranding}
        />
      </div>
    </div>
  );
};
