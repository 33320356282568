import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import { useAccount } from '@/hooks/useAccount';
import { Integration, ZendeskIntegration } from '@/models/integration';
import { ZENDESK_AUTHORIZATION_URL, getDocsUrl } from '@/util/constants';

import ApiClientFieldset from '../fieldsets/ApiClientFieldset';
import AuthorizationFieldset from '../fieldsets/AuthorizationFieldset';

interface Props {
  isUpdated: boolean;
  isUpdating: boolean;
  integration: ZendeskIntegration;
  updateIntegration?: (integration: ZendeskIntegration) => void;
}

const createOAuthURL = (integration: ZendeskIntegration) => {
  const queryParams = new URLSearchParams({
    redirect_uri: integration?.config?.redirect_uri ?? '',
    subdomain: integration?.config?.subdomain ?? '',
    client_id: integration?.config?.client_id ?? '',
    response_type: 'code',
    scope: 'read write chat',
  });

  return `${ZENDESK_AUTHORIZATION_URL}?${queryParams}`;
};

export default function ZendeskOauth({
  isUpdated,
  isUpdating,
  integration,
  updateIntegration,
}: Props) {
  const { t } = useTranslation();
  const { slug } = useAccount();

  const [shouldRedirect, setShouldRedirect] = useState(false);

  const createCallbackURL = useCallback(
    (integration: Integration) => {
      const { desk_id, integration_id } = integration;
      return `${window?.location?.origin}/www/api/integrations/zendesk/${slug}/environments/${desk_id}/integrations/${integration_id}/callback`;
    },
    [slug]
  );

  const zendeskUrl = createOAuthURL(integration);
  const callbackUrl = createCallbackURL(integration);

  useEffect(() => {
    if (shouldRedirect && isUpdated && !isUpdating) {
      window.open(zendeskUrl, '_self');
      setShouldRedirect(false);
    }
  }, [callbackUrl, zendeskUrl, shouldRedirect, isUpdated, isUpdating]);

  return (
    <div>
      <ContextualHelp
        name="zendesk-setup"
        title={t('integrations.zendesk.name')}
        links={[
          {
            label: t('integrations.zendesk.help_label'),
            url: getDocsUrl('/docs/integrations/zendesk'),
          },
        ]}
      >
        {t('integrations.zendesk.help_text')}
      </ContextualHelp>
      <ApiClientFieldset callbackUrl={callbackUrl} />
      <AuthorizationFieldset
        callbackUrl={callbackUrl}
        isUpdated={isUpdated}
        isUpdating={isUpdating}
        integration={integration}
        updateIntegration={updateIntegration}
      />
    </div>
  );
}
