import { useCallback, useDeferredValue, useEffect, useState } from 'react';

import orderBy from 'lodash/orderBy';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from 'react-use';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import { RichHeader } from '@/components/organisms/Headers/RichHeader/RichHeader';
import { BRAIN_ADD } from '@/components/organisms/Modals/ModalConductor';
import {
  TileBrain,
  TileBrainSkeleton,
  TileCreation,
} from '@/components/organisms/Tile';
import OldTileCreation from '@/components/organisms/Tile/TileCreation/OldTileCreation';
import TileList from '@/components/organisms/Tile/TileList';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { usePreferences } from '@/hooks/usePreferences';
import { Brain } from '@/models/brain';
import { actions } from '@/models/permissions';
import { EventName, PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { pageView, trackEvent } from '@/segment/segment';
import { BRAIN_DOCS_URL } from '@/util/constants';
import { preventClickThrough } from '@/util/util';

export default function Brains() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const slug = useSelector(selectAccountSlug);
  const [sort, setSort] = useState('last_trained');
  const [filter, setFilter] = useState<string>();
  const defferedFilter = useDeferredValue(filter);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  const { enableLimits } = useFeatureFlag();
  const { billingState } = useBilling();
  useEffect(() => {
    pageView(PageName.BRAINS);
  }, []);

  useTitle(t('pages.brains', { 0: slug }));

  const { brains, maxBrains, maxBrainsReached, createStatus } = useBrains();
  const disabled = maxBrainsReached || !canWrite;

  const showAddBrainModal = useCallback(() => {
    trackEvent(EventName.ClickCreateBrain);
    dispatch(pushModal(BRAIN_ADD));
  }, [dispatch]);

  const { updateAccountUserPreferences, accountUserPrefs } = usePreferences();

  const handleFavorite = useCallback(
    (event, brainId) => {
      preventClickThrough(event);

      const favoriteBrainIds: string[] =
        (accountUserPrefs?.favorite_brain_ids as string[]) || [];
      const index = favoriteBrainIds.indexOf(brainId);

      if (index > -1) {
        favoriteBrainIds.splice(index, 1);
      } else {
        favoriteBrainIds.push(brainId);
      }

      updateAccountUserPreferences({
        ...accountUserPrefs,
        favorite_brain_ids: favoriteBrainIds,
      });
    },
    [accountUserPrefs, updateAccountUserPreferences]
  );

  function renderSkeletons(n = 3) {
    return (
      Array(n)
        .fill(0)
        // eslint-disable-next-line react/no-array-index-key
        .map((_, i) => <TileBrainSkeleton key={i} />)
    );
  }

  const splitBrainsByFavorite = (sortedBrains, favoriteBrainIds) => {
    const favorites = [];
    const nonFavorites = [];

    for (let i = 0; i < sortedBrains.length; i++) {
      if (
        favoriteBrainIds.find((brainId) => brainId === sortedBrains[i].brain_id)
      ) {
        favorites.push(sortedBrains[i]);
      } else {
        nonFavorites.push(sortedBrains[i]);
      }
    }

    return [favorites, nonFavorites];
  };

  const renderTiles = useCallback(() => {
    let sortedBrains: Brain[];
    sortedBrains = orderBy(brains, [sort], ['desc']) as Brain[];

    if (defferedFilter) {
      sortedBrains = sortedBrains.filter((brain) =>
        brain.name.toLowerCase().includes(defferedFilter)
      );
    }

    const favoriteBrainIds: string[] =
      accountUserPrefs?.favorite_brain_ids as string[];

    if (favoriteBrainIds) {
      const [favorites, nonFavorites] = splitBrainsByFavorite(
        sortedBrains,
        favoriteBrainIds
      );

      return [
        ...favorites.map((brain) => (
          <TileBrain
            brain={brain}
            key={brain.brain_id}
            slug={slug}
            handleFavorite={handleFavorite}
            isFavorite
          />
        )),
        ...nonFavorites.map((brain) => (
          <TileBrain
            brain={brain}
            key={brain.brain_id}
            slug={slug}
            handleFavorite={handleFavorite}
          />
        )),
      ];
    }

    return sortedBrains.map((brain) => (
      <TileBrain
        brain={brain}
        key={brain.brain_id}
        slug={slug}
        handleFavorite={handleFavorite}
      />
    ));
  }, [
    accountUserPrefs?.favorite_brain_ids,
    brains,
    defferedFilter,
    handleFavorite,
    slug,
    sort,
  ]);

  const handleButtonClick = useCallback(() => {
    trackEvent(EventName.ClickCreateBrain);
    dispatch(pushModal(BRAIN_ADD));
  }, [dispatch]);

  const getTooltipTitle = () => {
    if (!canWrite) {
      return t('brains.no_permission');
    } else if (disabled) {
      return t('limits.reached', { 0: 'brains', 1: billingState?.plan_name });
    }

    return '';
  };

  const handleChange = useCallback((value: string) => {
    if (value === 'last_created') {
      setSort('created');
    } else {
      setSort(value);
    }
  }, []);

  const canCreateBrain = canWrite && !maxBrainsReached;
  return (
    <PermissionsWrapper module="brains">
      <RichHeader
        handleChange={handleChange}
        sortingOptions={['last_trained', 'last_created']}
        setFilter={setFilter}
        disabled={disabled}
        onButtonClick={handleButtonClick}
        tooltip={getTooltipTitle()}
        name={t('common.brains')}
      />
      <PageContentWrapper newPlain readOnly={!canWrite}>
        <ContextualHelp
          title={t('brains.contextual_title')}
          name="about_brains"
          links={[
            {
              label: t('brains.contextual_link'),
              url: BRAIN_DOCS_URL,
            },
          ]}
        >
          {t('brains.contextual_message')}
        </ContextualHelp>
        <TileList>
          {!enableLimits && canWrite && (
            <OldTileCreation
              isBrains={true}
              title={t('brains.add_brain')}
              onCreateClick={showAddBrainModal}
            />
          )}
          {enableLimits && canWrite && brains && (
            <TileCreation
              title={t('brains.add_brain')}
              limitReached={maxBrainsReached}
              subtitle={
                canCreateBrain ? (
                  <>
                    <Trans
                      i18nKey="limits.create_resource"
                      values={{
                        0: brains?.length,
                        1: maxBrains,
                        2: 'brains',
                      }}
                    />
                  </>
                ) : (
                  t('limits.reached', {
                    0: 'brains',
                    1: billingState?.plan_name,
                  })
                )
              }
              onCreateClick={showAddBrainModal}
            />
          )}
          {!brains && renderSkeletons(3)}
          {brains && renderTiles()}
          {createStatus === 'pending' && renderSkeletons(1)}
        </TileList>
      </PageContentWrapper>
    </PermissionsWrapper>
  );
}
